export const informationLink = "https://www.smartiscity.gr/dimos-patreon/";
export const informationLinkEN = "https://www.smartiscity.gr/en/dimos-patreon/";

export const androidAppLink =
  "https://play.google.com/store/apps/details?id=com.patras.baa";
export const iosAppLink =
  "https://apps.apple.com/tt/app/patras-env-sensors/id1592103654?uo=2";

export const welcomeMessageContent = [
  "Ο Δήμος Πατρέων σας καλωσορίζει στην Πλατφόρμα Εξυπνης Πόλης.",
  "Ενημερωθείτε για τις συνθήκες που επικρατούν στην πόλη μας.",
];

export const baseUrl = "https://patra.smartiscity.gr";

export const allTypesUrl = baseUrl + "/api/api.php?func=sensortypes";
export const allSensorsUrl = baseUrl + "/api/api.php?func=allsensors";

export const UvUrl = baseUrl + "/api/api.php?func=indexes";
export const enviURL = baseUrl + "/api/api.php?func=envi";
export const meshURL = baseUrl + "/api/api.php?func=mesh";
export const meteoURL = baseUrl + "/api/api.php?func=meteo";
export const poiURL = baseUrl + "/api/api.php?func=pois";
export const fleetoUrl = baseUrl + "/api/api.php?func=fleeto";
export const binsUrl = baseUrl + "/api/api.php?func=bins";
export const hydroUrl = baseUrl + "/api/api.php?func=hydro";
export const weatherUrl = baseUrl + "/api/api.php?func=forecast";
export const wifiUrl = baseUrl + "/api/api.php?func=hotspot";
export const chartsUrls = baseUrl + "/api/api.php?func=stats";
export const parkingUrl = baseUrl + "/api/api.php?func=parkingAll";
export const publicParkingUrl = baseUrl + "/api/api.php?func=publicparking";
export const gallerySmall = baseUrl + "/gallery/vars/Small/";
export const galleryBig = baseUrl + "/gallery/vars/Big/";

// export const cityCenter = {
//   lat: 38.2490766,
//   lng: 21.7105864,
// };
export const cityCenter = {
  lat: 38.247195,
  lng: 21.734945,
};

// settings
export const showEnvironmentAddress = true;
