import React, { createContext, useState } from "react";

export const ParkingTypeContext = createContext({ items: [] });

const ParkingTypeProvider = ({ children }) => {
  const [normalSpot, setNormalSpot] = useState(false);
  const [loadingSpot, setLoadingSpot] = useState(false);
  const [ameaSpot, setAmeaSpot] = useState(false);
  const [freeSpot, setFreeSpot] = useState(false);

  return (
    <ParkingTypeContext.Provider
      value={{
        normalSpot: { get: normalSpot, set: setNormalSpot },
        loadingSpot: { get: loadingSpot, set: setLoadingSpot },
        ameaSpot: { get: ameaSpot, set: setAmeaSpot },
        freeSpot: { get: freeSpot, set: setFreeSpot },
      }}
    >
      {children}
    </ParkingTypeContext.Provider>
  );
};

export default ParkingTypeProvider;
