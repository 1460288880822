import classes from "./TermsOfUse.module.css";
import { Link } from "react-router-dom";

const TermsOfUseEl = () => {
  return (
    <div className={`${classes.privacy}`}>
      <div>
        <h3>Αποποίηση ευθύνης - όροι χρήσης - δήλωση εχεμύθειας</h3>
        <p>
          Ο επισκέπτης του παρόντος κόμβου αποδέχεται ότι η χρήση των
          πληροφοριών που παρέχονται γίνεται με δική του ευθύνη. O Δήμος Πατρέων
          καθώς και πιθανοί τρίτοι παροχείς πληροφοριών δεν εγγυώνται ότι οι
          πληροφορίες που διατίθενται είναι πάντα σωστές. <br />
          <br />
          Παρόλες τις συνεχείς βελτιώσεις που γίνονται στον τομέα της
          τεχνολογίας μέτρησης ατμοσφαιρικών ρύπων, οι μετρήσεις έχουν πάντα
          λάθη και ο επισκέπτης πρέπει πάντα να λαμβάνει υπόψη του την
          πιθανότητα λανθασμένης μέτρησης.
          <br />
          <br /> Η παρούσα αποποίηση ευθύνης εφαρμόζεται σε οποιαδήποτε ζημιά η
          οποία μπορεί να προκληθεί από οποιοδήποτε σφάλμα ή έλλειψη
          πληροφόρησης, διακοπή ή καθυστέρηση παροχής των πληροφοριών.
          <br />
          <br /> Σε καμία περίπτωση οποιοδήποτε πρόσωπο που εμπλέκεται στην
          παροχή πληροφοριών στον κόμβο αυτό έχει ευθύνη για άμεση ή έμμεση
          ζημιά που μπορεί να προκύψει από τη χρήση του κόμβου. Ο χρήστης
          αποδέχεται ότι αυτή η αποποίηση ευθύνης ισχύει για το σύνολο των
          πληροφοριών που διατίθενται στον κόμβο.
          <br />
          <br /> Απαγορεύεται η αναπαραγωγή πληροφοριών του παρόντος κόμβου
          χωρίς την συναίνεση του Δήμου Πατρέων ή χωρίς την αναφορά στην πηγή
          (Δήμος Πατρέων/
          <a
            href={`https://patra.smartiscity.gr/`}
            target={"_blank"}
            style={{ textDecoration: "underline" }}
          >
            {" "}
            https://patra.smartiscity.gr/
          </a>
          ).
          <br />
          <br /> Πιο συγκεκριμένα, όσον αφορά στις αναφορές/μετρήσεις των
          σταθμών μας, οποιαδήποτε αναφορά, πίνακας ή άλλος τρόπος παρουσίασης
          από τρίτους πρέπει να φέρει το λογότυπο του κόμβου μας και τη σήμανση
          της πηγής (Δήμος Πατρέων/
          <a
            href={`https://patra.smartiscity.gr/`}
            target={"_blank"}
            style={{ textDecoration: "underline" }}
          >
            {" "}
            https://patra.smartiscity.gr/
          </a>
          ).
          <br />
          <br />
          Για οποιαδήποτε διευκρίνιση σχετικά μπορείτε να επικοινωνήσετε μαζί
          μας.
        </p>

        <p style={{ float: "right", fontWeight: 600, marginTop: 20 }}>
          <Link to={"/"}>Επιστροφή στην Αρχική</Link>
        </p>
      </div>
    </div>
  );
};

export default TermsOfUseEl;