import React, {
  useCallback,
  useEffect,
  useState,
  Fragment,
  useRef,
  useContext,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  MarkerClusterer,
  HeatmapLayer,
  Polyline,
} from "@react-google-maps/api";
import { LoadingCircle } from "../../UI/spinners/LoadingSpinners";

import GoogleMapStyles from "./GoogleMapStyles";

import { useStore } from "../../../hooks/store";

// markers
import green_mark from "./../../../assets/icons/Map/green_mark_icon.svg";
import orange_mark from "./../../../assets/icons/Map/orange_mark_icon.svg";
import blue_mark from "./../../../assets/icons/Map/blue_mark_icon.svg";
import purple_mark from "./../../../assets/icons/Map/purple_mark_icon.svg";
import yellow_mark from "./../../../assets/icons/Map/yellow_mark_icon.svg";
import light_blue_mark from "./../../../assets/icons/Map/light_blue_mark_icon.svg";
import pin_blue from "./../../../assets/icons/Map/pin_blue_icon.svg";
import customMarker from "./../../../assets/icons/Map/marker_icon_32x32.png";
import Spiderfy from "./Spiderfy";
import { cityCenter } from "../../../../SMARTCITY/hardCodeData/DATA";
import { SensorIdContext } from "../../../hooks/SensorIdProvider";
import { MobilityContext } from "../../../hooks/mobilityProvider";
import styled from "styled-components";

const icons = {
  1: green_mark,
  2: orange_mark,
  3: blue_mark,
  4: purple_mark,
  5: yellow_mark,
  6: light_blue_mark,
  7: pin_blue,
  8: pin_blue,
  9: purple_mark,
  10: orange_mark,
};
const options = {
  imagePath:
    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m", // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
};
const libraries = ["visualization", "places"];

let c = 0;
const SmartIsCityMap = ({
  filtersVars,
  mobilityMap,
  selectedRoad,
  ...props
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDQlCnn8F1D0S9ymnWZwc_WFx2ZS6g8w1Q",
    // ...otherOptions
    libraries: libraries,
  });
  const [map, setMap] = useState(null);
  const [heatMap, setHeatMap] = useState(false);
  // const [mobilityMap, setMobilityMap] = useState(false);
  const [clickedMarkerData, setClickedMarkerData] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [center, setCenter] = useState(cityCenter);
  const [showLegend, setShowLegend] = useState(true);
  const goCenter = useRef(null);

  const state = useStore()[0];

  const markStyleRef = useRef(null);

  const zoomRef = useRef(12);

  const backupActiveTypeId = useRef(cityCenter);

  const sensorId = useContext(SensorIdContext);
  const mobilityData = useContext(MobilityContext);

  const listener = useRef(null);
  const history = useHistory();
  const { search } = useLocation();
  const { pathname } = useLocation();

  useEffect(() => {
    if (selectedRoad) {
      setCenter(selectedRoad.route.routeCenter);
      console.log("TEST: selectedRoad:", selectedRoad);
    }
  }, [selectedRoad]);

  useEffect(() => {
    if (mobilityMap) {
      setCenter({
        lat: 38.240645,
        lng: 21.730558,
      });
    }
  }, []);

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const recenterMap = (position) => {
    if (position && center !== position) {
      goCenter.current = {
        lat: (center.lat - position.lat) / 5,
        lng: (center.lng - position.lng) / 5,
      };
      clearInterval(listener.current);
      listener.current = setInterval(smoothChangeCenter, 50);
      c = 5;

      if (zoomRef.current < 12) zoomRef.current = 12;
    }
  };

  const smoothChangeCenter = () => {
    console.log(c);
    if (c === 1) {
      clearInterval(listener.current);
      listener.current = null;
    }
    c -= 1;
    setCenter((prev) => ({
      lat: prev.lat - goCenter.current.lat,
      lng: prev.lng - goCenter.current.lng,
    }));
  };

  useEffect(() => {
    console.log("TEST: mobility data:", mobilityData);
    if (search !== "") {
      const currTypeURL = new URLSearchParams(search).get("typeId");
      const currSensorURL = sensorId.get;

      // console.log("TEST currSensorURL:", sensorId.get);

      const tempTypeIdActive = state.types.filter(
        (item) => item.id === currTypeURL
      );

      let data = [];
      if (tempTypeIdActive.length > 0 && state.sensors.length > 0) {
        backupActiveTypeId.current = tempTypeIdActive[0].id;
        markStyleRef.current = icons[tempTypeIdActive[0].id];
        if (currTypeURL !== "9") setHeatMap(false);

        data = state.sensors.filter((item) => {
          return item.typeId === tempTypeIdActive[0].id;
        });

        if (currSensorURL) {
          const tempMarker = data.filter(
            (item) => item.id.toString() === currSensorURL
          );

          if (tempMarker.length > 0) {
            setClickedMarkerData(tempMarker[0].id);
            recenterMap(tempMarker[0].position);
          }
        } else {
          setClickedMarkerData(null);
          recenterMap(cityCenter);
        }
      }
      // console.log("TEST: data on map:", data);
      let filteredData = data;
      if (filtersVars.length > 0) {
        switch (currTypeURL) {
          case "9": {
            for (let filterVar of filtersVars) {
              switch (filterVar) {
                case "Άδειοι":
                  filteredData = data.filter(
                    (sensor) => sensor.metrics[4] <= "25%"
                  );
                  break;
                case "Κάτω από τη μέση":
                  filteredData = data.filter(
                    (sensor) =>
                      sensor.metrics[4] > "25%" && sensor.metrics[4] <= "40%"
                  );
                  break;
                case "Περίπου στη μέση":
                  filteredData = data.filter(
                    (sensor) =>
                      sensor.metrics[4] > "40%" && sensor.metrics[4] <= "60%"
                  );
                  break;
                case "Σχεδόν πλήρεις":
                  filteredData = data.filter(
                    (sensor) =>
                      sensor.metrics[4] > "60%" && sensor.metrics[4] <= "95%"
                  );
                  break;
                case "Πλήρεις":
                  filteredData = data.filter(
                    (sensor) => sensor.metrics[4] > "95%"
                  );
                  break;
                default:
                  filteredData = [];
                  break;
              }
            }
            break;
          }
          case "24": {
            filteredData = data.filter((sensor) => {
              if (filtersVars.length > 1) {
                if (filtersVars.includes("Ελεύθερη")) {
                  return (
                    (filtersVars.includes(sensor.type) && !sensor.isOccupied) ??
                    sensor.isOccupied === false
                  );
                }
                if (filtersVars.includes("Κατειλημμένη")) {
                  return (
                    (filtersVars.includes(sensor.type) && sensor.isOccupied) ??
                    sensor.isOccupied === true
                  );
                }
                return filtersVars.includes(sensor.type);
              } else {
                if (filtersVars.includes("Ελεύθερη")) {
                  return !sensor.isOccupied ?? null;
                }
                if (filtersVars.includes("Κατειλημμένη")) {
                  return sensor.isOccupied ?? null;
                }
                return filtersVars.includes(sensor.type);
              }
            });
            break;
          }
          default: {
            for (let filterVar of filtersVars) {
              filteredData.filter((sensor) =>
                sensor.metrics.includes(filterVar)
              );
            }
            break;
          }
        }
      } else {
        filteredData = data;
      }

      setMarkers(filteredData);
    }
  }, [search, state.types, state.sensors, filtersVars, sensorId.get]);

  const calculator = (inputMarkers, numStyles) => {
    let index = 0;
    let count = inputMarkers.length;
    let dv = count;
    while (dv !== 0) {
      dv = parseInt(dv / 10, 10);
      index++;
    }

    console.log(index);
    if (backupActiveTypeId.current !== "9")
      return {
        text: inputMarkers.length,
        index: index,
        title: "",
      };

    let avg = 0;
    for (let i = 0; i < inputMarkers.length; i++) {
      const tempId = inputMarkers[i].title;

      for (let y = 0; y < markers.length; y++) {
        if (markers[y].id.toString() === tempId) {
          const sumMarkers = markers[y].metrics.filter(
            (item) => item.content === "Ποσοστό πληρότητας κάδου"
          );

          sumMarkers.map((item) => {
            const num = parseInt(item.value.replace("%", ""));
            avg += num;
          });
        }
      }
    }

    index = Math.min(index, numStyles);

    return {
      text: (avg / count).toFixed(0) + "%",
      index: index,
      title: "s",
    };
  };

  const MapMarker = React.forwardRef((props, ref) => {
    return <Marker position={props.position} title={props.title} ref={ref} />;
  });

  return (
    <>
      {isLoaded && (
        <GoogleMap
          center={center}
          zoom={zoomRef.current}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onDragEnd={() => setCenter(map.getCenter().toJSON())}
          onZoomChanged={() => map && (zoomRef.current = map.getZoom())}
          options={{
            styles: GoogleMapStyles,
          }}
        >
          {/* Child components, such as markers, info windows, etc. */}

          <Fragment>
            {heatMap && !mobilityMap.get && (
              <HeatmapLayer
                data={markers.map(
                  (marker) =>
                    new window.google.maps.LatLng(
                      marker.position.lat,
                      marker.position.lng
                    )
                )}
              />
            )}
            {mobilityMap.get &&
              mobilityData.map((line, id) => {
                return (
                  <Polyline
                    path={line.route.geometry.coordinates}
                    options={{
                      strokeColor:
                        line.level === 0
                          ? "#0fd170"
                          : line.level === 1
                          ? "#fcb836"
                          : line.level === 2
                          ? "#ff474c"
                          : line.level === 3
                          ? "#ff0038"
                          : line.level === 4
                          ? "#880038"
                          : "#aaa",
                      strokeOpacity: 1, // 50% opacity
                      strokeWeight: 4, // Line thickness
                    }}
                    geodesic={true}
                  />
                );
              })}

            {!heatMap &&
              !mobilityMap.get &&
              backupActiveTypeId.current === "10" && (
                // <Spiderfy>
                <>
                  {markers.map((marker) => (
                    <Marker
                      title={marker.id.toString()}
                      key={marker.id}
                      position={marker.position}
                      animation={4}
                      clickable={true}
                      icon={
                        marker.id === clickedMarkerData
                          ? customMarker
                          : marker.bigMarker
                          ? marker.bigMarker
                          : markStyleRef.current
                      }
                    >
                      {clickedMarkerData && marker.id === clickedMarkerData && (
                        <InfoWindow position={marker.position}>
                          <p>{marker.title}</p>
                        </InfoWindow>
                      )}
                    </Marker>
                  ))}
                </>
                // </Spiderfy>
              )}
            {!heatMap &&
              !mobilityMap.get &&
              backupActiveTypeId.current !== "10" && (
                <MarkerClusterer
                  options={options}
                  averageCenter={true}
                  calculator={calculator}
                >
                  {(clusterer) =>
                    markers.map((marker) => {
                      // console.log("TEST: my marker", clickedMarkerData);
                      return (
                        <Marker
                          title={marker.id?.toString()}
                          key={marker.id}
                          position={marker.position}
                          animation={4}
                          clickable={true}
                          // clusterer={
                          //   backupActiveTypeId.current === "9" ? false : clusterer
                          // }
                          clusterer={clusterer}
                          icon={
                            marker.id === clickedMarkerData
                              ? customMarker
                              : marker.bigMarker
                              ? marker.bigMarker
                              : markStyleRef.current
                          }
                          onClick={() => {
                            if (clickedMarkerData !== marker.id) {
                              console.log(
                                "TEST clicked",
                                clickedMarkerData,
                                marker.id
                              );
                              sensorId.set(marker.id);
                              const pageId = new URLSearchParams(search).get(
                                "typeId"
                              );
                              if (pageId !== "36") {
                                props.showAnimation(true);
                              }
                            } else {
                              props.showAnimation(false);
                            }
                          }}
                        >
                          {clickedMarkerData &&
                            marker.id === clickedMarkerData && (
                              <InfoWindow position={marker.position}>
                                <p>{marker.title}</p>
                              </InfoWindow>
                            )}
                        </Marker>
                      );
                    })
                  }
                </MarkerClusterer>
              )}
          </Fragment>

          <>
            {backupActiveTypeId.current === "24" && (
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  transform: "translateX(calc(50vw - (100% / 2 )))",
                  textAlign: "center",
                  padding: "5px 10px",
                  backgroundColor: "red",
                  borderRadius: "12px 12px 0 0",
                }}
              >
                <p
                  style={{ cursor: "pointer", color: "white" }}
                  onClick={() => mobilityMap.set((prev) => !prev)}
                >
                  <span>
                    {mobilityMap.get
                      ? "Κυκλοφορία: Ενεργοποιημένη"
                      : "Κυκλοφορία: Ανενεργή"}
                  </span>
                </p>
              </div>
            )}
          </>
          <>
            {backupActiveTypeId.current === "9" && (
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  transform: "translateX(calc(50vw - (100% / 2 )))",
                  textAlign: "center",
                  padding: "5px 10px",
                  backgroundColor: "green",
                  borderRadius: "12px 12px 0 0",
                }}
              >
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => setHeatMap((prev) => !prev)}
                >
                  <span>{heatMap ? "HeatMap: OFF" : "HeatMap: ON"}</span>
                </p>
              </div>
            )}
          </>
        </GoogleMap>
      )}
      {!isLoaded && <LoadingCircle />}
      {mobilityMap.get && (
        <MobilityLegend $showLegend={showLegend}>
          <span
            style={{
              textAlign: "center",
              // padding: "4px",
              cursor: "pointer",
              fontSize: "14px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => setShowLegend(!showLegend)}
          >
            {showLegend ? "Ελαχιστοποίηση" : "Προβολή υπομνήματος"}
          </span>
          <hr style={{ width: "100%", borderColor: "rgba(0,0,0,0.1)" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p>Πολύ Χαμηλό:</p>
            <div
              style={{
                width: "40px",
                height: "10px",
                backgroundColor: "#0fd170",
              }}
            ></div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p>Χαμηλό:</p>
            <div
              style={{
                width: "40px",
                height: "10px",
                backgroundColor: "#fcb836",
              }}
            ></div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p>Μέτριο:</p>
            <div
              style={{
                width: "40px",
                height: "10px",
                backgroundColor: "#ff474c",
              }}
            ></div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p>Υψηλό:</p>
            <div
              style={{
                width: "40px",
                height: "10px",
                backgroundColor: "#ff0038",
              }}
            ></div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p>Πολύ Υψηλό:</p>
            <div
              style={{
                width: "40px",
                height: "10px",
                backgroundColor: "#880038",
              }}
            ></div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p>Μη Διαθέσιμο:</p>
            <div
              style={{
                width: "40px",
                height: "10px",
                backgroundColor: "#aaa",
              }}
            ></div>
          </div>
        </MobilityLegend>
      )}
    </>
  );
};

export default React.memo(SmartIsCityMap);

const MobilityLegend = styled.div`
  width: 180px;
  height: ${(props) => (props.$showLegend ? "160px" : "18px")};
  overflow: hidden;
  background-color: #fff;
  position: absolute;
  right: 60px;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  transition: all 1s ease;
`;
