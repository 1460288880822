import { Fragment, useEffect, useState, useRef } from "react";
import BoxTitle from "../../box/title/BoxTitle";
import BoxSubTitle from "../../box/subTitle/BoxSubTitle";
import BoxEntity from "../../box/entity/BoxEntity";
import CustomSlider from "../../customSlider/CustomSlider";

import { useStore } from "../../../hooks/store";
import slice_lists from "../../../services/slice_lists";
import replace_duplicate_label from "../../../services/replace_duplicate_label";
import { LoadingDot } from "../../UI/spinners/LoadingSpinners";
import { useTranslation } from "react-i18next";

const meshliumId = "2";
const Meshlium = ({ previewIndex, maxEntities, previewGroup, typeId }) => {
  const [data, setData] = useState({ devideData: [], devideLabels: [] });
  const imgTitleRef = useRef(null);
  const tooltipRef = useRef(null);
  const state = useStore()[0];
  const subTitleRef = useRef(null);

  const { t } = useTranslation();
  useEffect(() => {
    if (state.sensors.length > 0) {
      for (let y = 0; y < state.types.length; y++) {
        if (state.types[y].id === meshliumId) {
          imgTitleRef.current = state.types[y].icon;
          tooltipRef.current = state.types[y].tooltip;
          break;
        }
      }

      const sensorsInSameType = state.sensors.filter(
        (item) => item.typeId === meshliumId
      );

      console.log(state.sensors);
      const totalData = [];
      const totalLabels = [];

      if (
        sensorsInSameType.length > previewIndex &&
        sensorsInSameType[previewIndex].metrics.length !== 0
      ) {
        const currList = slice_lists(
          sensorsInSameType[previewIndex].metrics,
          maxEntities
        );

        const currLabels = replace_duplicate_label(
          currList.length,
          sensorsInSameType[previewIndex].title
        );

        totalData.push(...currList);
        totalLabels.push(...currLabels);
      } else if (sensorsInSameType !== undefined && previewGroup) {
        const sensorsInSameTypeAndGroup = sensorsInSameType.filter(
          (item) => item.group === previewGroup
        );
        if (
          sensorsInSameTypeAndGroup === undefined ||
          sensorsInSameTypeAndGroup[0] === undefined
        )
          return;
        subTitleRef.current = sensorsInSameTypeAndGroup[0].groupName;
        for (let i = 0; i < sensorsInSameTypeAndGroup.length; i++) {
          if (sensorsInSameTypeAndGroup[i].metrics.length === 0) break;

          const currList = slice_lists(
            sensorsInSameTypeAndGroup[i].metrics,
            maxEntities
          );
          const currLabels = replace_duplicate_label(
            currList.length,
            sensorsInSameTypeAndGroup[i].title
          );

          totalData.push(...currList);
          totalLabels.push(...currLabels);
        }
      } else if (sensorsInSameType !== undefined && !previewIndex) {
        for (let i = 0; i < sensorsInSameType.length; i++) {
          if (sensorsInSameType[i].metrics.length === 0) break;

          const currList = slice_lists(
            sensorsInSameType[i].metrics,
            maxEntities
          );
          const currLabels = replace_duplicate_label(
            currList.length,
            sensorsInSameType[i].title
          );

          totalData.push(...currList);
          totalLabels.push(...currLabels);
        }
      }

      setData({ devideData: totalData, devideLabels: totalLabels });
    }
  }, [state.sensors]);

  return (
    <Fragment>
      <BoxTitle
        icon={imgTitleRef.current}
        title={t("ΣΥΝΑΘΡΟΙΣΗ ΚΟΙΝΟΥ")}
        link={`/map?typeId=${typeId}`}
        id={meshliumId}
        infoContext={tooltipRef.current}
        element={
          <i
            id="draggable"
            className="im im-icon-Double-Tap"
            style={{ fontSize: "32px", minWidth: "32px", cursor: "pointer" }}
          />
        }
      />
      {subTitleRef.current && (
        <p style={{ textAlign: "center", margin: "10px 0 5px" }}>
          <strong>{subTitleRef.current}</strong>
        </p>
      )}
      {data.devideData.length > 0 && (
        <CustomSlider>
          {data.devideData.map((seperateData, index) => (
            <div key={index}>
              <BoxSubTitle>
                {!subTitleRef.current && (
                  <strong>{data.devideLabels[index]}</strong>
                )}
                {subTitleRef.current && <span>{data.devideLabels[index]}</span>}
              </BoxSubTitle>
              {seperateData.map((data, index) => (
                <BoxEntity
                  key={index}
                  icon={data.icon_large}
                  content={data.content}
                  value={data.value}
                />
              ))}
            </div>
          ))}
        </CustomSlider>
      )}

      {data.devideData.length === 0 && <LoadingDot />}
    </Fragment>
  );
};

export default Meshlium;
