import classes from "./TermsOfUse.module.css";
import { Link } from "react-router-dom";

const TermsOfUseEn = () => {
  return (
    <div className={`${classes.privacy}`}>
      <div>
        <h3>Disclaimer – terms of use – confidentiality statement</h3>
        <p>
          The visitor of this site accepts that the use of the information provided is at his own risk. The Municipality of Patreon as well as possible third party providers do not guarantee that the information available is always correct.  <br />
          <br />
          Despite continuous improvements in air pollutant measurement technology, such kind of estimations always have errors an the visitor should always be aware of the possibility of a false measurement
          <br />
          <br /> This disclaimer applies to any damage that may be caused by any error or lack of information, interruption or delay in providing information. 
          <br />
          <br /> This disclaimer applies to any damage that may be caused by any error or lack of information, interruption or delay in providing information. 
          <br />
          <br /> The reproduction on information from this site is prohibited without the consent of the Municipality of Patreon or without reference to the source (Municipality of Patreon / 
          <a
            href={`https://patra.smartiscity.gr/`}
            target={"_blank"}
            style={{ textDecoration: "underline" }}
          >
            {" "}
            https://patra.smartiscity.gr/
          </a>
          ).
          <br />
          <br /> More specifically, regarding the reports/measurements of our stations, any report, table or other way of presentation by third parties must bear the logo or our hub and the marking of the source (Municipality of Patreon / 
          <a
            href={`https://patra.smartiscity.gr/`}
            target={"_blank"}
            style={{ textDecoration: "underline" }}
          >
            {" "}
            https://patra.smartiscity.gr/
          </a>
          ).
          <br />
          <br />
          For any clarification regarding the above, you may contact us. 
        </p>

        <p style={{ float: "right", fontWeight: 600, marginTop: 20 }}>
          <Link to={"/"}>Back to Home</Link>
        </p>
      </div>
    </div>
  );
};

export default TermsOfUseEn;