import classes from "../components/TermsOfUse/TermsOfUse.module.css";
import { Link } from "react-router-dom";
import TermsOfUseEl from "../components/TermsOfUse/TermsOfUseEl";
import TermsOfUseEN from "../components/TermsOfUse/TermsOfUseEn";
import { useContext } from "react";
import LanguageContext from "./../hooks/language-context";

const TermsOfUse = () => {
 const languageCtx = useContext(LanguageContext);
  return (
    <div>
      {languageCtx.languageCode === "el" ? (
        <TermsOfUseEl />  
      ) : (
         <TermsOfUseEN />
      )}
    </div>
  );
};

export default TermsOfUse;
