import { useEffect, useState } from "react";
import { cityCenter } from "../../../SMARTCITY/hardCodeData/DATA";
import MapContainer from "./container/MapContainer";
import SmartIsCityMap from "./smartiscity/SmartIsCityMap";

const Map = ({ mapFrame }) => {
  const [searchInput, setSearchInput] = useState("");
  const [filtersVars, setFiltersVars] = useState([]);
  const [mobilityMap, setMobilityMap] = useState(false);
  const [selectedRoad, setSelectedRoad] = useState(null);

  const clearVarsHandler = () => {
    setFiltersVars([]);
  };

  return (
    <MapContainer
      mapFrame={mapFrame}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      filtersVar={filtersVars}
      setFiltersVars={setFiltersVars}
      clearVarsHandler={clearVarsHandler}
      mobilityMap={mobilityMap}
      selectedRoad={{ get: selectedRoad, set: setSelectedRoad }}
    >
      <SmartIsCityMap
        filtersVars={filtersVars}
        mobilityMap={{ get: mobilityMap, set: setMobilityMap }}
        selectedRoad={selectedRoad}
      />
    </MapContainer>
  );
};

export default Map;
