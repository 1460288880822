import classes from "./MapContainer.module.css";
import React, { useState, Fragment, useEffect } from "react";

import LeftPanel from "../../../layout/leftPanel/LeftPanel";
import MapContentForLeftPanel from "../leftPanelContent/MapContentForLeftPanel";
import RightPanel from "../../../layout/rightPanel/RightPanel";
import RightPanelContent from "../rightPanelContent/RightPanelContent";
// import VerticalSidePanel from "./../../components/UI/panel/VerticalSidePanel";
// import Vertical from "../../components/forms/vertical/Vertical";

let listener = null;
const MapContainer = ({
  searchInput,
  setSearchInput,
  filtersVar,
  setFiltersVars,
  clearVarsHandler,
  mobilityMap,
  selectedRoad,
  ...props
}) => {
  const [showInfoPanel, setShowInfoPanel] = useState(false);

  const close = () => {
    setShowInfoPanel(false);
  };

  const showAnimation = (value) => {
    if (showInfoPanel && value) {
      clearTimeout(listener);
      setShowInfoPanel(false);
      listener = setTimeout(() => setShowInfoPanel(true), 250);
    } else {
      setShowInfoPanel(true);
    }
  };

  useEffect(() => {
    return () => clearTimeout(listener);
  }, []);

  return (
    <Fragment>
      <LeftPanel open>
        <MapContentForLeftPanel
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          filtersVar={filtersVar}
          setFiltersVars={setFiltersVars}
          clearVarsHandler={clearVarsHandler}
          showAnimation={showAnimation}
          close={close}
          mobilityMap={mobilityMap}
          selectedRoad={{ ...selectedRoad }}
        />
      </LeftPanel>
      <RightPanel show={showInfoPanel} close={close}>
        <RightPanelContent />
      </RightPanel>
      <div className={props.mapFrame ? classes.wrapper_full : classes.wrapper}>
        {React.cloneElement(props.children, {
          showAnimation,
        })}
      </div>
    </Fragment>
  );
};

export default MapContainer;
