import React from "react";
import { Modal } from "antd";

function ViewModal({ modalStatus, title, closeModal, children }) {
  return (
    <Modal
      centered
      title={title}
      width={850}
      open={modalStatus}
      onCancel={() => closeModal()}
      footer={null}
    >
      {children}
    </Modal>
  );
}

export default ViewModal;
