import React, { createContext, useState } from "react";

export const SensorIdContext = createContext({ items: [] });

const initialValue = null;

const SensorIdProvider = ({ children }) => {
  const [sensorId, setSensorId] = useState(initialValue);

  const handleSensorId = (sensorId) => {
    setSensorId(sensorId);
  };

  return (
    <SensorIdContext.Provider
      value={{
        get: sensorId,
        set: handleSensorId,
      }}
    >
      {children}
    </SensorIdContext.Provider>
  );
};

export default SensorIdProvider;
