import React, { createContext, useEffect, useState } from "react";
import { formatMobilityData } from "../../utils";

export const MobilityContext = createContext({ items: [] });

const MobilityProvider = ({ children }) => {
  const [mobilityData, setMobilityData] = useState(null);

  const fetchMobilityData = async () => {
    const currentDate = new Date();
    const currentMinutes = currentDate.getMinutes();
    const minutesToSubtract = (currentMinutes % 10) + 21;

    currentDate.setMinutes(currentMinutes - minutesToSubtract);

    const mobilityRawData = await fetch(
      `https://patra.dotsoft.gr/api/exchange/datasets/traffic-conditions?from=${currentDate.toISOString()}`,

      {
        headers: {
          Host: "services.invipo.local",
          "User-Agent":
            "Mozilla/5.0 (Windows; U; Windows NT 6.1; en-US; rv:1.0.1.5)",
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_INVIPO_API_KEY,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .catch((data) => {
        console.log(JSON.stringify(data));
        return [];
      });

    console.log("TEST: mobilityRawData:", mobilityRawData);

    setMobilityData(formatMobilityData(mobilityRawData));
  };

  useEffect(() => {
    const intervalMobility = setInterval(() => {
      fetchMobilityData();
    }, 1000 * 60 * 10);

    // clean up
    return () => clearInterval(intervalMobility);
  }, []);

  useEffect(() => {
    fetchMobilityData();
  }, []);
  return (
    <MobilityContext.Provider value={mobilityData}>
      {children}
    </MobilityContext.Provider>
  );
};

export default MobilityProvider;

const roads = [
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-3 -> BT-2",
      geometry: {
        coordinates: [
          [21.7360641, 38.246508600000006],
          [21.736217099999998, 38.246614699999995],
          [21.736860699999998, 38.247126699999995],
          [21.736996599999998, 38.2472348],
          [21.7380195, 38.2480604],
          [21.7383471, 38.248311799999996],
          [21.7389325, 38.2487609],
          [21.739478899999998, 38.249194800000005],
          [21.7397228, 38.2493824],
          [21.7402446, 38.2497743],
          [21.740529199999997, 38.249991099999995],
          [21.740848, 38.2502494],
          [21.7409613, 38.2503412],
          [21.7413985, 38.250674],
          [21.741727299999997, 38.250924499999996],
          [21.741820699999998, 38.250995599999996],
          [21.742147199999998, 38.251253299999995],
        ],
        type: "LineString",
      },
      length: 749,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-3 -> BT-7",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7360641, 38.246508600000006],
          [21.736217099999998, 38.246614699999995],
          [21.736794699999997, 38.2461104],
          [21.7360008, 38.2455515],
          [21.735111, 38.2448438],
        ],
      },
      length: 297,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-5 -> BT-2",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.747677499999998, 38.2552474],
          [21.747573799999998, 38.2552972],
          [21.747465599999998, 38.255462099999995],
          [21.7474505, 38.255522400000004],
          [21.7474418, 38.255590399999996],
          [21.747469799999998, 38.255780400000006],
          [21.7475377, 38.255964],
          [21.747649799999998, 38.256266],
          [21.7474927, 38.256395399999995],
          [21.7473866, 38.2564371],
          [21.7472626, 38.2564529],
          [21.7470908, 38.256364399999995],
          [21.746808599999998, 38.2562195],
          [21.7467172, 38.2561605],
          [21.746463, 38.2560201],
          [21.7462677, 38.2559566],
          [21.7460859, 38.255919],
          [21.7458992, 38.255974900000005],
          [21.7456975, 38.2560602],
          [21.7456225, 38.256078699999996],
          [21.744967, 38.2562406],
          [21.7446853, 38.2563132],
          [21.7445348, 38.256350499999996],
          [21.7442702, 38.2564219],
          [21.7440389, 38.2564703],
          [21.7435962, 38.2565628],
          [21.743503500000003, 38.2565867],
          [21.743418899999998, 38.2566302],
          [21.7433581, 38.2566309],
          [21.743324299999998, 38.25662210000001],
          [21.7432537, 38.2565653],
          [21.7432426, 38.2565497],
          [21.743231299999998, 38.2564798],
          [21.7432412, 38.2564542],
          [21.7430216, 38.2560777],
          [21.743006, 38.256034199999995],
          [21.742819, 38.255514999999995],
          [21.7425777, 38.2549126],
          [21.7423318, 38.2543302],
          [21.7422583, 38.2541211],
          [21.7421318, 38.253775399999995],
          [21.742055, 38.2536026],
          [21.741754399999998, 38.252923],
          [21.7416534, 38.2526805],
          [21.7416369, 38.2526307],
          [21.7415685, 38.2524575],
          [21.7415006, 38.2522857],
          [21.7413617, 38.2518913],
          [21.741239099999998, 38.2515632],
          [21.7411776, 38.2514725],
          [21.7411364, 38.251435],
          [21.7407853, 38.251156099999996],
          [21.7403644, 38.2508287],
          [21.7398891, 38.250473199999995],
          [21.7396452, 38.250274499999996],
          [21.7391328, 38.2498806],
          [21.738879999999998, 38.249676],
          [21.738797299999998, 38.249611099999996],
          [21.7383112, 38.249229799999995],
          [21.7389325, 38.2487609],
          [21.739478899999998, 38.249194800000005],
          [21.7397228, 38.2493824],
          [21.7402446, 38.2497743],
          [21.740409, 38.2498995],
          [21.740529199999997, 38.249991099999995],
          [21.7409613, 38.2503412],
          [21.7413985, 38.250674],
          [21.741727299999997, 38.250924499999996],
          [21.741820699999998, 38.250995599999996],
          [21.742143799999997, 38.2512506],
          [21.742147199999998, 38.251253299999995],
        ],
      },
      length: 1968,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-4 -> BT-26",
      geometry: {
        coordinates: [
          [21.7289083, 38.2408303],
          [21.7287659, 38.240846],
          [21.7278428, 38.2409505],
          [21.727571899999997, 38.2409755],
          [21.7273445, 38.2409965],
          [21.727252699999998, 38.2410035],
          [21.727202, 38.2410082],
          [21.726461999999998, 38.2410922],
          [21.7263385, 38.2411059],
          [21.726176, 38.241121899999996],
          [21.7261522, 38.2410662],
          [21.7261111, 38.2408834],
        ],
        type: "LineString",
      },
      length: 268,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-8 -> BT-10",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.730145, 38.240688],
          [21.7311004, 38.2405833],
          [21.731241999999998, 38.2405712],
        ],
      },
      length: 97,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-27 -> BT-26",
      geometry: {
        coordinates: [
          [21.7247099, 38.2332202],
          [21.7245211, 38.2323028],
          [21.7244463, 38.231927],
          [21.724428, 38.231860499999996],
          [21.724591300000004, 38.2318441],
          [21.7260638, 38.2317676],
          [21.7262265, 38.231758],
          [21.726454999999998, 38.2317475],
          [21.7269202, 38.2317819],
          [21.7271207, 38.2318145],
          [21.7275134, 38.2318919],
          [21.728091499999998, 38.2320035],
          [21.729350099999998, 38.232234500000004],
          [21.7295682, 38.2322738],
          [21.7295754, 38.232361999999995],
          [21.7296236, 38.2328069],
          [21.7296642, 38.233059],
          [21.729692999999997, 38.2332374],
          [21.729840499999998, 38.234192],
          [21.7298808, 38.2343884],
          [21.729958399999997, 38.2347429],
          [21.729984899999998, 38.2349308],
          [21.7300027, 38.235042299999996],
          [21.7300892, 38.2355776],
          [21.730114699999998, 38.235726899999996],
          [21.7301717, 38.2360373],
          [21.730234199999998, 38.236467499999996],
          [21.730262699999997, 38.2365977],
          [21.7303016, 38.236775],
          [21.730417700000004, 38.237280299999995],
          [21.7304538, 38.237502],
          [21.730511099999998, 38.2378589],
          [21.7306147, 38.2384671],
          [21.7308125, 38.239322],
          [21.7309528, 38.2399706],
          [21.7311004, 38.2405833],
          [21.730045399999998, 38.2406988],
          [21.729351599999998, 38.2407778],
          [21.7287659, 38.240846],
          [21.7278428, 38.2409505],
          [21.727571899999997, 38.2409755],
          [21.7273445, 38.2409965],
          [21.727252699999998, 38.2410035],
          [21.727202, 38.2410082],
          [21.726461999999998, 38.2410922],
          [21.7263385, 38.2411059],
          [21.726176, 38.241121899999996],
          [21.7261522, 38.2410662],
          [21.7261111, 38.2408834],
        ],
        type: "LineString",
      },
      length: 2005,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-27 -> BT-16",
      geometry: {
        coordinates: [
          [21.7247099, 38.2332202],
          [21.7245211, 38.2323028],
          [21.7244463, 38.231927],
          [21.724428, 38.231860499999996],
          [21.724591300000004, 38.2318441],
          [21.7260638, 38.2317676],
          [21.7262265, 38.231758],
          [21.726454999999998, 38.2317475],
          [21.7269202, 38.2317819],
          [21.7271207, 38.2318145],
          [21.7275134, 38.2318919],
          [21.728091499999998, 38.2320035],
          [21.729350099999998, 38.232234500000004],
          [21.7295682, 38.2322738],
          [21.7299808, 38.2323589],
          [21.7300111, 38.2323652],
          [21.730265, 38.2324176],
          [21.730629099999998, 38.2324881],
          [21.731164399999997, 38.232596699999995],
          [21.7313497, 38.2326301],
          [21.732026299999998, 38.232766999999996],
          [21.7324564, 38.2328562],
          [21.7325295, 38.2328724],
          [21.732729799999998, 38.232918999999995],
          [21.733140799999997, 38.2329952],
          [21.7332933, 38.233025999999995],
          [21.7337344, 38.2331159],
          [21.7337842, 38.233123299999995],
          [21.734082, 38.2331249],
          [21.7341299, 38.233117199999995],
          [21.734346, 38.2330591],
          [21.734486399999998, 38.233013500000006],
          [21.735633099999998, 38.2325607],
          [21.7356664, 38.2325478],
          [21.736277899999997, 38.2323494],
          [21.7368715, 38.2321384],
          [21.737672, 38.231854],
          [21.737743299999998, 38.2318225],
          [21.738130299999998, 38.2316308],
          [21.7393035, 38.2307734],
          [21.7395729, 38.230587799999995],
          [21.7397451, 38.2304692],
          [21.7399888, 38.2302866],
          [21.740168699999998, 38.2301475],
          [21.7405024, 38.2298896],
          [21.7406656, 38.2297635],
          [21.7409677, 38.2295301],
          [21.7412083, 38.2293441],
          [21.7412705, 38.2294452],
          [21.7414746, 38.22963130000001],
          [21.741729799999998, 38.2298748],
          [21.7417937, 38.2299321],
          [21.741992099999997, 38.2301068],
          [21.742117200000003, 38.230218099999995],
          [21.7421583, 38.2302526],
          [21.7423665, 38.2304266],
          [21.7424754, 38.2305375],
          [21.7428378, 38.2308768],
          [21.7430242, 38.2310397],
          [21.743050999999998, 38.231063899999995],
          [21.743156100000004, 38.2312005],
          [21.7433253, 38.2313827],
          [21.743460300000002, 38.231528],
          [21.743631999999998, 38.231729699999995],
          [21.743675700000004, 38.2317791],
          [21.7439501, 38.2321184],
          [21.7440883, 38.232261799999996],
          [21.7441386, 38.232314099999996],
          [21.7441805, 38.2323575],
          [21.744268299999998, 38.2324486],
          [21.744629399999997, 38.232838199999996],
          [21.744787499999997, 38.233011399999995],
          [21.7448792, 38.233112299999995],
          [21.745017900000004, 38.2332465],
          [21.7455194, 38.233742],
          [21.7459834, 38.2341791],
          [21.746219999999997, 38.2344467],
          [21.746361600000004, 38.234583799999996],
          [21.7465472, 38.2346837],
          [21.7466051, 38.2347159],
          [21.7466989, 38.2346992],
          [21.7468146, 38.2347369],
          [21.7467811, 38.23480860000001],
          [21.7465019, 38.235443499999995],
          [21.7462786, 38.2359705],
          [21.746014199999998, 38.2365784],
          [21.7459599, 38.2366749],
          [21.7457746, 38.2370039],
          [21.745769, 38.23701760000001],
          [21.745629299999997, 38.2373602],
          [21.745545, 38.2375256],
          [21.745381, 38.2378463],
          [21.7453507, 38.2379259],
          [21.7452041, 38.2382524],
          [21.745179699999998, 38.2383318],
          [21.745162699999998, 38.2385217],
          [21.745169, 38.2386292],
          [21.7451717, 38.238696],
          [21.745194599999998, 38.2391305],
          [21.7451924, 38.239291699999995],
          [21.745227099999997, 38.23941],
          [21.745280100000002, 38.2395497],
          [21.7453538, 38.2398318],
          [21.745397099999998, 38.2402042],
          [21.7452893, 38.24018220000001],
          [21.7450323, 38.240128899999995],
          [21.744938299999998, 38.240075],
          [21.7449141, 38.2400477],
          [21.7451021, 38.23996460000001],
        ],
        type: "LineString",
      },
      length: 3261,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-28 -> BT-13",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.730982500000003, 38.2457034],
          [21.7314029, 38.2460482],
          [21.731462399999998, 38.2460933],
          [21.7317884, 38.246345999999996],
          [21.7315073, 38.246615399999996],
          [21.7314783, 38.2466486],
          [21.7313772, 38.2467638],
          [21.7309244, 38.2465749],
          [21.730206799999998, 38.2462124],
          [21.7301304, 38.2461655],
          [21.72993, 38.2460386],
        ],
      },
      length: 309,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-2 -> BT-11",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.742147199999998, 38.251253299999995],
          [21.7423219, 38.2513911],
          [21.7429684, 38.251879699999996],
          [21.7427819, 38.2519849],
          [21.7426893, 38.252008599999996],
          [21.742024999999998, 38.2521591],
          [21.7415006, 38.2522857],
          [21.740925400000002, 38.252442599999995],
          [21.7401908, 38.252609899999996],
          [21.7401143, 38.252623],
          [21.7396662, 38.2526975],
          [21.7391009, 38.2528001],
          [21.7388798, 38.2528471],
          [21.738225699999997, 38.2529668],
          [21.7382613, 38.2530684],
        ],
      },
      length: 545,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-14 -> BT-2",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.736441300000003, 38.2506941],
          [21.7365417, 38.250622199999995],
          [21.7368757, 38.250356599999996],
          [21.7376015, 38.249794],
          [21.7379684, 38.2495091],
          [21.7383112, 38.249229799999995],
          [21.7389325, 38.2487609],
          [21.739478899999998, 38.249194800000005],
          [21.7397228, 38.2493824],
          [21.7402446, 38.2497743],
          [21.740409, 38.2498995],
          [21.740529199999997, 38.249991099999995],
          [21.7409613, 38.2503412],
          [21.7413985, 38.250674],
          [21.741727299999997, 38.250924499999996],
          [21.741820699999998, 38.250995599999996],
          [21.742143799999997, 38.2512506],
          [21.742147199999998, 38.251253299999995],
        ],
      },
      length: 701,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-15 -> BT-2",
      geometry: {
        coordinates: [
          [21.7391255, 38.2455681],
          [21.738965, 38.2456829],
          [21.7386743, 38.2459034],
          [21.7383986, 38.2461215],
          [21.7382421, 38.2462528],
          [21.7376301, 38.2467404],
          [21.737160199999998, 38.247109],
          [21.736996599999998, 38.2472348],
          [21.7380195, 38.2480604],
          [21.7383471, 38.248311799999996],
          [21.7389325, 38.2487609],
          [21.739478899999998, 38.249194800000005],
          [21.7397228, 38.2493824],
          [21.7402446, 38.2497743],
          [21.740529199999997, 38.249991099999995],
          [21.740848, 38.2502494],
          [21.7409613, 38.2503412],
          [21.7413985, 38.250674],
          [21.741820699999998, 38.250995599999996],
          [21.742147199999998, 38.251253299999995],
        ],
        type: "LineString",
      },
      length: 897,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-12 -> BT-13",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.733493499999998, 38.247582],
          [21.7333984, 38.2476656],
          [21.7341703, 38.248236999999996],
          [21.7345966, 38.2485572],
          [21.7349697, 38.2488465],
          [21.735193199999998, 38.2490196],
          [21.735356, 38.249147],
          [21.735476199999997, 38.2492393],
          [21.7360143, 38.249667699999996],
          [21.735639600000003, 38.249958],
          [21.735520899999997, 38.2498626],
          [21.7349817, 38.2494413],
          [21.734857299999998, 38.2493558],
          [21.734585199999998, 38.249136899999996],
          [21.7342075, 38.2488428],
          [21.7341299, 38.2487772],
          [21.7338208, 38.2485048],
          [21.7337196, 38.2484184],
          [21.7332312, 38.248038],
          [21.7330746, 38.2479065],
          [21.7329892, 38.2478488],
          [21.7328117, 38.2477267],
          [21.7323271, 38.2473617],
          [21.732171899999997, 38.2472358],
          [21.7318712, 38.2469956],
          [21.7317954, 38.2469526],
          [21.7315643, 38.2468396],
          [21.7313772, 38.2467638],
          [21.7309244, 38.2465749],
          [21.730206799999998, 38.2462124],
          [21.7301304, 38.2461655],
          [21.72993, 38.2460386],
        ],
      },
      length: 1043,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-13 -> BT-28",
      geometry: {
        coordinates: [
          [21.72993, 38.2460386],
          [21.7298347, 38.2459758],
          [21.7297573, 38.245928899999996],
          [21.7292786, 38.2456448],
          [21.7291924, 38.2455992],
          [21.728817799999998, 38.2453953],
          [21.7283094, 38.245061199999995],
          [21.7278236, 38.2447575],
          [21.727663099999997, 38.244671],
          [21.727296, 38.2444585],
          [21.7271301, 38.2443376],
          [21.726995199999998, 38.2442158],
          [21.726822, 38.2439714],
          [21.726741099999998, 38.2437362],
          [21.7266744, 38.243364],
          [21.726567199999998, 38.2424734],
          [21.7265098, 38.242129299999995],
          [21.7263666, 38.2412372],
          [21.7263385, 38.2411059],
          [21.726461999999998, 38.2410922],
          [21.727202, 38.2410082],
          [21.727252699999998, 38.2410035],
          [21.7273445, 38.2409965],
          [21.727571899999997, 38.2409755],
          [21.7278428, 38.2409505],
          [21.7287659, 38.240846],
          [21.7288794, 38.2409961],
          [21.729508700000004, 38.2414431],
          [21.729623999999998, 38.2415373],
          [21.729941399999998, 38.2417966],
          [21.7303258, 38.242087399999996],
          [21.7305989, 38.2422861],
          [21.730739399999997, 38.2423808],
          [21.7310089, 38.242591499999996],
          [21.731162599999998, 38.242712999999995],
          [21.7316443, 38.243093099999996],
          [21.7320877, 38.2434302],
          [21.731458999999997, 38.2439],
          [21.7311065, 38.2441798],
          [21.730786700000003, 38.2444373],
          [21.730102, 38.2450033],
          [21.7305283, 38.245334],
          [21.730721400000004, 38.2454916],
          [21.730916399999998, 38.2456508],
          [21.730982500000003, 38.2457034],
        ],
        type: "LineString",
      },
      length: 1658,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-14 -> BT-7",
      geometry: {
        coordinates: [
          [21.736441300000003, 38.2506941],
          [21.7365417, 38.250622199999995],
          [21.7360447, 38.25027660000001],
          [21.7358054, 38.25008210000001],
          [21.735639600000003, 38.249958],
          [21.735520899999997, 38.2498626],
          [21.7349817, 38.2494413],
          [21.7349122, 38.2493935],
          [21.734857299999998, 38.2493558],
          [21.734585199999998, 38.249136899999996],
          [21.7342075, 38.2488428],
          [21.7341299, 38.2487772],
          [21.7338208, 38.2485048],
          [21.7341703, 38.248236999999996],
          [21.734888599999998, 38.247654499999996],
          [21.735579599999998, 38.247094100000005],
          [21.735745299999998, 38.246969899999996],
          [21.736217099999998, 38.246614699999995],
          [21.736794699999997, 38.2461104],
          [21.7360008, 38.2455515],
          [21.735111, 38.2448438],
        ],
        type: "LineString",
      },
      length: 923,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-3 -> BT-14",
      geometry: {
        coordinates: [
          [21.7360641, 38.246508600000006],
          [21.736217099999998, 38.246614699999995],
          [21.736860699999998, 38.247126699999995],
          [21.736996599999998, 38.2472348],
          [21.7380195, 38.2480604],
          [21.737419799999998, 38.2485557],
          [21.7370737, 38.248822],
          [21.7370412, 38.2488458],
          [21.7367208, 38.2490935],
          [21.7364025, 38.2493522],
          [21.7362434, 38.2494815],
          [21.7360143, 38.249667699999996],
          [21.7364811, 38.2500277],
          [21.7367186, 38.2502257],
          [21.7368757, 38.250356599999996],
          [21.737490599999997, 38.250830199999996],
          [21.7375158, 38.2508534],
          [21.7378352, 38.2515417],
          [21.7378695, 38.2516314],
          [21.738013799999997, 38.2521213],
          [21.738184999999998, 38.252789799999995],
          [21.738225699999997, 38.2529668],
          [21.7382613, 38.2530684],
          [21.738262799999998, 38.253072599999996],
          [21.7383235, 38.253246],
          [21.7383916, 38.2535121],
          [21.738435799999998, 38.2536765],
          [21.738059999999997, 38.2537888],
          [21.737864, 38.2538349],
          [21.7376525, 38.2539039],
          [21.7372712, 38.2540325],
          [21.7372291, 38.2540445],
          [21.7371634, 38.254063099999996],
          [21.737115, 38.2539089],
          [21.7370348, 38.2535399],
          [21.7370177, 38.2534555],
          [21.736944299999998, 38.2531818],
          [21.736700700000004, 38.2523529],
          [21.7364784, 38.251556199999996],
          [21.7364421, 38.251425999999995],
          [21.7364098, 38.2512949],
          [21.7363793, 38.251171],
          [21.736337199999998, 38.2509942],
          [21.7363011, 38.2507946],
          [21.736441300000003, 38.2506941],
        ],
        type: "LineString",
      },
      length: 1511,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-25 -> BT-13",
      geometry: {
        coordinates: [
          [21.7352809, 38.249088300000004],
          [21.735356, 38.249147],
          [21.735476199999997, 38.2492393],
          [21.7360143, 38.249667699999996],
          [21.735639600000003, 38.249958],
          [21.735520899999997, 38.2498626],
          [21.7349817, 38.2494413],
          [21.734857299999998, 38.2493558],
          [21.734585199999998, 38.249136899999996],
          [21.7342075, 38.2488428],
          [21.7341299, 38.2487772],
          [21.7338208, 38.2485048],
          [21.7337196, 38.2484184],
          [21.7332312, 38.248038],
          [21.7330746, 38.2479065],
          [21.7329892, 38.2478488],
          [21.7328117, 38.2477267],
          [21.7323271, 38.2473617],
          [21.732171899999997, 38.2472358],
          [21.7318712, 38.2469956],
          [21.7317954, 38.2469526],
          [21.7315643, 38.2468396],
          [21.7313772, 38.2467638],
          [21.7309244, 38.2465749],
          [21.730206799999998, 38.2462124],
          [21.7301304, 38.2461655],
          [21.72993, 38.2460386],
        ],
        type: "LineString",
      },
      length: 803,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-26 -> BT-28",
      geometry: {
        coordinates: [
          [21.7261111, 38.2408834],
          [21.7261522, 38.2410662],
          [21.726176, 38.241121899999996],
          [21.7263385, 38.2411059],
          [21.726461999999998, 38.2410922],
          [21.726645599999998, 38.241963999999996],
          [21.7266904, 38.242346],
          [21.7267263, 38.2426457],
          [21.7268398, 38.243682299999996],
          [21.7269067, 38.243853099999995],
          [21.7269632, 38.243909699999996],
          [21.7270765, 38.243967999999995],
          [21.727299100000003, 38.244009],
          [21.7275219, 38.2439718],
          [21.7277181, 38.243897],
          [21.7278667, 38.2438086],
          [21.7283208, 38.2435451],
          [21.7284361, 38.2436388],
          [21.7288539, 38.243975500000005],
          [21.729177099999998, 38.2442303],
          [21.7292511, 38.2443034],
          [21.729689699999998, 38.2446632],
          [21.730102, 38.2450033],
          [21.7303654, 38.24520760000001],
          [21.7305283, 38.245334],
          [21.730721400000004, 38.2454916],
          [21.730916399999998, 38.2456508],
          [21.730982500000003, 38.2457034],
        ],
        type: "LineString",
      },
      length: 841,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-5 -> BT-7",
      geometry: {
        coordinates: [
          [21.747677499999998, 38.2552474],
          [21.747573799999998, 38.2552972],
          [21.747465599999998, 38.255462099999995],
          [21.7474505, 38.255522400000004],
          [21.7474418, 38.255590399999996],
          [21.747469799999998, 38.255780400000006],
          [21.7475377, 38.255964],
          [21.747649799999998, 38.256266],
          [21.7474927, 38.256395399999995],
          [21.7473866, 38.2564371],
          [21.7472626, 38.2564529],
          [21.7470908, 38.256364399999995],
          [21.746808599999998, 38.2562195],
          [21.7467172, 38.2561605],
          [21.746463, 38.2560201],
          [21.7462677, 38.2559566],
          [21.7460859, 38.255919],
          [21.7458992, 38.255974900000005],
          [21.7456975, 38.2560602],
          [21.7456225, 38.256078699999996],
          [21.744967, 38.2562406],
          [21.7446853, 38.2563132],
          [21.7445348, 38.256350499999996],
          [21.7442702, 38.2564219],
          [21.7440389, 38.2564703],
          [21.7435962, 38.2565628],
          [21.743503500000003, 38.2565867],
          [21.743418899999998, 38.2566302],
          [21.7433581, 38.2566309],
          [21.743324299999998, 38.25662210000001],
          [21.7432537, 38.2565653],
          [21.7432426, 38.2565497],
          [21.743231299999998, 38.2564798],
          [21.7432412, 38.2564542],
          [21.7430216, 38.2560777],
          [21.743006, 38.256034199999995],
          [21.742819, 38.255514999999995],
          [21.7425777, 38.2549126],
          [21.7423318, 38.2543302],
          [21.7422583, 38.2541211],
          [21.7421318, 38.253775399999995],
          [21.742055, 38.2536026],
          [21.741754399999998, 38.252923],
          [21.7416534, 38.2526805],
          [21.7416369, 38.2526307],
          [21.7415685, 38.2524575],
          [21.7415006, 38.2522857],
          [21.7413617, 38.2518913],
          [21.741239099999998, 38.2515632],
          [21.7411776, 38.2514725],
          [21.7411364, 38.251435],
          [21.7407853, 38.251156099999996],
          [21.7403644, 38.2508287],
          [21.7398891, 38.250473199999995],
          [21.7396452, 38.250274499999996],
          [21.7391328, 38.2498806],
          [21.738879999999998, 38.249676],
          [21.738797299999998, 38.249611099999996],
          [21.7383112, 38.249229799999995],
          [21.7389325, 38.2487609],
          [21.7392519, 38.2485032],
          [21.7395784, 38.24824160000001],
          [21.740067699999997, 38.247862999999995],
          [21.7401795, 38.2477634],
          [21.7405975, 38.2473806],
          [21.7396842, 38.2466795],
          [21.7386743, 38.2459034],
          [21.738340299999997, 38.2456573],
          [21.7381325, 38.2455147],
          [21.7379729, 38.2453857],
          [21.7378605, 38.245294799999996],
          [21.737707999999998, 38.2451933],
          [21.7375011, 38.245049099999996],
          [21.7371755, 38.244796199999996],
          [21.7370488, 38.2447003],
          [21.736708099999998, 38.244459899999995],
          [21.7361011, 38.244039099999995],
          [21.7357189, 38.2443704],
          [21.7355989, 38.2444639],
          [21.735111, 38.2448438],
        ],
        type: "LineString",
      },
      length: 2450,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-22 -> BT-14",
      geometry: {
        coordinates: [
          [21.7413235, 38.2515492],
          [21.7417855, 38.2514727],
          [21.7421284, 38.2514244],
          [21.7423219, 38.2513911],
          [21.7429684, 38.251879699999996],
          [21.7427819, 38.2519849],
          [21.7426893, 38.252008599999996],
          [21.742024999999998, 38.2521591],
          [21.7415006, 38.2522857],
          [21.740925400000002, 38.252442599999995],
          [21.7401908, 38.252609899999996],
          [21.7401143, 38.252623],
          [21.7396662, 38.2526975],
          [21.7391009, 38.2528001],
          [21.7388798, 38.2528471],
          [21.738225699999997, 38.2529668],
          [21.7376312, 38.253065899999996],
          [21.7375865, 38.2530734],
          [21.737372500000003, 38.2531095],
          [21.736944299999998, 38.2531818],
          [21.736700700000004, 38.2523529],
          [21.7364784, 38.251556199999996],
          [21.7364421, 38.251425999999995],
          [21.7364098, 38.2512949],
          [21.7363793, 38.251171],
          [21.736337199999998, 38.2509942],
          [21.7363011, 38.2507946],
          [21.736441300000003, 38.2506941],
        ],
        type: "LineString",
      },
      length: 1002,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-18 -> BT-10",
      geometry: {
        coordinates: [
          [21.7343761, 38.24273900000001],
          [21.734665800000002, 38.2426137],
          [21.7347258, 38.242595699999995],
          [21.7352202, 38.2423851],
          [21.734927199999998, 38.241981900000006],
          [21.7348772, 38.2419131],
          [21.7348069, 38.241830400000005],
          [21.7345813, 38.2415798],
          [21.7342884, 38.2412768],
          [21.7342069, 38.241194],
          [21.7341276, 38.2411473],
          [21.7337965, 38.241029],
          [21.733608099999998, 38.2409268],
          [21.7334751, 38.240839099999995],
          [21.7334691, 38.240832499999996],
          [21.733424199999998, 38.2407256],
          [21.733274299999998, 38.2399562],
          [21.733178499999998, 38.239438299999996],
          [21.733102799999998, 38.2390555],
          [21.7330588, 38.238763999999996],
          [21.733017099999998, 38.2385872],
          [21.732922499999997, 38.2381866],
          [21.7327948, 38.2375992],
          [21.7319055, 38.2376994],
          [21.7315351, 38.2377439],
          [21.7309226, 38.237809399999996],
          [21.730511099999998, 38.2378589],
          [21.7306147, 38.2384671],
          [21.7306494, 38.238614],
          [21.7308125, 38.239322],
          [21.7309528, 38.2399706],
          [21.7309727, 38.2400531],
          [21.7311004, 38.2405833],
          [21.731241999999998, 38.2405712],
        ],
        type: "LineString",
      },
      length: 1206,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-22 -> BT-7",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7412088, 38.251512],
          [21.7411776, 38.2514725],
          [21.7411073, 38.2514119],
          [21.7407853, 38.251156099999996],
          [21.7403644, 38.2508287],
          [21.7398891, 38.250473199999995],
          [21.7396452, 38.250274499999996],
          [21.7391328, 38.2498806],
          [21.738879999999998, 38.249676],
          [21.738797299999998, 38.249611099999996],
          [21.7383112, 38.249229799999995],
          [21.7389325, 38.2487609],
          [21.7392519, 38.2485032],
          [21.7395784, 38.24824160000001],
          [21.740067699999997, 38.247862999999995],
          [21.7401795, 38.2477634],
          [21.7405975, 38.2473806],
          [21.7396842, 38.2466795],
          [21.7386743, 38.2459034],
          [21.738340299999997, 38.2456573],
          [21.7381325, 38.2455147],
          [21.7379729, 38.2453857],
          [21.7378605, 38.245294799999996],
          [21.737707999999998, 38.2451933],
          [21.7375011, 38.245049099999996],
          [21.7371755, 38.244796199999996],
          [21.7370488, 38.2447003],
          [21.736708099999998, 38.244459899999995],
          [21.7361011, 38.244039099999995],
          [21.7357189, 38.2443704],
          [21.7355989, 38.2444639],
          [21.735111, 38.2448438],
        ],
      },
      length: 1311,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-1 -> BT-5",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7466078, 38.2558406],
          [21.746633199999998, 38.2557656],
          [21.747014099999998, 38.2555385],
          [21.7473216, 38.2553625],
          [21.747401300000003, 38.2552962],
          [21.747502, 38.2552163],
          [21.7478911, 38.255026699999995],
          [21.748148699999998, 38.2549227],
          [21.7482011, 38.254904499999995],
          [21.7483895, 38.2548388],
          [21.7489796, 38.254701],
          [21.750289799999997, 38.2544459],
          [21.750720599999998, 38.2543244],
          [21.751211899999998, 38.2541247],
          [21.7515137, 38.2539509],
          [21.751842800000002, 38.2537145],
          [21.7528938, 38.2528891],
          [21.7529353, 38.2528581],
          [21.752978499999998, 38.2528259],
          [21.753646399999997, 38.2523335],
          [21.7546897, 38.2516313],
          [21.755001099999998, 38.251368],
          [21.7551364, 38.2512009],
          [21.7551845, 38.2511127],
          [21.7552026, 38.25107],
          [21.755209999999998, 38.2510507],
          [21.7552818, 38.2508147],
          [21.7552837, 38.250794299999995],
          [21.7554098, 38.2494365],
          [21.755459800000004, 38.2488983],
          [21.755460900000003, 38.248789699999996],
          [21.7554481, 38.248653999999995],
          [21.755438500000004, 38.248610899999996],
          [21.7553855, 38.2484362],
          [21.7552216, 38.2481948],
          [21.7550577, 38.248035],
          [21.755001099999998, 38.2479946],
          [21.7549575, 38.2479635],
          [21.7546084, 38.2477454],
          [21.7544588, 38.2476644],
          [21.7541428, 38.2475149],
          [21.7538521, 38.2474144],
          [21.753726399999998, 38.2473781],
          [21.7538029, 38.2472747],
          [21.7542226, 38.2474175],
          [21.7545221, 38.2475625],
          [21.7550444, 38.2478796],
          [21.7551, 38.247916],
          [21.755253, 38.248045700000006],
          [21.755374500000002, 38.248175499999995],
          [21.7554655, 38.248325099999995],
          [21.755515199999998, 38.248425399999995],
          [21.755586599999997, 38.248671],
          [21.755603999999998, 38.248885699999995],
          [21.7555702, 38.249244399999995],
          [21.755419699999997, 38.2508427],
          [21.755351299999997, 38.2510387],
          [21.755297, 38.251160299999995],
          [21.755274999999997, 38.2512018],
          [21.755256, 38.2512377],
          [21.7551553, 38.2513818],
          [21.754932999999998, 38.251591999999995],
          [21.7542524, 38.2520731],
          [21.753984199999998, 38.2522559],
          [21.753222299999997, 38.2527873],
          [21.751752, 38.2539216],
          [21.751394299999998, 38.254145799999996],
          [21.7509445, 38.254361599999996],
          [21.7504365, 38.254522699999995],
          [21.749483899999998, 38.2547213],
          [21.748815399999998, 38.254844999999996],
          [21.7483819, 38.2549606],
          [21.747879700000002, 38.2551504],
          [21.747677499999998, 38.2552474],
        ],
      },
      length: 2782,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-17 -> BT-16",
      geometry: {
        coordinates: [
          [21.7419911, 38.2399578],
          [21.7421219, 38.2401686],
          [21.7423252, 38.2404748],
          [21.7424306, 38.2406345],
          [21.742656, 38.2409696],
          [21.7430639, 38.240807],
          [21.7432222, 38.2407501],
          [21.7436294, 38.2405854],
          [21.743833499999997, 38.240496199999995],
          [21.7438881, 38.2404712],
          [21.7441046, 38.2403796],
          [21.7444484, 38.240238],
          [21.7449141, 38.2400477],
          [21.7451021, 38.23996460000001],
        ],
        type: "LineString",
      },
      length: 368,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-16 -> BT-5",
      geometry: {
        coordinates: [
          [21.7452693, 38.240012899999996],
          [21.7452312, 38.239902199999996],
          [21.745200399999998, 38.239745299999996],
          [21.7451677, 38.2395673],
          [21.7451257, 38.2394351],
          [21.7450927, 38.2393084],
          [21.7450922, 38.239303],
          [21.7450759, 38.2391236],
          [21.745061699999997, 38.2386803],
          [21.7450558, 38.2385004],
          [21.744650099999998, 38.2383562],
          [21.7449782, 38.238137099999996],
          [21.7451465, 38.238026],
          [21.7452425, 38.237859199999995],
          [21.745381, 38.2378463],
          [21.7453507, 38.2379259],
          [21.7452041, 38.2382524],
          [21.745179699999998, 38.2383318],
          [21.745162699999998, 38.2385217],
          [21.745169, 38.2386292],
          [21.7451717, 38.238696],
          [21.745194599999998, 38.2391305],
          [21.7451924, 38.239291699999995],
          [21.745227099999997, 38.23941],
          [21.745280100000002, 38.2395497],
          [21.7453538, 38.2398318],
          [21.745374599999998, 38.2400109],
          [21.745397099999998, 38.2402042],
          [21.745326499999997, 38.2403948],
          [21.7452036, 38.2405259],
          [21.744939499999997, 38.2409244],
          [21.7448282, 38.2410795],
          [21.7447882, 38.2411357],
          [21.7444849, 38.2415823],
          [21.7443864, 38.2417517],
          [21.7443143, 38.2419108],
          [21.744237899999998, 38.2420852],
          [21.7440604, 38.2424986],
          [21.743987399999998, 38.2426697],
          [21.743967899999998, 38.242739799999995],
          [21.7438444, 38.243093699999996],
          [21.743772999999997, 38.2432526],
          [21.7437608, 38.243274899999996],
          [21.7438105, 38.2433912],
          [21.7439584, 38.243562399999995],
          [21.7441779, 38.24361],
          [21.744937999999998, 38.243682199999995],
          [21.745164, 38.2437112],
          [21.7454602, 38.243749199999996],
          [21.7456124, 38.2437823],
          [21.7462149, 38.243911499999996],
          [21.7467178, 38.244017],
          [21.746936899999998, 38.244068999999996],
          [21.7478062, 38.2443157],
          [21.7480111, 38.244396099999996],
          [21.7489168, 38.2447516],
          [21.749340699999998, 38.2449314],
          [21.7505585, 38.2454311],
          [21.751160499999997, 38.245691],
          [21.7515401, 38.2458917],
          [21.7520469, 38.2462555],
          [21.752108399999997, 38.2463063],
          [21.7525105, 38.246653599999995],
          [21.7528766, 38.246947],
          [21.753211399999998, 38.2471065],
          [21.7536421, 38.247229],
          [21.7538029, 38.2472747],
          [21.754016, 38.2473402],
          [21.754419700000003, 38.2475088],
          [21.7545221, 38.2475625],
          [21.7550444, 38.2478796],
          [21.755065, 38.2478931],
          [21.755253, 38.248045700000006],
          [21.755374500000002, 38.248175499999995],
          [21.755515199999998, 38.248425399999995],
          [21.755586599999997, 38.248671],
          [21.755603999999998, 38.248885699999995],
          [21.7555702, 38.249244399999995],
          [21.755419699999997, 38.2508427],
          [21.755351299999997, 38.2510387],
          [21.755297, 38.251160299999995],
          [21.755274999999997, 38.2512018],
          [21.755256, 38.2512377],
          [21.7551553, 38.2513818],
          [21.754932999999998, 38.251591999999995],
          [21.7542524, 38.2520731],
          [21.753984199999998, 38.2522559],
          [21.753222299999997, 38.2527873],
          [21.751752, 38.2539216],
          [21.751394299999998, 38.254145799999996],
          [21.7509445, 38.254361599999996],
          [21.7504365, 38.254522699999995],
          [21.749483899999998, 38.2547213],
          [21.748815399999998, 38.254844999999996],
          [21.7483819, 38.2549606],
          [21.747879700000002, 38.2551504],
          [21.747677499999998, 38.2552474],
        ],
        type: "LineString",
      },
      length: 3286,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-2 -> BT-7",
      geometry: {
        coordinates: [
          [21.742147199999998, 38.251253299999995],
          [21.7423219, 38.2513911],
          [21.7429684, 38.251879699999996],
          [21.7427819, 38.2519849],
          [21.7426893, 38.252008599999996],
          [21.742024999999998, 38.2521591],
          [21.7415006, 38.2522857],
          [21.740925400000002, 38.252442599999995],
          [21.7401908, 38.252609899999996],
          [21.7401143, 38.252623],
          [21.7396662, 38.2526975],
          [21.7391009, 38.2528001],
          [21.7388798, 38.2528471],
          [21.738225699999997, 38.2529668],
          [21.7376312, 38.253065899999996],
          [21.7375865, 38.2530734],
          [21.737372500000003, 38.2531095],
          [21.736944299999998, 38.2531818],
          [21.736700700000004, 38.2523529],
          [21.7364784, 38.251556199999996],
          [21.7364421, 38.251425999999995],
          [21.7364098, 38.2512949],
          [21.7363793, 38.251171],
          [21.736337199999998, 38.2509942],
          [21.7363011, 38.2507946],
          [21.736241, 38.2505747],
          [21.736161199999998, 38.2504087],
          [21.7360447, 38.25027660000001],
          [21.735971199999998, 38.2502062],
          [21.735639600000003, 38.249958],
          [21.735520899999997, 38.2498626],
          [21.7349817, 38.2494413],
          [21.7349122, 38.2493935],
          [21.734857299999998, 38.2493558],
          [21.734585199999998, 38.249136899999996],
          [21.7342075, 38.2488428],
          [21.7341299, 38.2487772],
          [21.7338208, 38.2485048],
          [21.7341703, 38.248236999999996],
          [21.734888599999998, 38.247654499999996],
          [21.735579599999998, 38.247094100000005],
          [21.735745299999998, 38.246969899999996],
          [21.736217099999998, 38.246614699999995],
          [21.736794699999997, 38.2461104],
          [21.7360008, 38.2455515],
          [21.735111, 38.2448438],
        ],
        type: "LineString",
      },
      length: 1834,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-6 -> BT-1",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7446205, 38.2538107],
          [21.7446873, 38.253762],
          [21.7448175, 38.253909799999995],
          [21.7450144, 38.2541353],
          [21.7453051, 38.254471699999996],
          [21.745407999999998, 38.25458810000001],
          [21.745582799999998, 38.2547934],
          [21.745659399999997, 38.2548901],
          [21.745997199999998, 38.2552867],
          [21.74635, 38.2556991],
          [21.746457, 38.255821499999996],
          [21.746633199999998, 38.2557656],
          [21.747014099999998, 38.2555385],
          [21.7472147, 38.2555465],
          [21.7473077, 38.2555802],
          [21.7473982, 38.2556584],
          [21.747469799999998, 38.255780400000006],
          [21.7475377, 38.255964],
          [21.747649799999998, 38.256266],
          [21.7474927, 38.256395399999995],
          [21.7473866, 38.2564371],
          [21.7472626, 38.2564529],
          [21.7470908, 38.256364399999995],
          [21.746808599999998, 38.2562195],
          [21.7467172, 38.2561605],
          [21.7466325, 38.2560344],
          [21.7466071, 38.2559617],
          [21.7465992, 38.2558764],
          [21.7466078, 38.2558406],
        ],
      },
      length: 589,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-7 -> BT-13",
      geometry: {
        coordinates: [
          [21.735111, 38.2448438],
          [21.7347164, 38.2445218],
          [21.7343396, 38.2442171],
          [21.733729699999998, 38.2447221],
          [21.733147400000004, 38.2452237],
          [21.732456000000003, 38.245788499999996],
          [21.7317884, 38.246345999999996],
          [21.7316525, 38.2464763],
          [21.7315073, 38.246615399999996],
          [21.7314783, 38.2466486],
          [21.7313772, 38.2467638],
          [21.7309244, 38.2465749],
          [21.730206799999998, 38.2462124],
          [21.7301304, 38.2461655],
          [21.72993, 38.2460386],
        ],
        type: "LineString",
      },
      length: 631,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-24 -> BT-14",
      geometry: {
        coordinates: [
          [21.736756699999997, 38.2525434],
          [21.736700700000004, 38.2523529],
          [21.7364784, 38.251556199999996],
          [21.7364421, 38.251425999999995],
          [21.7364098, 38.2512949],
          [21.736339599999997, 38.2510099],
          [21.736337199999998, 38.2509942],
          [21.7363011, 38.2507946],
          [21.736441300000003, 38.2506941],
        ],
        type: "LineString",
      },
      length: 216,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-13 -> BT-26",
      geometry: {
        coordinates: [
          [21.72993, 38.2460386],
          [21.7298347, 38.2459758],
          [21.7297573, 38.245928899999996],
          [21.7292786, 38.2456448],
          [21.7291924, 38.2455992],
          [21.728817799999998, 38.2453953],
          [21.7283094, 38.245061199999995],
          [21.7278236, 38.2447575],
          [21.727663099999997, 38.244671],
          [21.727296, 38.2444585],
          [21.7271301, 38.2443376],
          [21.726995199999998, 38.2442158],
          [21.726822, 38.2439714],
          [21.726741099999998, 38.2437362],
          [21.7266744, 38.243364],
          [21.726567199999998, 38.2424734],
          [21.7265098, 38.242129299999995],
          [21.7263666, 38.2412372],
          [21.7263385, 38.2411059],
          [21.726176, 38.241121899999996],
          [21.7261522, 38.2410662],
          [21.7261111, 38.2408834],
        ],
        type: "LineString",
      },
      length: 721,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-22 -> BT-2",
      geometry: {
        coordinates: [
          [21.7412088, 38.251512],
          [21.7411776, 38.2514725],
          [21.7411073, 38.2514119],
          [21.7407853, 38.251156099999996],
          [21.7403644, 38.2508287],
          [21.7398891, 38.250473199999995],
          [21.7396452, 38.250274499999996],
          [21.7391328, 38.2498806],
          [21.738879999999998, 38.249676],
          [21.738797299999998, 38.249611099999996],
          [21.7383112, 38.249229799999995],
          [21.7389325, 38.2487609],
          [21.739478899999998, 38.249194800000005],
          [21.7397228, 38.2493824],
          [21.7402446, 38.2497743],
          [21.740409, 38.2498995],
          [21.740529199999997, 38.249991099999995],
          [21.7409613, 38.2503412],
          [21.7413985, 38.250674],
          [21.741727299999997, 38.250924499999996],
          [21.741820699999998, 38.250995599999996],
          [21.742143799999997, 38.2512506],
          [21.742147199999998, 38.251253299999995],
        ],
        type: "LineString",
      },
      length: 829,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-25 -> BT-7",
      geometry: {
        coordinates: [
          [21.7352809, 38.249088300000004],
          [21.735356, 38.249147],
          [21.735476199999997, 38.2492393],
          [21.7360143, 38.249667699999996],
          [21.735639600000003, 38.249958],
          [21.735520899999997, 38.2498626],
          [21.7349817, 38.2494413],
          [21.734857299999998, 38.2493558],
          [21.734585199999998, 38.249136899999996],
          [21.7342075, 38.2488428],
          [21.7341299, 38.2487772],
          [21.7338208, 38.2485048],
          [21.7341703, 38.248236999999996],
          [21.734888599999998, 38.247654499999996],
          [21.735579599999998, 38.247094100000005],
          [21.735745299999998, 38.246969899999996],
          [21.736217099999998, 38.246614699999995],
          [21.736794699999997, 38.2461104],
          [21.7360008, 38.2455515],
          [21.735111, 38.2448438],
        ],
        type: "LineString",
      },
      length: 940,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-26 -> BT-27",
      geometry: {
        coordinates: [
          [21.7261111, 38.2408834],
          [21.7261522, 38.2410662],
          [21.726176, 38.241121899999996],
          [21.7263385, 38.2411059],
          [21.726246699999997, 38.2407897],
          [21.7260672, 38.239879699999996],
          [21.7260237, 38.239659599999996],
          [21.7258747, 38.2389957],
          [21.725748799999998, 38.238462],
          [21.7256452, 38.2379286],
          [21.725405499999997, 38.236694899999996],
          [21.7253228, 38.236204099999995],
          [21.725249599999998, 38.2358688],
          [21.724968999999998, 38.234532],
          [21.7247892, 38.2336052],
          [21.7247099, 38.2332202],
        ],
        type: "LineString",
      },
      length: 929,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-21 -> BT-6",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7432327, 38.2565272],
          [21.7432412, 38.2564542],
          [21.7433124, 38.256399699999996],
          [21.7434323, 38.256388099999995],
          [21.7436188, 38.2560432],
          [21.743783300000004, 38.2556493],
          [21.743801899999998, 38.25556220000001],
          [21.7440484, 38.2550798],
          [21.744123, 38.2549097],
          [21.7441976, 38.2547397],
          [21.7443062, 38.2545292],
          [21.7443743, 38.2543575],
          [21.7445795, 38.253840499999995],
          [21.7446205, 38.2538107],
        ],
      },
      length: 334,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-19 -> BT-7",
      geometry: {
        coordinates: [
          [21.7321779, 38.2445066],
          [21.732214799999998, 38.244473400000004],
          [21.7328102, 38.2440023],
          [21.7334075, 38.2435101],
          [21.7339886, 38.243029899999996],
          [21.734525599999998, 38.243439699999996],
          [21.7347349, 38.243586],
          [21.734912599999998, 38.2437007],
          [21.735106299999998, 38.243849],
          [21.7353077, 38.2440036],
          [21.7354691, 38.2441473],
          [21.7357189, 38.2443704],
          [21.735111, 38.2448438],
        ],
        type: "LineString",
      },
      length: 516,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-25 -> BT-28",
      geometry: {
        coordinates: [
          [21.7352809, 38.249088300000004],
          [21.735356, 38.249147],
          [21.735476199999997, 38.2492393],
          [21.7360143, 38.249667699999996],
          [21.735639600000003, 38.249958],
          [21.735520899999997, 38.2498626],
          [21.7349817, 38.2494413],
          [21.734857299999998, 38.2493558],
          [21.734585199999998, 38.249136899999996],
          [21.7342075, 38.2488428],
          [21.7341299, 38.2487772],
          [21.7338208, 38.2485048],
          [21.7337196, 38.2484184],
          [21.7332312, 38.248038],
          [21.7330746, 38.2479065],
          [21.7329892, 38.2478488],
          [21.7328117, 38.2477267],
          [21.7323271, 38.2473617],
          [21.732171899999997, 38.2472358],
          [21.7318712, 38.2469956],
          [21.7317954, 38.2469526],
          [21.7315643, 38.2468396],
          [21.7313772, 38.2467638],
          [21.7309244, 38.2465749],
          [21.730206799999998, 38.2462124],
          [21.730287399999998, 38.2461398],
          [21.7305343, 38.2459177],
          [21.7308639, 38.2456878],
        ],
        type: "LineString",
      },
      length: 855,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-17 -> BT-27",
      geometry: {
        coordinates: [
          [21.7419911, 38.2399578],
          [21.7421219, 38.2401686],
          [21.7423252, 38.2404748],
          [21.741654099999998, 38.240769300000004],
          [21.7413885, 38.2404051],
          [21.7411806, 38.2400928],
          [21.740441999999998, 38.2403852],
          [21.739907100000003, 38.2405879],
          [21.7396979, 38.2406611],
          [21.7392507, 38.240846],
          [21.739172, 38.240877600000005],
          [21.738968099999997, 38.240959499999995],
          [21.7386682, 38.241077],
          [21.7385762, 38.2411093],
          [21.738158, 38.2412559],
          [21.7379215, 38.2413522],
          [21.7375149, 38.2415178],
          [21.7374704, 38.2415324],
          [21.7368421, 38.2417502],
          [21.73611, 38.2420399],
          [21.7354272, 38.242308200000004],
          [21.7352202, 38.2423851],
          [21.7347258, 38.242595699999995],
          [21.734665800000002, 38.2426137],
          [21.7344361, 38.242708799999996],
          [21.7342619, 38.2427966],
          [21.7339886, 38.243029899999996],
          [21.7334075, 38.2435101],
          [21.7328102, 38.2440023],
          [21.733349399999998, 38.244431999999996],
          [21.733729699999998, 38.2447221],
          [21.733147400000004, 38.2452237],
          [21.7326147, 38.2456588],
          [21.732456000000003, 38.245788499999996],
          [21.7317884, 38.246345999999996],
          [21.7315073, 38.246615399999996],
          [21.7314783, 38.2466486],
          [21.7313772, 38.2467638],
          [21.7309244, 38.2465749],
          [21.730206799999998, 38.2462124],
          [21.7301304, 38.2461655],
          [21.7298347, 38.2459758],
          [21.7297573, 38.245928899999996],
          [21.7292786, 38.2456448],
          [21.728817799999998, 38.2453953],
          [21.7283094, 38.245061199999995],
          [21.7278236, 38.2447575],
          [21.727663099999997, 38.244671],
          [21.727296, 38.2444585],
          [21.7271301, 38.2443376],
          [21.726995199999998, 38.2442158],
          [21.726822, 38.2439714],
          [21.726741099999998, 38.2437362],
          [21.7266744, 38.243364],
          [21.726567199999998, 38.2424734],
          [21.7265098, 38.242129299999995],
          [21.7263666, 38.2412372],
          [21.7263385, 38.2411059],
          [21.726246699999997, 38.2407897],
          [21.7261985, 38.2405464],
          [21.7260672, 38.239879699999996],
          [21.7260237, 38.239659599999996],
          [21.7258747, 38.2389957],
          [21.725748799999998, 38.238462],
          [21.7256797, 38.2381064],
          [21.7256452, 38.2379286],
          [21.725405499999997, 38.236694899999996],
          [21.7253228, 38.236204099999995],
          [21.725249599999998, 38.2358688],
          [21.724968999999998, 38.234532],
          [21.7247892, 38.2336052],
          [21.7247099, 38.2332202],
        ],
        type: "LineString",
      },
      length: 3215,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-9 -> BT-7",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7361011, 38.244039099999995],
          [21.7357189, 38.2443704],
          [21.7355989, 38.2444639],
          [21.735111, 38.2448438],
        ],
      },
      length: 124,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-15 -> BT-7",
      geometry: {
        coordinates: [
          [21.7391255, 38.2455681],
          [21.738965, 38.2456829],
          [21.7386743, 38.2459034],
          [21.7383986, 38.2461215],
          [21.7382421, 38.2462528],
          [21.7376301, 38.2467404],
          [21.736794699999997, 38.2461104],
          [21.7365127, 38.245911199999995],
          [21.7360008, 38.2455515],
          [21.7359172, 38.2454824],
          [21.735111, 38.2448438],
        ],
        type: "LineString",
      },
      length: 490,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-20 -> BT-1",
      geometry: {
        coordinates: [
          [21.7583991, 38.2725886],
          [21.7582433, 38.2724066],
          [21.7582012, 38.2723575],
          [21.757879799999998, 38.271939499999995],
          [21.7576435, 38.271631299999996],
          [21.757432, 38.2713488],
          [21.757113999999998, 38.270937599999996],
          [21.756926699999998, 38.2706608],
          [21.756856199999998, 38.270558099999995],
          [21.7567015, 38.2703487],
          [21.7566018, 38.2702161],
          [21.7560035, 38.2694592],
          [21.7555856, 38.2689033],
          [21.754919400000002, 38.2680617],
          [21.754797999999997, 38.2679092],
          [21.7544306, 38.2674532],
          [21.7540706, 38.2669937],
          [21.7536791, 38.2664541],
          [21.7536022, 38.2663618],
          [21.7534898, 38.2662051],
          [21.7524771, 38.2648083],
          [21.7519282, 38.2640624],
          [21.7517633, 38.263847399999996],
          [21.7511674, 38.263079399999995],
          [21.750131900000003, 38.261744799999995],
          [21.749978499999997, 38.2615324],
          [21.7499598, 38.2615076],
          [21.7495643, 38.2609626],
          [21.7492594, 38.2605423],
          [21.7489334, 38.2600976],
          [21.748888299999997, 38.260035699999996],
          [21.7488448, 38.259988299999996],
          [21.748505500000004, 38.2595609],
          [21.7482866, 38.259268299999995],
          [21.7481403, 38.2590012],
          [21.7481063, 38.2589098],
          [21.7479981, 38.2584741],
          [21.7479869, 38.2582375],
          [21.7479829, 38.2576494],
          [21.7479831, 38.257523],
          [21.747961699999998, 38.25725200000001],
          [21.7479349, 38.257144],
          [21.7478865, 38.256989],
          [21.747827299999997, 38.256865499999996],
          [21.7477998, 38.2568231],
          [21.7477104, 38.2567234],
          [21.7474389, 38.2565428],
          [21.7472626, 38.2564529],
          [21.7470908, 38.256364399999995],
          [21.746808599999998, 38.2562195],
          [21.7467172, 38.2561605],
          [21.7466325, 38.2560344],
          [21.7466071, 38.2559617],
          [21.7465992, 38.2558764],
          [21.7466078, 38.2558406],
        ],
        type: "LineString",
      },
      length: 2162,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-19 -> BT-26",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7321827, 38.2444485],
          [21.7318499, 38.244190599999996],
          [21.731458999999997, 38.2439],
          [21.7310444, 38.2435754],
          [21.7305299, 38.243185499999996],
          [21.729989699999997, 38.2427877],
          [21.7298835, 38.242688],
          [21.7293104, 38.242283],
          [21.728999299999998, 38.242019],
          [21.7288692, 38.241908599999995],
          [21.728704399999998, 38.2417837],
          [21.7279895, 38.2412369],
          [21.727890199999997, 38.24113620000001],
          [21.7278715, 38.2411051],
          [21.7278428, 38.2409505],
          [21.727571899999997, 38.2409755],
          [21.7273445, 38.2409965],
          [21.727252699999998, 38.2410035],
          [21.727202, 38.2410082],
          [21.726461999999998, 38.2410922],
          [21.7263385, 38.2411059],
          [21.726176, 38.241121899999996],
          [21.7261522, 38.2410662],
          [21.7261111, 38.2408834],
        ],
      },
      length: 721,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-19 -> BT-28",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7321779, 38.2445066],
          [21.731563599999998, 38.245076999999995],
          [21.731237399999998, 38.2453973],
          [21.7310756, 38.245530699999996],
          [21.730916399999998, 38.2456508],
          [21.730982500000003, 38.2457034],
        ],
      },
      length: 176,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-18 -> BT-7",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7343761, 38.24273900000001],
          [21.7342619, 38.2427966],
          [21.7339886, 38.243029899999996],
          [21.734525599999998, 38.243439699999996],
          [21.7347349, 38.243586],
          [21.734912599999998, 38.2437007],
          [21.735106299999998, 38.243849],
          [21.7353077, 38.2440036],
          [21.7354691, 38.2441473],
          [21.7357189, 38.2443704],
          [21.735111, 38.2448438],
        ],
      },
      length: 335,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-6 -> BT-11",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7446205, 38.2538107],
          [21.7445795, 38.253840499999995],
          [21.7445788, 38.253842299999995],
          [21.7443062, 38.2545292],
          [21.7442836, 38.254573],
          [21.7440484, 38.2550798],
          [21.7438872, 38.255395299999996],
          [21.743801899999998, 38.25556220000001],
          [21.743783300000004, 38.2556493],
          [21.743761499999998, 38.2557014],
          [21.7436188, 38.2560432],
          [21.743611299999998, 38.256057],
          [21.7434323, 38.256388099999995],
          [21.743509799999998, 38.2564397],
          [21.7435355, 38.2565148],
          [21.743503500000003, 38.2565867],
          [21.743418899999998, 38.2566302],
          [21.7433581, 38.2566309],
          [21.743324299999998, 38.25662210000001],
          [21.7432537, 38.2565653],
          [21.7432426, 38.2565497],
          [21.743231299999998, 38.2564798],
          [21.7432412, 38.2564542],
          [21.7430216, 38.2560777],
          [21.743006, 38.256034199999995],
          [21.742819, 38.255514999999995],
          [21.7425777, 38.2549126],
          [21.7423318, 38.2543302],
          [21.7423192, 38.2542945],
          [21.7422583, 38.2541211],
          [21.7421318, 38.253775399999995],
          [21.742055, 38.2536026],
          [21.741754399999998, 38.252923],
          [21.7416534, 38.2526805],
          [21.7416369, 38.2526307],
          [21.7415685, 38.2524575],
          [21.7415006, 38.2522857],
          [21.740925400000002, 38.252442599999995],
          [21.7401908, 38.252609899999996],
          [21.7401143, 38.252623],
          [21.7396662, 38.2526975],
          [21.7391009, 38.2528001],
          [21.7388798, 38.2528471],
          [21.738225699999997, 38.2529668],
          [21.7382613, 38.2530684],
        ],
      },
      length: 1168,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-4 -> BT-8",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7289083, 38.2408303],
          [21.729351599999998, 38.2407778],
          [21.730045399999998, 38.2406988],
          [21.730145, 38.240688],
        ],
      },
      length: 109,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-7 -> BT-12",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.735111, 38.2448438],
          [21.7347164, 38.2445218],
          [21.7343396, 38.2442171],
          [21.733729699999998, 38.2447221],
          [21.733147400000004, 38.2452237],
          [21.732456000000003, 38.245788499999996],
          [21.7317884, 38.246345999999996],
          [21.7321472, 38.2466332],
          [21.732519999999997, 38.246926599999995],
          [21.732995499999998, 38.2473102],
          [21.7333984, 38.2476656],
          [21.733493499999998, 38.247582],
        ],
      },
      length: 637,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-17 -> BT-10",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7419911, 38.2399578],
          [21.7421219, 38.2401686],
          [21.7423252, 38.2404748],
          [21.741654099999998, 38.240769300000004],
          [21.7413885, 38.2404051],
          [21.7411806, 38.2400928],
          [21.740441999999998, 38.2403852],
          [21.739907100000003, 38.2405879],
          [21.7396979, 38.2406611],
          [21.739321399999998, 38.2401747],
          [21.7389272, 38.2396502],
          [21.7385211, 38.2391259],
          [21.7384076, 38.2389697],
          [21.738064299999998, 38.2385109],
          [21.7379366, 38.2383616],
          [21.7376567, 38.2380108],
          [21.7373963, 38.2377149],
          [21.7373403, 38.237653699999996],
          [21.7368945, 38.2371565],
          [21.7369317, 38.2370545],
          [21.7362856, 38.237126599999996],
          [21.7358548, 38.2371705],
          [21.7357882, 38.2371906],
          [21.735579299999998, 38.237234099999995],
          [21.7354168, 38.2372484],
          [21.7353225, 38.2372693],
          [21.7345686, 38.2374047],
          [21.734497299999997, 38.2374128],
          [21.7338173, 38.237489599999996],
          [21.7327948, 38.2375992],
          [21.7319055, 38.2376994],
          [21.7315351, 38.2377439],
          [21.7309226, 38.237809399999996],
          [21.730511099999998, 38.2378589],
          [21.7306147, 38.2384671],
          [21.7306494, 38.238614],
          [21.7308125, 38.239322],
          [21.7309528, 38.2399706],
          [21.7309727, 38.2400531],
          [21.7311004, 38.2405833],
          [21.731241999999998, 38.2405712],
        ],
      },
      length: 1721,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-2 -> BT-6",
      geometry: {
        coordinates: [
          [21.742147199999998, 38.251253299999995],
          [21.7423219, 38.2513911],
          [21.7429684, 38.251879699999996],
          [21.743133300000004, 38.2520068],
          [21.7434263, 38.252283],
          [21.743498, 38.252357499999995],
          [21.7436063, 38.2524867],
          [21.7442496, 38.2532475],
          [21.7445071, 38.2535385],
          [21.7446873, 38.253762],
          [21.7446205, 38.2538107],
        ],
        type: "LineString",
      },
      length: 366,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-2 -> BT-9",
      geometry: {
        coordinates: [
          [21.742147199999998, 38.251253299999995],
          [21.7423219, 38.2513911],
          [21.7429684, 38.251879699999996],
          [21.7427819, 38.2519849],
          [21.7426893, 38.252008599999996],
          [21.742024999999998, 38.2521591],
          [21.7415006, 38.2522857],
          [21.7413617, 38.2518913],
          [21.741239099999998, 38.2515632],
          [21.7411776, 38.2514725],
          [21.7411364, 38.251435],
          [21.7407853, 38.251156099999996],
          [21.7403644, 38.2508287],
          [21.7398891, 38.250473199999995],
          [21.7396452, 38.250274499999996],
          [21.7391328, 38.2498806],
          [21.738879999999998, 38.249676],
          [21.738797299999998, 38.249611099999996],
          [21.7383112, 38.249229799999995],
          [21.7389325, 38.2487609],
          [21.7392519, 38.2485032],
          [21.7395784, 38.24824160000001],
          [21.740067699999997, 38.247862999999995],
          [21.7401795, 38.2477634],
          [21.7405975, 38.2473806],
          [21.7396842, 38.2466795],
          [21.7386743, 38.2459034],
          [21.738340299999997, 38.2456573],
          [21.7381325, 38.2455147],
          [21.7379729, 38.2453857],
          [21.7378605, 38.245294799999996],
          [21.737707999999998, 38.2451933],
          [21.7375011, 38.245049099999996],
          [21.7371755, 38.244796199999996],
          [21.7370488, 38.2447003],
          [21.736708099999998, 38.244459899999995],
          [21.7361011, 38.244039099999995],
        ],
        type: "LineString",
      },
      length: 1514,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-14 -> BT-13",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.736441300000003, 38.2506941],
          [21.7365417, 38.250622199999995],
          [21.7360447, 38.25027660000001],
          [21.7358054, 38.25008210000001],
          [21.735639600000003, 38.249958],
          [21.735520899999997, 38.2498626],
          [21.7349817, 38.2494413],
          [21.7349122, 38.2493935],
          [21.734857299999998, 38.2493558],
          [21.734585199999998, 38.249136899999996],
          [21.7342075, 38.2488428],
          [21.7341299, 38.2487772],
          [21.7338208, 38.2485048],
          [21.7337196, 38.2484184],
          [21.7332312, 38.248038],
          [21.7330746, 38.2479065],
          [21.7329892, 38.2478488],
          [21.7328117, 38.2477267],
          [21.7323271, 38.2473617],
          [21.732171899999997, 38.2472358],
          [21.7318712, 38.2469956],
          [21.7317954, 38.2469526],
          [21.7315643, 38.2468396],
          [21.7313772, 38.2467638],
          [21.7309244, 38.2465749],
          [21.730206799999998, 38.2462124],
          [21.7301304, 38.2461655],
          [21.72993, 38.2460386],
        ],
      },
      length: 787,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-27 -> BT-17",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7247099, 38.2332202],
          [21.7245211, 38.2323028],
          [21.7244463, 38.231927],
          [21.724428, 38.231860499999996],
          [21.724591300000004, 38.2318441],
          [21.7260638, 38.2317676],
          [21.7262265, 38.231758],
          [21.726454999999998, 38.2317475],
          [21.7269202, 38.2317819],
          [21.7271207, 38.2318145],
          [21.7275134, 38.2318919],
          [21.728091499999998, 38.2320035],
          [21.729350099999998, 38.232234500000004],
          [21.7295682, 38.2322738],
          [21.7299808, 38.2323589],
          [21.7300111, 38.2323652],
          [21.730265, 38.2324176],
          [21.730629099999998, 38.2324881],
          [21.731164399999997, 38.232596699999995],
          [21.7313497, 38.2326301],
          [21.732026299999998, 38.232766999999996],
          [21.7324564, 38.2328562],
          [21.7325295, 38.2328724],
          [21.732729799999998, 38.232918999999995],
          [21.733140799999997, 38.2329952],
          [21.7332933, 38.233025999999995],
          [21.7337344, 38.2331159],
          [21.7337842, 38.233123299999995],
          [21.734082, 38.2331249],
          [21.7341299, 38.233117199999995],
          [21.734346, 38.2330591],
          [21.734486399999998, 38.233013500000006],
          [21.735633099999998, 38.2325607],
          [21.7356664, 38.2325478],
          [21.736277899999997, 38.2323494],
          [21.736336299999998, 38.23244890000001],
          [21.7363932, 38.232559200000004],
          [21.7365015, 38.2327709],
          [21.7368566, 38.2334177],
          [21.7369067, 38.233498399999995],
          [21.737298, 38.234057299999996],
          [21.7373187, 38.234155],
          [21.737354699999997, 38.234237],
          [21.737441, 38.2345505],
          [21.737471499999998, 38.2347726],
          [21.737500900000004, 38.2351383],
          [21.7375039, 38.2353434],
          [21.737490899999997, 38.235557],
          [21.7374892, 38.235605299999996],
          [21.737481000000002, 38.2356745],
          [21.7374126, 38.2359522],
          [21.737387599999998, 38.2360322],
          [21.737268999999998, 38.2361861],
          [21.7370752, 38.236441299999996],
          [21.7369317, 38.2370545],
          [21.7373491, 38.2370301],
          [21.7376053, 38.2370425],
          [21.7378322, 38.2370666],
          [21.7380417, 38.237088899999996],
          [21.7386014, 38.237115599999996],
          [21.7392637, 38.2371384],
          [21.7399111, 38.237166599999995],
          [21.740452599999998, 38.2371239],
          [21.7405403, 38.237117],
          [21.7408035, 38.237118099999996],
          [21.741032300000004, 38.2371255],
          [21.7412766, 38.2371335],
          [21.7415621, 38.2371428],
          [21.7421765, 38.2371489],
          [21.7423081, 38.2371866],
          [21.7423655, 38.2374152],
          [21.7427364, 38.237935],
          [21.742762499999998, 38.2379671],
          [21.7431399, 38.2384179],
          [21.7435454, 38.23894730000001],
          [21.7430061, 38.239272299999996],
          [21.742863, 38.239356],
          [21.742639099999998, 38.239487],
          [21.742462099999997, 38.2395893],
          [21.7422999, 38.2396695],
          [21.7421942, 38.239712399999995],
          [21.742114900000004, 38.239744099999996],
          [21.7419109, 38.2398258],
          [21.7419911, 38.2399578],
        ],
      },
      length: 2667,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-28 -> BT-19",
      geometry: {
        coordinates: [
          [21.7308639, 38.2456878],
          [21.730916399999998, 38.2456508],
          [21.7310756, 38.245530699999996],
          [21.731237399999998, 38.2453973],
          [21.731563599999998, 38.245076999999995],
          [21.731853599999997, 38.2447988],
          [21.7321779, 38.2445066],
        ],
        type: "LineString",
      },
      length: 175,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-14 -> BT-11",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.736441300000003, 38.2506941],
          [21.7365417, 38.250622199999995],
          [21.7368757, 38.250356599999996],
          [21.737490599999997, 38.250830199999996],
          [21.7375158, 38.2508534],
          [21.7378695, 38.2516314],
          [21.738013799999997, 38.2521213],
          [21.738184999999998, 38.252789799999995],
          [21.738225699999997, 38.2529668],
          [21.7382613, 38.2530684],
        ],
      },
      length: 387,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-3 -> BT-15",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7360641, 38.246508600000006],
          [21.736217099999998, 38.246614699999995],
          [21.736860699999998, 38.247126699999995],
          [21.736996599999998, 38.2472348],
          [21.7376301, 38.2467404],
          [21.7382421, 38.2462528],
          [21.7386743, 38.2459034],
          [21.738965, 38.2456829],
          [21.7391255, 38.2455681],
        ],
      },
      length: 378,
    },
    currentTime: 145,
    historicTime: 935,
    level: 0,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-28 -> BT-12",
      geometry: {
        coordinates: [
          [21.730982500000003, 38.2457034],
          [21.7314029, 38.2460482],
          [21.731462399999998, 38.2460933],
          [21.7317884, 38.246345999999996],
          [21.7321472, 38.2466332],
          [21.732519999999997, 38.246926599999995],
          [21.732995499999998, 38.2473102],
          [21.7333984, 38.2476656],
          [21.733493499999998, 38.247582],
        ],
        type: "LineString",
      },
      length: 316,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-4 -> BT-19",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7289083, 38.2408303],
          [21.7287659, 38.240846],
          [21.7288794, 38.2409961],
          [21.729508700000004, 38.2414431],
          [21.729623999999998, 38.2415373],
          [21.729941399999998, 38.2417966],
          [21.7303258, 38.242087399999996],
          [21.7305989, 38.2422861],
          [21.730739399999997, 38.2423808],
          [21.7310089, 38.242591499999996],
          [21.731162599999998, 38.242712999999995],
          [21.7316443, 38.243093099999996],
          [21.7320877, 38.2434302],
          [21.7324387, 38.243713],
          [21.732485, 38.243749],
          [21.7328102, 38.2440023],
          [21.732214799999998, 38.244473400000004],
          [21.7321779, 38.2445066],
        ],
      },
      length: 591,
    },
    currentTime: 2331,
    historicTime: 433,
    level: 4,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-23 -> BT-24",
      geometry: {
        coordinates: [
          [21.7395233, 38.2658177],
          [21.7400909, 38.2656941],
          [21.7403656, 38.265639199999995],
          [21.741117, 38.2654705],
          [21.741135099999997, 38.2653485],
          [21.7410475, 38.265187999999995],
          [21.7410408, 38.265157599999995],
          [21.7409262, 38.264710799999996],
          [21.7400661, 38.2648651],
          [21.7396203, 38.2649478],
          [21.7393569, 38.2649842],
          [21.7393577, 38.2646922],
          [21.7393625, 38.2644647],
          [21.7393641, 38.26433],
          [21.7393665, 38.2640844],
          [21.7393605, 38.26361920000001],
          [21.7393513, 38.2635259],
          [21.7393203, 38.263246599999995],
          [21.7392887, 38.263068499999996],
          [21.739246599999998, 38.262831299999995],
          [21.7390166, 38.2614848],
          [21.7388462, 38.2604951],
          [21.7387108, 38.259725499999995],
          [21.7386391, 38.2593702],
          [21.7386028, 38.2591903],
          [21.738580499999998, 38.2590594],
          [21.738519099999998, 38.2586815],
          [21.738491, 38.2584984],
          [21.7382079, 38.2569182],
          [21.7381171, 38.256600899999995],
          [21.7377852, 38.255369300000005],
          [21.737641, 38.255027999999996],
          [21.737220200000003, 38.2541918],
          [21.7371634, 38.254063099999996],
          [21.737115, 38.2539089],
          [21.7370348, 38.2535399],
          [21.7370177, 38.2534555],
          [21.736944299999998, 38.2531818],
          [21.736756699999997, 38.2525434],
        ],
        type: "LineString",
      },
      length: 1779,
    },
    currentTime: 157,
    historicTime: 140,
    level: 0,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-7 -> BT-19",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.735111, 38.2448438],
          [21.7347164, 38.2445218],
          [21.7343396, 38.2442171],
          [21.7342776, 38.2441749],
          [21.7339314, 38.243939399999995],
          [21.7338581, 38.2438791],
          [21.7334075, 38.2435101],
          [21.7328102, 38.2440023],
          [21.732214799999998, 38.244473400000004],
          [21.7321779, 38.2445066],
        ],
      },
      length: 364,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-23 -> BT-20",
      geometry: {
        coordinates: [
          [21.7395233, 38.2658177],
          [21.7400909, 38.2656941],
          [21.740218, 38.266145],
          [21.7402885, 38.2664052],
          [21.7403473, 38.266579],
          [21.740418899999998, 38.2667906],
          [21.740534699999998, 38.2671504],
          [21.7411153, 38.2670327],
          [21.741563499999998, 38.266942199999995],
          [21.7423465, 38.2667711],
          [21.742634, 38.266711099999995],
          [21.743146, 38.2666004],
          [21.743643, 38.266504],
          [21.7438654, 38.2664608],
          [21.744087999999998, 38.2671201],
          [21.7441917, 38.2674725],
          [21.7442761, 38.267778799999995],
          [21.7442907, 38.2678334],
          [21.744330299999998, 38.267946699999996],
          [21.744390600000003, 38.2681202],
          [21.744456200000002, 38.2682925],
          [21.744579899999998, 38.268568699999996],
          [21.7448326, 38.2690704],
          [21.7448474, 38.269097699999996],
          [21.745130099999997, 38.2694955],
          [21.7454258, 38.2698335],
          [21.7457979, 38.270183599999996],
          [21.745891099999998, 38.2702628],
          [21.7462222, 38.270511400000004],
          [21.7465674, 38.270747899999996],
          [21.746614299999997, 38.270776999999995],
          [21.746942999999998, 38.270972199999996],
          [21.7471546, 38.2710973],
          [21.747239, 38.271138799999996],
          [21.747696599999998, 38.271382599999995],
          [21.747886599999998, 38.2714962],
          [21.7480317, 38.2715727],
          [21.748442400000002, 38.271788099999995],
          [21.7486103, 38.2718837],
          [21.7490366, 38.272098],
          [21.7500252, 38.272596],
          [21.750217799999998, 38.2727009],
          [21.7502817, 38.2727295],
          [21.7508172, 38.272964099999996],
          [21.7508575, 38.2729833],
          [21.751603799999998, 38.2733335],
          [21.7519933, 38.273578199999996],
          [21.7522192, 38.2737294],
          [21.752636199999998, 38.274029299999995],
          [21.752919799999997, 38.2742393],
          [21.7535269, 38.274689699999996],
          [21.7539583, 38.2750005],
          [21.754593800000002, 38.2754583],
          [21.7547632, 38.2755681],
          [21.7548289, 38.2755067],
          [21.7549119, 38.275438799999996],
          [21.7551499, 38.275235699999996],
          [21.755403899999997, 38.2750146],
          [21.7557701, 38.2747156],
          [21.7559289, 38.274586],
          [21.756036299999998, 38.2744991],
          [21.756218699999998, 38.2743519],
          [21.756358199999998, 38.2742431],
          [21.7565127, 38.2741246],
          [21.7574188, 38.273422499999995],
          [21.7575843, 38.2732981],
          [21.757908399999998, 38.2730611],
          [21.7581353, 38.272896599999996],
          [21.7583644, 38.2727536],
        ],
        type: "LineString",
      },
      length: 2405,
    },
    currentTime: 313,
    historicTime: 236,
    level: 1,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-25 -> BT-24",
      geometry: {
        coordinates: [
          [21.7352809, 38.249088300000004],
          [21.735356, 38.249147],
          [21.735476199999997, 38.2492393],
          [21.7360143, 38.249667699999996],
          [21.7364811, 38.2500277],
          [21.7367186, 38.2502257],
          [21.7368757, 38.250356599999996],
          [21.737490599999997, 38.250830199999996],
          [21.7375158, 38.2508534],
          [21.7378695, 38.2516314],
          [21.738013799999997, 38.2521213],
          [21.738184999999998, 38.252789799999995],
          [21.738225699999997, 38.2529668],
          [21.7382613, 38.2530684],
          [21.738262799999998, 38.253072599999996],
          [21.7383235, 38.253246],
          [21.7383916, 38.2535121],
          [21.738435799999998, 38.2536765],
          [21.738059999999997, 38.2537888],
          [21.737864, 38.2538349],
          [21.7376525, 38.2539039],
          [21.7372712, 38.2540325],
          [21.7372291, 38.2540445],
          [21.7371634, 38.254063099999996],
          [21.737115, 38.2539089],
          [21.7370348, 38.2535399],
          [21.7370177, 38.2534555],
          [21.736944299999998, 38.2531818],
          [21.736756699999997, 38.2525434],
        ],
        type: "LineString",
      },
      length: 894,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-7 -> BT-9",
      geometry: {
        coordinates: [
          [21.735111, 38.2448438],
          [21.7347164, 38.2445218],
          [21.7343396, 38.2442171],
          [21.7342776, 38.2441749],
          [21.7339314, 38.243939399999995],
          [21.734525599999998, 38.243439699999996],
          [21.734912599999998, 38.2437007],
          [21.735106299999998, 38.243849],
          [21.7353077, 38.2440036],
          [21.7354691, 38.2441473],
          [21.7357189, 38.2443704],
          [21.735882, 38.2444967],
          [21.7366043, 38.245056],
          [21.737102999999998, 38.2454271],
          [21.7374167, 38.2456546],
          [21.7382421, 38.2462528],
          [21.7386743, 38.2459034],
          [21.7381325, 38.2455147],
          [21.7379729, 38.2453857],
          [21.7378605, 38.245294799999996],
          [21.737707999999998, 38.2451933],
          [21.7375011, 38.245049099999996],
          [21.7371755, 38.244796199999996],
          [21.7370488, 38.2447003],
          [21.736708099999998, 38.244459899999995],
          [21.7361011, 38.244039099999995],
        ],
        type: "LineString",
      },
      length: 1031,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-4 -> BT-18",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7289083, 38.2408303],
          [21.729351599999998, 38.2407778],
          [21.730045399999998, 38.2406988],
          [21.7311004, 38.2405833],
          [21.7311647, 38.2408649],
          [21.731520399999997, 38.2411342],
          [21.732035999999997, 38.241516499999996],
          [21.7321779, 38.241614899999995],
          [21.7323614, 38.2417508],
          [21.7325211, 38.2418797],
          [21.7328291, 38.2421268],
          [21.7332951, 38.2424471],
          [21.733457899999998, 38.2425736],
          [21.7336721, 38.2427401],
          [21.7339886, 38.243029899999996],
          [21.7342619, 38.2427966],
          [21.7343761, 38.24273900000001],
        ],
      },
      length: 617,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-14 -> BT-9",
      geometry: {
        coordinates: [
          [21.736441300000003, 38.2506941],
          [21.7365417, 38.250622199999995],
          [21.7368757, 38.250356599999996],
          [21.7376015, 38.249794],
          [21.7379684, 38.2495091],
          [21.7383112, 38.249229799999995],
          [21.7389325, 38.2487609],
          [21.7392519, 38.2485032],
          [21.7395784, 38.24824160000001],
          [21.740067699999997, 38.247862999999995],
          [21.7401795, 38.2477634],
          [21.7405975, 38.2473806],
          [21.7396842, 38.2466795],
          [21.7386743, 38.2459034],
          [21.738340299999997, 38.2456573],
          [21.7381325, 38.2455147],
          [21.7379729, 38.2453857],
          [21.7378605, 38.245294799999996],
          [21.737707999999998, 38.2451933],
          [21.7375011, 38.245049099999996],
          [21.7371755, 38.244796199999996],
          [21.7370488, 38.2447003],
          [21.736708099999998, 38.244459899999995],
          [21.7361011, 38.244039099999995],
        ],
        type: "LineString",
      },
      length: 1058,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-16 -> BT-17",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7452693, 38.240012899999996],
          [21.7452312, 38.239902199999996],
          [21.745200399999998, 38.239745299999996],
          [21.7451677, 38.2395673],
          [21.7451257, 38.2394351],
          [21.7450927, 38.2393084],
          [21.7450922, 38.239303],
          [21.7450759, 38.2391236],
          [21.745061699999997, 38.2386803],
          [21.7450558, 38.2385004],
          [21.744650099999998, 38.2383562],
          [21.7445147, 38.2384364],
          [21.7436173, 38.2389177],
          [21.7435454, 38.23894730000001],
          [21.7430061, 38.239272299999996],
          [21.742863, 38.239356],
          [21.742639099999998, 38.239487],
          [21.742462099999997, 38.2395893],
          [21.7422999, 38.2396695],
          [21.7421942, 38.239712399999995],
          [21.742114900000004, 38.239744099999996],
          [21.7419109, 38.2398258],
          [21.7419911, 38.2399578],
        ],
      },
      length: 515,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-25 -> BT-11",
      geometry: {
        coordinates: [
          [21.7352809, 38.249088300000004],
          [21.735356, 38.249147],
          [21.735476199999997, 38.2492393],
          [21.7360143, 38.249667699999996],
          [21.7364811, 38.2500277],
          [21.7367186, 38.2502257],
          [21.7368757, 38.250356599999996],
          [21.737490599999997, 38.250830199999996],
          [21.7375158, 38.2508534],
          [21.7378695, 38.2516314],
          [21.738013799999997, 38.2521213],
          [21.738184999999998, 38.252789799999995],
          [21.738225699999997, 38.2529668],
          [21.7382613, 38.2530684],
        ],
        type: "LineString",
      },
      length: 532,
    },
    currentTime: 105,
    historicTime: 88,
    level: 0,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-19 -> BT-18",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7321779, 38.2445066],
          [21.732214799999998, 38.244473400000004],
          [21.7328102, 38.2440023],
          [21.7334075, 38.2435101],
          [21.7339886, 38.243029899999996],
          [21.7342619, 38.2427966],
          [21.7343761, 38.24273900000001],
        ],
      },
      length: 275,
    },
    currentTime: 1422,
    historicTime: 71,
    level: 4,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-11 -> BT-24",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7382705, 38.2530947],
          [21.7383235, 38.253246],
          [21.7383916, 38.2535121],
          [21.738435799999998, 38.2536765],
          [21.738059999999997, 38.2537888],
          [21.737864, 38.2538349],
          [21.7376525, 38.2539039],
          [21.7372712, 38.2540325],
          [21.7372291, 38.2540445],
          [21.7371634, 38.254063099999996],
          [21.737115, 38.2539089],
          [21.7370348, 38.2535399],
          [21.7370177, 38.2534555],
          [21.736944299999998, 38.2531818],
          [21.736756699999997, 38.2525434],
        ],
      },
      length: 358,
    },
    currentTime: 2122,
    historicTime: 3429,
    level: 0,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-9 -> BT-18",
      geometry: {
        coordinates: [
          [21.7361011, 38.244039099999995],
          [21.7356204, 38.243727199999995],
          [21.7354454, 38.2436079],
          [21.7352806, 38.2434922],
          [21.7352253, 38.243455399999995],
          [21.7348121, 38.2431774],
          [21.7346584, 38.2430695],
          [21.7343664, 38.2428686],
        ],
        type: "LineString",
      },
      length: 200,
    },
    currentTime: 916,
    historicTime: 430,
    level: 4,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-11 -> BT-23",
      geometry: {
        coordinates: [
          [21.7382705, 38.2530947],
          [21.7383235, 38.253246],
          [21.7383916, 38.2535121],
          [21.738435799999998, 38.2536765],
          [21.738059999999997, 38.2537888],
          [21.737864, 38.2538349],
          [21.7376525, 38.2539039],
          [21.7372712, 38.2540325],
          [21.7373235, 38.2541468],
          [21.7376551, 38.2548566],
          [21.7379045, 38.255359],
          [21.7381323, 38.256077999999995],
          [21.738230599999998, 38.2565904],
          [21.7382079, 38.2569182],
          [21.738491, 38.2584984],
          [21.738519099999998, 38.2586815],
          [21.738580499999998, 38.2590594],
          [21.7386028, 38.2591903],
          [21.738675, 38.2595479],
          [21.7387108, 38.259725499999995],
          [21.7388462, 38.2604951],
          [21.7390166, 38.2614848],
          [21.739246599999998, 38.262831299999995],
          [21.7393203, 38.263246599999995],
          [21.739331399999998, 38.2633467],
          [21.7393513, 38.2635259],
          [21.7393605, 38.26361920000001],
          [21.7393675, 38.263904499999995],
          [21.7393665, 38.2640844],
          [21.7393641, 38.26433],
          [21.7393625, 38.2644647],
          [21.7393577, 38.2646922],
          [21.7393569, 38.2649842],
          [21.7393442, 38.265577],
          [21.7393438, 38.2658568],
          [21.7395233, 38.2658177],
        ],
        type: "LineString",
      },
      length: 1526,
    },
    currentTime: 154,
    historicTime: 3308,
    level: 0,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-7 -> BT-18",
      geometry: {
        coordinates: [
          [21.735111, 38.2448438],
          [21.7347164, 38.2445218],
          [21.7343396, 38.2442171],
          [21.7342776, 38.2441749],
          [21.7339314, 38.243939399999995],
          [21.734525599999998, 38.243439699999996],
          [21.7346948, 38.243285],
          [21.7348121, 38.2431774],
          [21.7343664, 38.2428686],
        ],
        type: "LineString",
      },
      length: 310,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-10 -> BT-19",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.731241999999998, 38.2405712],
          [21.7311004, 38.2405833],
          [21.7311647, 38.2408649],
          [21.731520399999997, 38.2411342],
          [21.732035999999997, 38.241516499999996],
          [21.7321779, 38.241614899999995],
          [21.7323614, 38.2417508],
          [21.7325211, 38.2418797],
          [21.7328291, 38.2421268],
          [21.7332951, 38.2424471],
          [21.733457899999998, 38.2425736],
          [21.7336721, 38.2427401],
          [21.7339886, 38.243029899999996],
          [21.7334075, 38.2435101],
          [21.7328102, 38.2440023],
          [21.7323761, 38.2443457],
          [21.732214799999998, 38.244473400000004],
          [21.7321779, 38.2445066],
        ],
      },
      length: 617,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-7 -> BT-8",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.735111, 38.2448438],
          [21.7347164, 38.2445218],
          [21.7343396, 38.2442171],
          [21.733729699999998, 38.2447221],
          [21.733147400000004, 38.2452237],
          [21.732717599999997, 38.2448629],
          [21.7323915, 38.2446103],
          [21.732214799999998, 38.244473400000004],
          [21.7318499, 38.244190599999996],
          [21.731458999999997, 38.2439],
          [21.7310444, 38.2435754],
          [21.7305299, 38.243185499999996],
          [21.7300029, 38.2427972],
          [21.729989699999997, 38.2427877],
          [21.7298835, 38.242688],
          [21.7293104, 38.242283],
          [21.7288692, 38.241908599999995],
          [21.728704399999998, 38.2417837],
          [21.7279895, 38.2412369],
          [21.727890199999997, 38.24113620000001],
          [21.7278715, 38.2411051],
          [21.7278428, 38.2409505],
          [21.7287659, 38.240846],
          [21.729351599999998, 38.2407778],
          [21.730045399999998, 38.2406988],
          [21.730145, 38.240688],
        ],
      },
      length: 1121,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-12 -> BT-22",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.733493499999998, 38.247582],
          [21.7333984, 38.2476656],
          [21.7341703, 38.248236999999996],
          [21.7345966, 38.2485572],
          [21.7349697, 38.2488465],
          [21.735193199999998, 38.2490196],
          [21.735356, 38.249147],
          [21.735476199999997, 38.2492393],
          [21.7360143, 38.249667699999996],
          [21.736320499999998, 38.2498994],
          [21.7364811, 38.2500277],
          [21.7367186, 38.2502257],
          [21.7368757, 38.250356599999996],
          [21.737490599999997, 38.250830199999996],
          [21.7375158, 38.2508534],
          [21.737797399999998, 38.2514608],
          [21.7378695, 38.2516314],
          [21.738013799999997, 38.2521213],
          [21.7386609, 38.252003099999996],
          [21.739131699999998, 38.2519221],
          [21.7395463, 38.251845599999996],
          [21.7401724, 38.2517372],
          [21.7402831, 38.2517178],
          [21.7403862, 38.2516989],
          [21.7405851, 38.2516674],
          [21.7407024, 38.251641],
          [21.7409276, 38.251608399999995],
          [21.741239099999998, 38.2515632],
          [21.7413235, 38.2515492],
        ],
      },
      length: 961,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-15 -> BT-11",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7391255, 38.2455681],
          [21.738965, 38.2456829],
          [21.7386743, 38.2459034],
          [21.7383986, 38.2461215],
          [21.7382421, 38.2462528],
          [21.7376301, 38.2467404],
          [21.737160199999998, 38.247109],
          [21.736996599999998, 38.2472348],
          [21.7380195, 38.2480604],
          [21.737419799999998, 38.2485557],
          [21.7370737, 38.248822],
          [21.7370412, 38.2488458],
          [21.7367208, 38.2490935],
          [21.7364025, 38.2493522],
          [21.7362434, 38.2494815],
          [21.7360143, 38.249667699999996],
          [21.7364811, 38.2500277],
          [21.7367186, 38.2502257],
          [21.7368757, 38.250356599999996],
          [21.737490599999997, 38.250830199999996],
          [21.7375158, 38.2508534],
          [21.7378352, 38.2515417],
          [21.7378695, 38.2516314],
          [21.738013799999997, 38.2521213],
          [21.738184999999998, 38.252789799999995],
          [21.738225699999997, 38.2529668],
          [21.7382613, 38.2530684],
        ],
      },
      length: 1082,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-19 -> BT-4",
      geometry: {
        coordinates: [
          [21.7321827, 38.2444485],
          [21.7318499, 38.244190599999996],
          [21.731458999999997, 38.2439],
          [21.7310444, 38.2435754],
          [21.7305299, 38.243185499999996],
          [21.729989699999997, 38.2427877],
          [21.7298835, 38.242688],
          [21.7293104, 38.242283],
          [21.728999299999998, 38.242019],
          [21.7288692, 38.241908599999995],
          [21.728704399999998, 38.2417837],
          [21.7279895, 38.2412369],
          [21.727890199999997, 38.24113620000001],
          [21.7278715, 38.2411051],
          [21.7278428, 38.2409505],
          [21.7287659, 38.240846],
          [21.7289083, 38.2408303],
        ],
        type: "LineString",
      },
      length: 641,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-26 -> BT-4",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7261111, 38.2408834],
          [21.7261522, 38.2410662],
          [21.726176, 38.241121899999996],
          [21.7263385, 38.2411059],
          [21.726461999999998, 38.2410922],
          [21.727202, 38.2410082],
          [21.727252699999998, 38.2410035],
          [21.7273445, 38.2409965],
          [21.727571899999997, 38.2409755],
          [21.7278428, 38.2409505],
          [21.7287659, 38.240846],
          [21.7289083, 38.2408303],
        ],
      },
      length: 268,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-18 -> BT-17",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7343761, 38.24273900000001],
          [21.734665800000002, 38.2426137],
          [21.7347258, 38.242595699999995],
          [21.7352202, 38.2423851],
          [21.7354272, 38.242308200000004],
          [21.73611, 38.2420399],
          [21.7368421, 38.2417502],
          [21.7370658, 38.2416653],
          [21.7375149, 38.2415178],
          [21.738158, 38.2412559],
          [21.738367099999998, 38.2411826],
          [21.7386682, 38.241077],
          [21.738968099999997, 38.240959499999995],
          [21.7392507, 38.240846],
          [21.7396979, 38.2406611],
          [21.739907100000003, 38.2405879],
          [21.740441999999998, 38.2403852],
          [21.7411806, 38.2400928],
          [21.741594799999998, 38.239939299999996],
          [21.7419109, 38.2398258],
          [21.7419911, 38.2399578],
        ],
      },
      length: 750,
    },
    currentTime: 131,
    historicTime: 110,
    level: 0,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-19 -> BT-9",
      geometry: {
        coordinates: [
          [21.7321779, 38.2445066],
          [21.732214799999998, 38.244473400000004],
          [21.7328102, 38.2440023],
          [21.7334075, 38.2435101],
          [21.7339886, 38.243029899999996],
          [21.7342619, 38.2427966],
          [21.734665800000002, 38.2426137],
          [21.7347258, 38.242595699999995],
          [21.7352202, 38.2423851],
          [21.7354272, 38.242308200000004],
          [21.7356342, 38.2422313],
          [21.73611, 38.2420399],
          [21.7366469, 38.2428798],
          [21.7367389, 38.243044499999996],
          [21.7369533, 38.2434342],
          [21.7376489, 38.243123],
          [21.7376634, 38.2433021],
          [21.7377324, 38.2437064],
          [21.737711599999997, 38.2438056],
          [21.7379669, 38.2438907],
          [21.7382734, 38.24408100000001],
          [21.7384058, 38.2441848],
          [21.7384774, 38.244234999999996],
          [21.738690899999998, 38.244464],
          [21.7387685, 38.244563],
          [21.738260800000003, 38.2449606],
          [21.738043899999997, 38.2451359],
          [21.7380143, 38.2451615],
          [21.7378605, 38.245294799999996],
          [21.737707999999998, 38.2451933],
          [21.7375011, 38.245049099999996],
          [21.7370488, 38.2447003],
          [21.7368779, 38.244580500000005],
          [21.7361011, 38.244039099999995],
        ],
        type: "LineString",
      },
      length: 1214,
    },
    currentTime: 2440,
    historicTime: 662,
    level: 4,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-22 -> BT-9",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7412088, 38.251512],
          [21.7411776, 38.2514725],
          [21.7411073, 38.2514119],
          [21.7407853, 38.251156099999996],
          [21.7403644, 38.2508287],
          [21.7398891, 38.250473199999995],
          [21.7396452, 38.250274499999996],
          [21.7391328, 38.2498806],
          [21.738879999999998, 38.249676],
          [21.738797299999998, 38.249611099999996],
          [21.7383112, 38.249229799999995],
          [21.7389325, 38.2487609],
          [21.7392519, 38.2485032],
          [21.7395784, 38.24824160000001],
          [21.740067699999997, 38.247862999999995],
          [21.7401795, 38.2477634],
          [21.7405975, 38.2473806],
          [21.7396842, 38.2466795],
          [21.7386743, 38.2459034],
          [21.738340299999997, 38.2456573],
          [21.7381325, 38.2455147],
          [21.7379729, 38.2453857],
          [21.7378605, 38.245294799999996],
          [21.737707999999998, 38.2451933],
          [21.7375011, 38.245049099999996],
          [21.7371755, 38.244796199999996],
          [21.7370488, 38.2447003],
          [21.736708099999998, 38.244459899999995],
          [21.7361011, 38.244039099999995],
        ],
      },
      length: 1187,
    },
    currentTime: 661,
    historicTime: 3986,
    level: 0,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-8 -> BT-4",
      geometry: {
        coordinates: [
          [21.730145, 38.240688],
          [21.730045399999998, 38.2406988],
          [21.729351599999998, 38.2407778],
          [21.7289083, 38.2408303],
        ],
        type: "LineString",
      },
      length: 109,
    },
    currentTime: 23,
    historicTime: 43,
    level: 0,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-9 -> BT-15",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7361011, 38.244039099999995],
          [21.7357189, 38.2443704],
          [21.7366043, 38.245056],
          [21.737102999999998, 38.2454271],
          [21.7372712, 38.2455491],
          [21.7374167, 38.2456546],
          [21.7382421, 38.2462528],
          [21.7386743, 38.2459034],
          [21.738965, 38.2456829],
          [21.7391255, 38.2455681],
        ],
      },
      length: 462,
    },
    currentTime: 670,
    historicTime: 382,
    level: 3,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-10 -> BT-18",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.731241999999998, 38.2405712],
          [21.7311004, 38.2405833],
          [21.7311647, 38.2408649],
          [21.731520399999997, 38.2411342],
          [21.732035999999997, 38.241516499999996],
          [21.7321779, 38.241614899999995],
          [21.7323614, 38.2417508],
          [21.7325211, 38.2418797],
          [21.7328291, 38.2421268],
          [21.7332951, 38.2424471],
          [21.733457899999998, 38.2425736],
          [21.7336721, 38.2427401],
          [21.7339886, 38.243029899999996],
          [21.7342619, 38.2427966],
          [21.7343761, 38.24273900000001],
        ],
      },
      length: 436,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-25 -> BT-22",
      geometry: {
        coordinates: [
          [21.7352809, 38.249088300000004],
          [21.735356, 38.249147],
          [21.735476199999997, 38.2492393],
          [21.7360143, 38.249667699999996],
          [21.7364811, 38.2500277],
          [21.7367186, 38.2502257],
          [21.7368757, 38.250356599999996],
          [21.737490599999997, 38.250830199999996],
          [21.7375158, 38.2508534],
          [21.7378695, 38.2516314],
          [21.738013799999997, 38.2521213],
          [21.7386609, 38.252003099999996],
          [21.739131699999998, 38.2519221],
          [21.7395463, 38.251845599999996],
          [21.7401724, 38.2517372],
          [21.7402831, 38.2517178],
          [21.7403862, 38.2516989],
          [21.7405851, 38.2516674],
          [21.7407024, 38.251641],
          [21.7409276, 38.251608399999995],
          [21.741239099999998, 38.2515632],
          [21.7413235, 38.2515492],
        ],
        type: "LineString",
      },
      length: 721,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-10 -> BT-8",
      geometry: {
        coordinates: [
          [21.731241999999998, 38.2405712],
          [21.7311004, 38.2405833],
          [21.730145, 38.240688],
        ],
        type: "LineString",
      },
      length: 97,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-7 -> BT-3",
      geometry: {
        coordinates: [
          [21.735111, 38.2448438],
          [21.7345115, 38.2453283],
          [21.734791899999998, 38.2455408],
          [21.7351015, 38.2457749],
          [21.7351673, 38.2457783],
          [21.735252199999998, 38.245745299999996],
          [21.7353558, 38.245702699999995],
          [21.7354836, 38.2457194],
          [21.7356239, 38.245805],
          [21.735663199999998, 38.2458306],
          [21.7357065, 38.2458761],
          [21.7358145, 38.245998],
          [21.7358096, 38.2460516],
          [21.735726600000003, 38.246122],
          [21.7357115, 38.2461424],
          [21.735703299999997, 38.2461651],
          [21.735713699999998, 38.2462455],
          [21.735793, 38.246320499999996],
          [21.7360641, 38.246508600000006],
        ],
        type: "LineString",
      },
      length: 299,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-5 -> BT-20",
      geometry: {
        coordinates: [
          [21.747677499999998, 38.2552474],
          [21.747573799999998, 38.2552972],
          [21.747465599999998, 38.255462099999995],
          [21.7474505, 38.255522400000004],
          [21.7474418, 38.255590399999996],
          [21.747469799999998, 38.255780400000006],
          [21.7475377, 38.255964],
          [21.747649799999998, 38.256266],
          [21.7477867, 38.2565071],
          [21.747871999999997, 38.256639299999996],
          [21.7480864, 38.2571516],
          [21.748109, 38.2572419],
          [21.748123099999997, 38.2574214],
          [21.7481317, 38.257673499999996],
          [21.7481159, 38.2581432],
          [21.7481168, 38.258223199999996],
          [21.7481238, 38.258397699999996],
          [21.7482124, 38.2588179],
          [21.7482498, 38.258927199999995],
          [21.7483224, 38.259071299999995],
          [21.748452399999998, 38.259253099999995],
          [21.7486402, 38.259522499999996],
          [21.7486855, 38.2595846],
          [21.7487872, 38.2597151],
          [21.748832099999998, 38.2597786],
          [21.7489416, 38.2599337],
          [21.7489913, 38.259995499999995],
          [21.749051299999998, 38.2600614],
          [21.749289599999997, 38.260394500000004],
          [21.7493651, 38.2605],
          [21.750087500000003, 38.2614376],
          [21.750117799999998, 38.261482199999996],
          [21.7502601, 38.2616623],
          [21.750638499999997, 38.262154699999996],
          [21.751532299999997, 38.263314],
          [21.751770999999998, 38.263636399999996],
          [21.7518843, 38.263792699999996],
          [21.752145100000003, 38.2641401],
          [21.7525959, 38.2647468],
          [21.7529342, 38.2651924],
          [21.753748899999998, 38.2663047],
          [21.753856, 38.2664396],
          [21.7548924, 38.2678309],
          [21.7551033, 38.2681031],
          [21.7556929, 38.2688741],
          [21.756041, 38.269307],
          [21.7561066, 38.2693886],
          [21.7562523, 38.26957],
          [21.7564714, 38.2698459],
          [21.757099999999998, 38.270674],
          [21.7572279, 38.2708582],
          [21.757279999999998, 38.270933],
          [21.7577224, 38.2715739],
          [21.758457699999997, 38.272438300000005],
          [21.7586029, 38.2726082],
          [21.7584791, 38.2726821],
          [21.7583644, 38.2727536],
        ],
        type: "LineString",
      },
      length: 2224,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-18 -> BT-9",
      geometry: {
        coordinates: [
          [21.7343761, 38.24273900000001],
          [21.734665800000002, 38.2426137],
          [21.7347258, 38.242595699999995],
          [21.7352202, 38.2423851],
          [21.7354272, 38.242308200000004],
          [21.73611, 38.2420399],
          [21.7366469, 38.2428798],
          [21.7367389, 38.243044499999996],
          [21.7369533, 38.2434342],
          [21.7376489, 38.243123],
          [21.7376634, 38.2433021],
          [21.7377324, 38.2437064],
          [21.737711599999997, 38.2438056],
          [21.7379669, 38.2438907],
          [21.7382734, 38.24408100000001],
          [21.7384058, 38.2441848],
          [21.7384774, 38.244234999999996],
          [21.738690899999998, 38.244464],
          [21.7387685, 38.244563],
          [21.738260800000003, 38.2449606],
          [21.738043899999997, 38.2451359],
          [21.7380143, 38.2451615],
          [21.7378605, 38.245294799999996],
          [21.737707999999998, 38.2451933],
          [21.7375011, 38.245049099999996],
          [21.7370488, 38.2447003],
          [21.7368779, 38.244580500000005],
          [21.7361011, 38.244039099999995],
        ],
        type: "LineString",
      },
      length: 939,
    },
    currentTime: 1941,
    historicTime: 2508,
    level: 0,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-21 -> BT-23",
      geometry: {
        coordinates: [
          [21.7432327, 38.2565272],
          [21.7432412, 38.2564542],
          [21.7433124, 38.256399699999996],
          [21.7434323, 38.256388099999995],
          [21.743509799999998, 38.2564397],
          [21.7435355, 38.2565148],
          [21.743503500000003, 38.2565867],
          [21.743418899999998, 38.2566302],
          [21.7433581, 38.2566309],
          [21.743216999999998, 38.256667],
          [21.7431183, 38.2566895],
          [21.7429588, 38.2567265],
          [21.742855499999997, 38.2567605],
          [21.742433, 38.256899499999996],
          [21.7422601, 38.2569028],
          [21.74146, 38.2571088],
          [21.7404923, 38.257347599999996],
          [21.740371, 38.257383399999995],
          [21.7400987, 38.257463699999995],
          [21.739942199999998, 38.257509899999995],
          [21.739867699999998, 38.2575261],
          [21.7394324, 38.2576374],
          [21.7396587, 38.2582279],
          [21.739714, 38.2583726],
          [21.7398632, 38.2587727],
          [21.7399193, 38.259055599999996],
          [21.7400271, 38.2592135],
          [21.7401356, 38.2593719],
          [21.740037700000002, 38.2594011],
          [21.7399516, 38.259427599999995],
          [21.7394092, 38.259575999999996],
          [21.7387108, 38.259725499999995],
          [21.7388462, 38.2604951],
          [21.7390166, 38.2614848],
          [21.739246599999998, 38.262831299999995],
          [21.7393203, 38.263246599999995],
          [21.739331399999998, 38.2633467],
          [21.7393513, 38.2635259],
          [21.7393605, 38.26361920000001],
          [21.7393675, 38.263904499999995],
          [21.7393665, 38.2640844],
          [21.7393641, 38.26433],
          [21.7393625, 38.2644647],
          [21.7393577, 38.2646922],
          [21.7393569, 38.2649842],
          [21.7393442, 38.265577],
          [21.7393438, 38.2658568],
          [21.7395233, 38.2658177],
        ],
        type: "LineString",
      },
      length: 1466,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-12 -> BT-25",
      geometry: {
        coordinates: [
          [21.733493499999998, 38.247582],
          [21.7333984, 38.2476656],
          [21.7341703, 38.248236999999996],
          [21.7345966, 38.2485572],
          [21.7349697, 38.2488465],
          [21.735193199999998, 38.2490196],
          [21.7352809, 38.249088300000004],
        ],
        type: "LineString",
      },
      length: 240,
    },
    currentTime: 88,
    historicTime: 113,
    level: 0,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-20 -> BT-23",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7583644, 38.2727536],
          [21.7581353, 38.272896599999996],
          [21.757908399999998, 38.2730611],
          [21.7575843, 38.2732981],
          [21.756765299999998, 38.2739268],
          [21.7565127, 38.2741246],
          [21.756358199999998, 38.2742431],
          [21.756218699999998, 38.2743519],
          [21.7559289, 38.274586],
          [21.755403899999997, 38.2750146],
          [21.7551499, 38.275235699999996],
          [21.7549119, 38.275438799999996],
          [21.7548289, 38.2755067],
          [21.7547632, 38.2755681],
          [21.754466999999998, 38.275813],
          [21.754184799999997, 38.2760587],
          [21.753281599999998, 38.2768294],
          [21.7531327, 38.2769522],
          [21.753052, 38.277029899999995],
          [21.7528057, 38.2772787],
          [21.752696399999998, 38.277368599999996],
          [21.7517387, 38.2781644],
          [21.7514324, 38.2783522],
          [21.7513276, 38.2783843],
          [21.751157799999998, 38.2783911],
          [21.7508701, 38.278359],
          [21.750674, 38.2782834],
          [21.7497975, 38.2777983],
          [21.7497679, 38.2777819],
          [21.7495665, 38.2776895],
          [21.749375099999998, 38.277620399999996],
          [21.748658400000004, 38.2774371],
          [21.7476089, 38.2772425],
          [21.7474693, 38.277221999999995],
          [21.746686999999998, 38.277110199999996],
          [21.7462114, 38.2770668],
          [21.745696, 38.2770197],
          [21.745485499999997, 38.277008699999996],
          [21.745299400000004, 38.2769992],
          [21.7451153, 38.2769998],
          [21.745064799999998, 38.276974599999996],
          [21.7449357, 38.276880899999995],
          [21.7440656, 38.2758235],
          [21.7437204, 38.275357],
          [21.743090799999997, 38.274397799999996],
          [21.742826299999997, 38.27408210000001],
          [21.7423218, 38.273643799999995],
          [21.7419112, 38.273302799999996],
          [21.7415569, 38.2729468],
          [21.741388099999998, 38.272712899999995],
          [21.7412779, 38.2725331],
          [21.7405583, 38.2714304],
          [21.739922, 38.2706526],
          [21.7396784, 38.270281499999996],
          [21.7394674, 38.269791399999995],
          [21.7393637, 38.2693771],
          [21.7393507, 38.269308699999996],
          [21.739340499999997, 38.2692297],
          [21.7393189, 38.2689217],
          [21.7393155, 38.268544399999996],
          [21.739319, 38.268502],
          [21.7393243, 38.2681203],
          [21.739335099999998, 38.2673583],
          [21.7393355, 38.2672729],
          [21.7393438, 38.2658568],
          [21.7395233, 38.2658177],
        ],
      },
      length: 2856,
    },
    currentTime: 277,
    historicTime: 263,
    level: 0,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-25 -> BT-9",
      geometry: {
        coordinates: [
          [21.7352809, 38.249088300000004],
          [21.735356, 38.249147],
          [21.735476199999997, 38.2492393],
          [21.7360143, 38.249667699999996],
          [21.7364811, 38.2500277],
          [21.7367186, 38.2502257],
          [21.7368757, 38.250356599999996],
          [21.7376015, 38.249794],
          [21.7379684, 38.2495091],
          [21.7383112, 38.249229799999995],
          [21.7386408, 38.2489802],
          [21.7389325, 38.2487609],
          [21.7395784, 38.24824160000001],
          [21.740067699999997, 38.247862999999995],
          [21.7401795, 38.2477634],
          [21.7405975, 38.2473806],
          [21.7396842, 38.2466795],
          [21.7386743, 38.2459034],
          [21.738340299999997, 38.2456573],
          [21.7381325, 38.2455147],
          [21.7379729, 38.2453857],
          [21.7378605, 38.245294799999996],
          [21.737707999999998, 38.2451933],
          [21.7375011, 38.245049099999996],
          [21.7371755, 38.244796199999996],
          [21.7370488, 38.2447003],
          [21.736708099999998, 38.244459899999995],
          [21.7361011, 38.244039099999995],
        ],
        type: "LineString",
      },
      length: 1203,
    },
    currentTime: 2791,
    historicTime: 2696,
    level: 0,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-18 -> BT-19",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7343761, 38.24273900000001],
          [21.7342619, 38.2427966],
          [21.7339886, 38.243029899999996],
          [21.7334075, 38.2435101],
          [21.7328102, 38.2440023],
          [21.7325375, 38.2442181],
          [21.732214799999998, 38.244473400000004],
          [21.7321779, 38.2445066],
        ],
      },
      length: 275,
    },
    currentTime: 86,
    historicTime: 69,
    level: 1,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-3 -> BT-9",
      geometry: {
        coordinates: [
          [21.7360641, 38.246508600000006],
          [21.736217099999998, 38.246614699999995],
          [21.736860699999998, 38.247126699999995],
          [21.736996599999998, 38.2472348],
          [21.7376301, 38.2467404],
          [21.7382421, 38.2462528],
          [21.7386743, 38.2459034],
          [21.7381325, 38.2455147],
          [21.7379729, 38.2453857],
          [21.7378605, 38.245294799999996],
          [21.737707999999998, 38.2451933],
          [21.7375011, 38.245049099999996],
          [21.7371755, 38.244796199999996],
          [21.7370488, 38.2447003],
          [21.736708099999998, 38.244459899999995],
          [21.7361011, 38.244039099999995],
        ],
        type: "LineString",
      },
      length: 629,
    },
    currentTime: 27,
    historicTime: 166,
    level: 0,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-24 -> BT-22",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.736756699999997, 38.2525434],
          [21.736700700000004, 38.2523529],
          [21.737407299999997, 38.252243299999996],
          [21.7377272, 38.2521694],
          [21.737951199999998, 38.2521318],
          [21.738013799999997, 38.2521213],
          [21.7386609, 38.252003099999996],
          [21.739131699999998, 38.2519221],
          [21.7395463, 38.251845599999996],
          [21.7401724, 38.2517372],
          [21.7402831, 38.2517178],
          [21.7403862, 38.2516989],
          [21.7405851, 38.2516674],
          [21.7407024, 38.251641],
          [21.7409276, 38.251608399999995],
          [21.741239099999998, 38.2515632],
          [21.7413235, 38.2515492],
        ],
      },
      length: 436,
    },
    currentTime: 684,
    historicTime: 646,
    level: 0,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-9 -> BT-3",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7361011, 38.244039099999995],
          [21.7357189, 38.2443704],
          [21.7355989, 38.2444639],
          [21.735111, 38.2448438],
          [21.7345115, 38.2453283],
          [21.734791899999998, 38.2455408],
          [21.7351015, 38.2457749],
          [21.7351673, 38.2457783],
          [21.735252199999998, 38.245745299999996],
          [21.7353558, 38.245702699999995],
          [21.7354836, 38.2457194],
          [21.7356239, 38.245805],
          [21.735663199999998, 38.2458306],
          [21.7357065, 38.2458761],
          [21.7358145, 38.245998],
          [21.7358096, 38.2460516],
          [21.735726600000003, 38.246122],
          [21.7357115, 38.2461424],
          [21.735703299999997, 38.2461651],
          [21.735713699999998, 38.2462455],
          [21.735793, 38.246320499999996],
          [21.7360641, 38.246508600000006],
        ],
      },
      length: 424,
    },
    currentTime: 409,
    historicTime: 968,
    level: 0,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-4 -> BT-3",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7289083, 38.2408303],
          [21.7287659, 38.240846],
          [21.7288794, 38.2409961],
          [21.729508700000004, 38.2414431],
          [21.729623999999998, 38.2415373],
          [21.729941399999998, 38.2417966],
          [21.7303258, 38.242087399999996],
          [21.7305989, 38.2422861],
          [21.730739399999997, 38.2423808],
          [21.7310089, 38.242591499999996],
          [21.731162599999998, 38.242712999999995],
          [21.7316443, 38.243093099999996],
          [21.7320877, 38.2434302],
          [21.7324387, 38.243713],
          [21.732485, 38.243749],
          [21.7328102, 38.2440023],
          [21.733349399999998, 38.244431999999996],
          [21.733729699999998, 38.2447221],
          [21.733776300000002, 38.24475270000001],
          [21.7341275, 38.2449836],
          [21.7345115, 38.2453283],
          [21.7346772, 38.2454525],
          [21.734791899999998, 38.2455408],
          [21.7351015, 38.2457749],
          [21.7351673, 38.2457783],
          [21.735252199999998, 38.245745299999996],
          [21.7353558, 38.245702699999995],
          [21.7354836, 38.2457194],
          [21.7356239, 38.245805],
          [21.735663199999998, 38.2458306],
          [21.7357065, 38.2458761],
          [21.7358145, 38.245998],
          [21.7358096, 38.2460516],
          [21.735726600000003, 38.246122],
          [21.7357115, 38.2461424],
          [21.735703299999997, 38.2461651],
          [21.735713699999998, 38.2462455],
          [21.735793, 38.246320499999996],
          [21.7360641, 38.246508600000006],
        ],
      },
      length: 946,
    },
    currentTime: 190,
    historicTime: 275,
    level: 0,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-1 -> BT-20",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7466078, 38.2558406],
          [21.746633199999998, 38.2557656],
          [21.747014099999998, 38.2555385],
          [21.7472147, 38.2555465],
          [21.7473077, 38.2555802],
          [21.7473982, 38.2556584],
          [21.747469799999998, 38.255780400000006],
          [21.7475377, 38.255964],
          [21.747649799999998, 38.256266],
          [21.7477867, 38.2565071],
          [21.747871999999997, 38.256639299999996],
          [21.747945899999998, 38.2568096],
          [21.7480864, 38.2571516],
          [21.748109, 38.2572419],
          [21.748123099999997, 38.2574214],
          [21.7481317, 38.257673499999996],
          [21.7481159, 38.2581432],
          [21.7481168, 38.258223199999996],
          [21.7481238, 38.258397699999996],
          [21.7482124, 38.2588179],
          [21.7482498, 38.258927199999995],
          [21.7483224, 38.259071299999995],
          [21.748452399999998, 38.259253099999995],
          [21.7486402, 38.259522499999996],
          [21.7486855, 38.2595846],
          [21.7487872, 38.2597151],
          [21.748832099999998, 38.2597786],
          [21.7489416, 38.2599337],
          [21.7489913, 38.259995499999995],
          [21.749051299999998, 38.2600614],
          [21.749289599999997, 38.260394500000004],
          [21.7493651, 38.2605],
          [21.750087500000003, 38.2614376],
          [21.750117799999998, 38.261482199999996],
          [21.7502601, 38.2616623],
          [21.750638499999997, 38.262154699999996],
          [21.751532299999997, 38.263314],
          [21.751770999999998, 38.263636399999996],
          [21.7518843, 38.263792699999996],
          [21.752145100000003, 38.2641401],
          [21.7525959, 38.2647468],
          [21.7529342, 38.2651924],
          [21.753748899999998, 38.2663047],
          [21.753856, 38.2664396],
          [21.7548924, 38.2678309],
          [21.7551033, 38.2681031],
          [21.7556929, 38.2688741],
          [21.756041, 38.269307],
          [21.7561066, 38.2693886],
          [21.7562523, 38.26957],
          [21.7564714, 38.2698459],
          [21.757099999999998, 38.270674],
          [21.7572279, 38.2708582],
          [21.757279999999998, 38.270933],
          [21.7577224, 38.2715739],
          [21.758457699999997, 38.272438300000005],
          [21.7586029, 38.2726082],
          [21.7584791, 38.2726821],
          [21.7583644, 38.2727536],
        ],
      },
      length: 2262,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-19 -> BT-3",
      geometry: {
        coordinates: [
          [21.7321779, 38.2445066],
          [21.732214799999998, 38.244473400000004],
          [21.7328102, 38.2440023],
          [21.733349399999998, 38.244431999999996],
          [21.733729699999998, 38.2447221],
          [21.733776300000002, 38.24475270000001],
          [21.7341275, 38.2449836],
          [21.7345115, 38.2453283],
          [21.734791899999998, 38.2455408],
          [21.7351015, 38.2457749],
          [21.7351673, 38.2457783],
          [21.735252199999998, 38.245745299999996],
          [21.7353558, 38.245702699999995],
          [21.7354836, 38.2457194],
          [21.7356239, 38.245805],
          [21.735663199999998, 38.2458306],
          [21.7357065, 38.2458761],
          [21.7358145, 38.245998],
          [21.7358096, 38.2460516],
          [21.735726600000003, 38.246122],
          [21.7357115, 38.2461424],
          [21.735703299999997, 38.2461651],
          [21.735713699999998, 38.2462455],
          [21.735793, 38.246320499999996],
          [21.7360641, 38.246508600000006],
        ],
        type: "LineString",
      },
      length: 513,
    },
    currentTime: 513,
    historicTime: 131,
    level: 4,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-22 -> BT-15",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7412088, 38.251512],
          [21.7411776, 38.2514725],
          [21.7411073, 38.2514119],
          [21.7407853, 38.251156099999996],
          [21.7403644, 38.2508287],
          [21.7398891, 38.250473199999995],
          [21.7396452, 38.250274499999996],
          [21.7391328, 38.2498806],
          [21.738879999999998, 38.249676],
          [21.738797299999998, 38.249611099999996],
          [21.7383112, 38.249229799999995],
          [21.7389325, 38.2487609],
          [21.7392519, 38.2485032],
          [21.7395784, 38.24824160000001],
          [21.740067699999997, 38.247862999999995],
          [21.7401795, 38.2477634],
          [21.7405975, 38.2473806],
          [21.7396842, 38.2466795],
          [21.7386743, 38.2459034],
          [21.738965, 38.2456829],
          [21.7391255, 38.2455681],
        ],
      },
      length: 935,
    },
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-6 -> BT-21",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7446205, 38.2538107],
          [21.7445795, 38.253840499999995],
          [21.7445788, 38.253842299999995],
          [21.7443062, 38.2545292],
          [21.7442836, 38.254573],
          [21.7440484, 38.2550798],
          [21.7438872, 38.255395299999996],
          [21.743801899999998, 38.25556220000001],
          [21.743783300000004, 38.2556493],
          [21.743761499999998, 38.2557014],
          [21.7436188, 38.2560432],
          [21.743611299999998, 38.256057],
          [21.7434323, 38.256388099999995],
          [21.743509799999998, 38.2564397],
          [21.7435355, 38.2565148],
          [21.743503500000003, 38.2565867],
          [21.743418899999998, 38.2566302],
          [21.7433581, 38.2566309],
          [21.743324299999998, 38.25662210000001],
          [21.7432537, 38.2565653],
          [21.7432426, 38.2565497],
          [21.7432327, 38.2565272],
        ],
      },
      length: 363,
    },
    currentTime: 112,
    historicTime: 1348,
    level: 0,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-21 -> BT-22",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7432327, 38.2565272],
          [21.7432412, 38.2564542],
          [21.7430216, 38.2560777],
          [21.743006, 38.256034199999995],
          [21.742819, 38.255514999999995],
          [21.7425777, 38.2549126],
          [21.7423318, 38.2543302],
          [21.7422583, 38.2541211],
          [21.7421318, 38.253775399999995],
          [21.742055, 38.2536026],
          [21.741754399999998, 38.252923],
          [21.7416534, 38.2526805],
          [21.7416369, 38.2526307],
          [21.7415685, 38.2524575],
          [21.7415006, 38.2522857],
          [21.7413617, 38.2518913],
          [21.741239099999998, 38.2515632],
          [21.7412088, 38.251512],
        ],
      },
      length: 586,
    },
    currentTime: 93,
    historicTime: 84,
    level: 0,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-24 -> BT-11",
      geometry: {
        coordinates: [
          [21.736756699999997, 38.2525434],
          [21.736700700000004, 38.2523529],
          [21.737407299999997, 38.252243299999996],
          [21.7377272, 38.2521694],
          [21.737951199999998, 38.2521318],
          [21.738013799999997, 38.2521213],
          [21.738225699999997, 38.2529668],
          [21.7382613, 38.2530684],
        ],
        type: "LineString",
      },
      length: 248,
    },
    currentTime: 161,
    historicTime: 233,
    level: 0,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-15 -> BT-9",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7391255, 38.2455681],
          [21.738965, 38.2456829],
          [21.7386743, 38.2459034],
          [21.7381325, 38.2455147],
          [21.7379729, 38.2453857],
          [21.7378605, 38.245294799999996],
          [21.737707999999998, 38.2451933],
          [21.7375011, 38.245049099999996],
          [21.7371755, 38.244796199999996],
          [21.7370488, 38.2447003],
          [21.736708099999998, 38.244459899999995],
          [21.7361011, 38.244039099999995],
        ],
      },
      length: 360,
    },
    currentTime: 578,
    historicTime: 27,
    level: 4,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-9 -> BT-12",
      geometry: {
        coordinates: [
          [21.7361011, 38.244039099999995],
          [21.7356204, 38.243727199999995],
          [21.7354454, 38.2436079],
          [21.7352806, 38.2434922],
          [21.7352253, 38.243455399999995],
          [21.7350165, 38.2436195],
          [21.734912599999998, 38.2437007],
          [21.7348085, 38.2437943],
          [21.7343396, 38.2442171],
          [21.7340449, 38.244461099999995],
          [21.733729699999998, 38.2447221],
          [21.733147400000004, 38.2452237],
          [21.732456000000003, 38.245788499999996],
          [21.7317884, 38.246345999999996],
          [21.7321472, 38.2466332],
          [21.732519999999997, 38.246926599999995],
          [21.732995499999998, 38.2473102],
          [21.7333984, 38.2476656],
          [21.733493499999998, 38.247582],
        ],
        type: "LineString",
      },
      length: 755,
    },
    currentTime: 1890,
    historicTime: 3484,
    level: 0,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-17 -> BT-18",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7419911, 38.2399578],
          [21.7421219, 38.2401686],
          [21.7423252, 38.2404748],
          [21.741654099999998, 38.240769300000004],
          [21.7413885, 38.2404051],
          [21.7411806, 38.2400928],
          [21.740441999999998, 38.2403852],
          [21.739907100000003, 38.2405879],
          [21.7396979, 38.2406611],
          [21.7392507, 38.240846],
          [21.739172, 38.240877600000005],
          [21.738968099999997, 38.240959499999995],
          [21.7386682, 38.241077],
          [21.7385762, 38.2411093],
          [21.738158, 38.2412559],
          [21.7379215, 38.2413522],
          [21.7375149, 38.2415178],
          [21.7374704, 38.2415324],
          [21.7368421, 38.2417502],
          [21.73611, 38.2420399],
          [21.7354272, 38.242308200000004],
          [21.7352202, 38.2423851],
          [21.7347258, 38.242595699999995],
          [21.734665800000002, 38.2426137],
          [21.7343761, 38.24273900000001],
        ],
      },
      length: 880,
    },
    currentTime: 120,
    historicTime: 146,
    level: 0,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-21 -> BT-11",
      geometry: {
        coordinates: [
          [21.7432327, 38.2565272],
          [21.7432412, 38.2564542],
          [21.7430216, 38.2560777],
          [21.743006, 38.256034199999995],
          [21.742819, 38.255514999999995],
          [21.7425777, 38.2549126],
          [21.7423318, 38.2543302],
          [21.7422583, 38.2541211],
          [21.7421318, 38.253775399999995],
          [21.742055, 38.2536026],
          [21.741754399999998, 38.252923],
          [21.7416534, 38.2526805],
          [21.7416369, 38.2526307],
          [21.7415685, 38.2524575],
          [21.7415006, 38.2522857],
          [21.740925400000002, 38.252442599999995],
          [21.7401908, 38.252609899999996],
          [21.7401143, 38.252623],
          [21.7396662, 38.2526975],
          [21.7391009, 38.2528001],
          [21.7388798, 38.2528471],
          [21.738225699999997, 38.2529668],
          [21.7382613, 38.2530684],
        ],
        type: "LineString",
      },
      length: 804,
    },
    currentTime: 1784,
    historicTime: 1949,
    level: 0,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-25 -> BT-3",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7352809, 38.249088300000004],
          [21.735356, 38.249147],
          [21.735476199999997, 38.2492393],
          [21.7360143, 38.249667699999996],
          [21.735639600000003, 38.249958],
          [21.735520899999997, 38.2498626],
          [21.7349817, 38.2494413],
          [21.734857299999998, 38.2493558],
          [21.734585199999998, 38.249136899999996],
          [21.7342075, 38.2488428],
          [21.7341299, 38.2487772],
          [21.7338208, 38.2485048],
          [21.7337196, 38.2484184],
          [21.7332312, 38.248038],
          [21.7330746, 38.2479065],
          [21.7329892, 38.2478488],
          [21.7328117, 38.2477267],
          [21.7323271, 38.2473617],
          [21.732171899999997, 38.2472358],
          [21.7318712, 38.2469956],
          [21.7317954, 38.2469526],
          [21.7315643, 38.2468396],
          [21.7313772, 38.2467638],
          [21.7309244, 38.2465749],
          [21.7311199, 38.246300399999996],
          [21.7314029, 38.2460482],
          [21.7320545, 38.24546],
          [21.732717599999997, 38.2448629],
          [21.733349399999998, 38.244431999999996],
          [21.733729699999998, 38.2447221],
          [21.733776300000002, 38.24475270000001],
          [21.7341275, 38.2449836],
          [21.7345115, 38.2453283],
          [21.734791899999998, 38.2455408],
          [21.7351015, 38.2457749],
          [21.7351673, 38.2457783],
          [21.735252199999998, 38.245745299999996],
          [21.7353558, 38.245702699999995],
          [21.7354836, 38.2457194],
          [21.7356239, 38.245805],
          [21.735663199999998, 38.2458306],
          [21.7357065, 38.2458761],
          [21.7358145, 38.245998],
          [21.7358096, 38.2460516],
          [21.735726600000003, 38.246122],
          [21.7357115, 38.2461424],
          [21.735703299999997, 38.2461651],
          [21.735713699999998, 38.2462455],
          [21.735793, 38.246320499999996],
          [21.7360641, 38.246508600000006],
        ],
      },
      length: 1385,
    },
    currentTime: 2402,
    historicTime: 264,
    level: 4,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-3 -> BT-11",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7360641, 38.246508600000006],
          [21.736217099999998, 38.246614699999995],
          [21.736860699999998, 38.247126699999995],
          [21.736996599999998, 38.2472348],
          [21.7380195, 38.2480604],
          [21.737419799999998, 38.2485557],
          [21.7370737, 38.248822],
          [21.7370412, 38.2488458],
          [21.7367208, 38.2490935],
          [21.7364025, 38.2493522],
          [21.7362434, 38.2494815],
          [21.7360143, 38.249667699999996],
          [21.7364811, 38.2500277],
          [21.7367186, 38.2502257],
          [21.7368757, 38.250356599999996],
          [21.737490599999997, 38.250830199999996],
          [21.7375158, 38.2508534],
          [21.7378352, 38.2515417],
          [21.7378695, 38.2516314],
          [21.738013799999997, 38.2521213],
          [21.738184999999998, 38.252789799999995],
          [21.738225699999997, 38.2529668],
          [21.7382613, 38.2530684],
        ],
      },
      length: 934,
    },
    currentTime: 204,
    historicTime: 235,
    level: 0,
  },
  {
    interval: {
      from: "2024-10-03T06:20:00.000Z",
      to: "2024-10-03T06:30:00.000Z",
    },
    route: {
      name: "BT-15 -> BT-21",
      geometry: {
        type: "LineString",
        coordinates: [
          [21.7391255, 38.2455681],
          [21.738965, 38.2456829],
          [21.7386743, 38.2459034],
          [21.7383986, 38.2461215],
          [21.7382421, 38.2462528],
          [21.7376301, 38.2467404],
          [21.737160199999998, 38.247109],
          [21.736996599999998, 38.2472348],
          [21.7380195, 38.2480604],
          [21.7383471, 38.248311799999996],
          [21.7389325, 38.2487609],
          [21.739478899999998, 38.249194800000005],
          [21.7397228, 38.2493824],
          [21.7402446, 38.2497743],
          [21.740529199999997, 38.249991099999995],
          [21.740848, 38.2502494],
          [21.7409613, 38.2503412],
          [21.7413985, 38.250674],
          [21.741820699999998, 38.250995599999996],
          [21.7423219, 38.2513911],
          [21.7429684, 38.251879699999996],
          [21.743133300000004, 38.2520068],
          [21.7433248, 38.2521777],
          [21.743498, 38.252357499999995],
          [21.7436063, 38.2524867],
          [21.7442496, 38.2532475],
          [21.7445071, 38.2535385],
          [21.7446873, 38.253762],
          [21.7445795, 38.253840499999995],
          [21.7445788, 38.253842299999995],
          [21.7443062, 38.2545292],
          [21.7442836, 38.254573],
          [21.7440484, 38.2550798],
          [21.7438872, 38.255395299999996],
          [21.743801899999998, 38.25556220000001],
          [21.743783300000004, 38.2556493],
          [21.743761499999998, 38.2557014],
          [21.7436188, 38.2560432],
          [21.743611299999998, 38.256057],
          [21.7434323, 38.256388099999995],
          [21.743509799999998, 38.2564397],
          [21.7435355, 38.2565148],
          [21.743503500000003, 38.2565867],
          [21.743418899999998, 38.2566302],
          [21.7433581, 38.2566309],
          [21.743324299999998, 38.25662210000001],
          [21.7432537, 38.2565653],
          [21.7432426, 38.2565497],
          [21.7432327, 38.2565272],
        ],
      },
      length: 1626,
    },
    currentTime: 2927,
    historicTime: 294,
    level: 4,
  },
];
