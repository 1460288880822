export const formatMobilityData = (data) => {
  console.log("TEST: road data: ", data);
  const tempRoads = [];

  /**
   * 3ου Ορειβατικού(διπλός χωριστα)
   * Ακτή Δυμαίων(διπλός χωριστα)
   * Ελ. Βενιζέλου(διπλός χωριστα)
   * Καλαβρύτων(διπλός χωριστα)
   * Μικρή Περιμετρική Πατρών(διπλός χωριστα)
   * Πανεπιστημίου*(διπλός χωριστα)
   *
   * 25ης Μαρτίου(διπλός)
   * 28ης Οκτωβρίου(διπλός)
   * Αγίου Νικολάου(διπλός)
   * Γεροκωστοπούλου(διπλός)
   *
   * Λεωφ. Γεωρ. Παπανδρέου(διπλός)
   * Μεσάτιδος(διπλός)
   * Παπαφλέσσα(διπλός)
   * Παπαδιαμαντοπούλου(διπλός)
   * Π. Κανελλόπουλου
   *
   * Αλ. Υψηλάντου
   * Αράτου
   * Άστιγγος
   * Αγίας Σοφίας
   * Αθηνών
   * Αγίου Ανδρέου
   * Αγίου Διονυσίου
   * Αγίας Τριάδος
   * Βότσαρη
   * Δημ. Βότση
   * Δημ. Γούναρη
   * Δοϊράνης
   * Ερμού
   * Ζαϊμη
   * Κορίνθου
   * Κολοκοτρώνη
   * Κανακάρη
   * Κανάρη
   * Κωνσταντινουπόλεως
   * Καραϊσκάκη
   * Κιλκίς
   * Καλαβρύτων(διπλός)
   * Λεωφ. Όθωνος-Αμαλίας
   * Λόντου
   * Μαιζώνος
   * Μανιακίου
   * Νέου Λιμένα
   * Νόρμαν
   * ΝΕΟ Πατρών Αθηνών
   * Παλαιών Πατρών Γερμανού
   * Πατρέως
   * Πέντε Πηγαδιών
   * Παρθενίου
   * Πολυτεχνίου
   * Προύσης
   * Σολωμού
   * Σμύρνης
   * Σαρανταπόρου
   * Τριών Ναυάρχων
   * Φιλοποίμενος
   *
   */

  for (let road of data) {
    const tempCoordinates = road.route.geometry.coordinates.map(
      (coordinateSet, id) => {
        console.log("TEST: coordinates set:", typeof coordinateSet[0]);
        return {
          lat: coordinateSet[1],
          lng: coordinateSet[0],
        };
      }
    );
    road.route.geometry.coordinates = tempCoordinates;
    road.route.routeCenter =
      tempCoordinates[Math.floor(tempCoordinates.length / 2)];
    switch (road.route.name) {
      case "BT-1 -> BT-5":
        road.route.title = "Αγίας Σοφίας";
        break;
      case "BT-1 -> BT-20":
        road.route.title = "Αγίας Σοφίας - Πανεπιστημίου - ΝΕΟ Πατρών Αθηνών";
        break;
      case "BT-2 -> BT-6":
        road.route.title = "Παπαφλέσσα";
        break;
      case "BT-2 -> BT-7":
        road.route.title = "Καραϊσκάκη - Δημ. Γούναρη";
        break;
      case "BT-2 -> BT-9":
        road.route.title = "Καραϊσκάκη - Δημ. Γούναρη";
        break;
      case "BT-2 -> BT-11":
        road.route.title = "Αγίου Διονυσίου - Νόρμαν - Πολυτεχνίου";
        // road.route.title = "Κορίνθου-> Άστιγγος-> Αγίου Ανδρέου";
        break;
      case "BT-3 -> BT-2":
        road.route.title = "Άστιγγος - Λεωφ. Όθωνος-Αμαλίας";
        // road.route.title = "Κορίνθου 2";
        break;
      case "BT-3 -> BT-15":
        road.route.title = "Αγίου Ανδρέου";
        break;
      case "BT-3 -> BT-7":
        road.route.title = "Αγίου Ανδρέου - 28ης Οκτωβρίου";
        // road.route.title = "Κορίνθου-> Ερμού-> Κανακάρη";
        break;
      case "BT-3 -> BT-9":
        road.route.title = "Γεροκωστοπούλου - Λεωφ. Όθωνος-Αμαλίας";
        break;
      case "BT-3 -> BT-11":
        road.route.title = "Λεωφ. Όθωνος-Αμαλίας";
        break;
      case "BT-3 -> BT-14":
        road.route.title = "Κανάρη - Αγίου Ανδρέου";
        // road.route.title = "Καρίνθου-> Κολοκοτρώνη-> Αγίου Ανδρέου-> Νόρμαν-> Λεωφ. Όθωνος-Αμαλίας-> Αράτου";
        break;
      case "BT-4 -> BT-8":
        road.route.title = "Λεωφ. Όθωνος-Αμαλίας";
        // road.route.title = "Παπαφλέσσα (προς Κορινθίου 2)";
        break;
      case "BT-4 -> BT-3":
        road.route.title = "Αράτου - Κανακάρη";
        break;
      case "BT-4 -> BT-18":
        road.route.title = "Αράτου - Αλ. Υψηλάντου";
        break;
      case "BT-4 -> BT-19":
        road.route.title = "Αράτου - Κορίνθου";
        break;
      case "BT-4 -> BT-26":
        road.route.title = "Αράτου - Αγίου Ανδρέου - Αγίου Διονυσίου";
        road.route.title = "Παπαφλέσσα-> Νέου Λιμένα";
        break;
      case "BT-5 -> BT-20":
        road.route.title = "Παρθενίου - Κολοκοτρώνη - Κορίνθου";
        break;
      case "BT-5 -> BT-7":
        road.route.title =
          "Αγίου Νικολάου - Κορίνθου - Ζαϊμη - Αγίου Ανδρέου - Αγίου Διονυσίου";
        break;
      case "BT-5 -> BT-2":
        road.route.title = "Αγίου Νικολάου - Κανακάρη";
        // road.route.title = "Μικρή Περιμετρική Πατρών-> Πανεπιστημίου-> Αγιας Σοφίας-> Κωνσταντινουπόλεως-> Μαζώνος-> Αράτου-> Κορίνθου";
        break;
      case "BT-6 -> BT-21":
        road.route.title = "Αγίου Νικολάου - Αλ. Υψηλάντου";
        break;
      case "BT-6 -> BT-1":
        road.route.title = "3ου Ορειβατικού - Δημ. Γούναρη";
        break;
      case "BT-6 -> BT-11":
        road.route.title = "Παπαδιαμαντοπούλου - Παλαιών Πατρών Γερμανού";
        break;
      case "BT-7 -> BT-3":
        road.route.title = "Βότσαρη";
        break;
      case "BT-7 -> BT-19":
        road.route.title = "3ου Ορειβατικού - Δημ. Γούναρη - Λόντου";
        break;
      case "BT-7 -> BT-18":
        road.route.title = "Δημ. Γούναρη";
        break;
      case "BT-7 -> BT-8":
        road.route.title = "Δημ. Γούναρη";
        break;
      case "BT-7 -> BT-9":
        road.route.title = "Δημ. Γούναρη";
        break;
      case "BT-7 -> BT-12":
        road.route.title = "Δημ. Γούναρη - Καραϊσκάκη - Πατρέως";
        break;
      case "BT-7 -> BT-13":
        road.route.title = "Δημ. Γούναρη - Καραϊσκάκη - Πατρέως";
        break;
      case "BT-8 -> BT-4":
        road.route.title = "Αλ. Υψηλάντου - Παπαφλέσσα";
        // road.route.title = "Παπαφλέσσα (προς Αθαν. Διακου 1)";
        break;
      case "BT-8 -> BT-10":
        road.route.title = "Δημ. Γούναρη";
        // road.route.title = "Παπαφλέσσα (προς Κορινθίου 1)";
        break;
      case "BT-9 -> BT-18":
        road.route.title = "Δημ. Γούναρη";
        break;
      case "BT-9 -> BT-7":
        road.route.title = "Δημ. Γούναρη";
        break;
      case "BT-9 -> BT-15":
        road.route.title = "Μαιζώνος - Παπαφλέσσα";
        break;
      case "BT-9 -> BT-3":
        road.route.title = "Μαιζώνος - Παπαφλέσσα - Ακτή Δυμαίων";
        break;
      case "BT-9 -> BT-12":
        road.route.title = "Δημ. Γούναρη - Κορίνθου";
        break;
      case "BT-10 -> BT-8":
        road.route.title = "Δημ. Γούναρη - Καραϊσκάκη - Πατρέως";
        // road.route.title = "Παπαφλέσσα (προς Αθαν. Διακου 2)";
        break;
      case "BT-10 -> BT-18":
        road.route.title = "Δημ. Γούναρη - Καραϊσκάκη - Πατρέως";
        break;
      case "BT-10 -> BT-19":
        road.route.title = "Κορίνθου";
        break;
      case "BT-11 -> BT-23":
        road.route.title = "28ης Οκτωβρίου - Κανακάρη";
        break;
      case "BT-11 -> BT-24":
        road.route.title = "28ης Οκτωβρίου - Κανακάρη - Αράτου - Αλ. Υψηλάντου";
        break;
      case "BT-12 -> BT-25":
        road.route.title = "ΝΕΟ Πατρών Αθηνών";
        break;
      case "BT-12 -> BT-13":
        road.route.title = "Π. Κανελλόπουλου - Πολυτεχνίου";
        // road.route.title = "Γεροκωστοπούλου-> Αγίου Ανδρέου-> Κολοκοτρώνη-> Λεωφ. Όθωνος-Αμαλίας";
        break;
      case "BT-13 -> BT-26":
        road.route.title = "Κωνσταντινουπόλεως";
        break;
      case "BT-13 -> BT-28":
        road.route.title = "Κιλκίς";
        // road.route.title = "Λεωφ. Όθωνος-Αμαλίας-> Αγίου Ανδρέου";
        break;
      case "BT-14 -> BT-13":
        road.route.title = "Κωνσταντινουπόλεως - Άστιγγος";
        break;
      case "BT-14 -> BT-7":
        road.route.title =
          "Αγίας Σοφίας - Αθηνών - Πέντε Πηγαδιών - Πολυτεχνίου";
        // road.route.title = "Αράτου-> Καψάλη-> Λεωφ. Όθωνος-Αμαλίας-> Ερμού-> Κανακάρη";
        break;
      case "BT-14 -> BT-9":
        road.route.title = "Μαιζώνος - Αράτου - Κανακάρη";
        break;
      case "BT-14 -> BT-2":
        road.route.title = "Μαιζώνος - Αράτου - Αλ. Υψηλάντου";
        // road.route.title = "Αράτου-> Κορίνθου";
        break;
      case "BT-14 -> BT-11":
        road.route.title = "28ης Οκτωβρίου";
        break;
      case "BT-15 -> BT-2":
        road.route.title = "Μαιζώνος - Ζαϊμη - Λεωφ. Όθωνος-Αμαλίας";
        // road.route.title = "25ης Μαρτίου-> Αγίου Νικολάου-> Κορίνθου";
        break;
      case "BT-15 -> BT-11":
        road.route.title = "Μαιζώνος - Αράτου - Κανακάρη - Αγίου Νικολάου";
        break;
      case "BT-15 -> BT-7":
        road.route.title = "Πολυτεχνίου - Π. Κανελλόπουλου";
        break;
      case "BT-15 -> BT-9":
        road.route.title = "Πολυτεχνίου - Λεωφ. Όθωνος-Αμαλίας";
        break;
      case "BT-15 -> BT-21":
        road.route.title = "Παπαφλέσσα - Ακτή Δυμαίων";
        break;
      case "BT-16 -> BT-17":
        road.route.title =
          "Πολυτεχνίου - Πέντε Πηγαδιών - Δοϊράνης - Αγίας Σοφίας";
        break;
      case "BT-16 -> BT-5":
        road.route.title = "28ης Οκτωβρίου";
        break;
      case "BT-17 -> BT-16":
        road.route.title = "Λεωφ. Όθωνος-Αμαλίας";
        break;
      case "BT-17 -> BT-27":
        road.route.title = "28ης Οκτωβρίου - Αγίου Διονυσίου";
        break;
      case "BT-17 -> BT-18":
        road.route.title = "Αγίου Ανδρέου - Αγίου Διονυσίου";
        break;
      case "BT-17 -> BT-10":
        road.route.title = "Αγίου Ανδρέου - Αράτου - Κανακάρη";
        break;
      case "BT-18 -> BT-19":
        road.route.title = "Αγίου Ανδρέου - Αράτου - Αλ. Υψηλάντου";
        break;
      case "BT-18 -> BT-9":
        road.route.title = "Αγίου Ανδρέου - Ζαϊμη - Λεωφ. Όθωνος-Αμαλίας";
        break;
      case "BT-18 -> BT-7":
        road.route.title = "Λεωφ. Όθωνος-Αμαλίας";
        break;
      case "BT-18 -> BT-10":
        road.route.title = "Λεωφ. Όθωνος-Αμαλίας - Δημ. Γούναρη";
        break;
      case "BT-18 -> BT-17":
        road.route.title = "Λεωφ. Όθωνος-Αμαλίας - Ερμού";
        break;
      case "BT-19 -> BT-28":
        road.route.title = "Αγίου Ανδρέου - 28ης Οκτωβρίου";
        break;
      case "BT-19 -> BT-18":
        road.route.title = "Ακτή Δυμαίων";
        break;
      case "BT-19 -> BT-4":
        road.route.title = "Ακτή Δυμαίων - Αγίου Ανδρέου";
        break;
      case "BT-19 -> BT-26":
        road.route.title = "Παπαφλέσσα";
        break;
      case "BT-19 -> BT-3":
        road.route.title = "Ακτή Δυμαίων";
        break;
      case "BT-19 -> BT-9":
        road.route.title =
          "Ακτή Δυμαίων - Ελ. Βενιζέλου - Αγίας Τριάδος - Σμύρνης - Προύσης - Δημ. Γούναρη";
        break;
      case "BT-19 -> BT-7":
        road.route.title =
          "Ακτή Δυμαίων - Ελ. Βενιζέλου - Λεωφ. Γεωρ. Παπανδρέου - Καλαβρύτων - 3ου Ορειβατικού";
        break;
      case "BT-20 -> BT-1":
        road.route.title = "Αγίου Ανδρέου";
        break;
      case "BT-21 -> BT-22":
        road.route.title = "Αγίου Ανδρέου - Δημ. Βότση - Λεωφ. Όθωνος-Αμαλίας";
        break;
      case "BT-21 -> BT-6":
        road.route.title = "Κορίνθου";
        break;
      case "BT-21 -> BT-11":
        road.route.title = "Κορίνθου - Αγίου Νικολάου";
        break;
      case "BT-21 -> BT-23":
        road.route.title = "Ερμού - Κανακάρη";
        break;
      case "BT-22 -> BT-7":
        road.route.title = "Ερμού - Αλ. Υψηλάντου";
        break;
      case "BT-22 -> BT-9":
        road.route.title =
          "Κορίνθου - Κολοκοτρώνη - Αγίου Ανδρέου - Αγίου Διονυσίου";
        break;
      case "BT-22 -> BT-2":
        road.route.title =
          "Κορίνθου - Κολοκοτρώνη - Αγίου Ανδρέου - Ζαϊμη - Λεωφ. Όθωνος-Αμαλίας";
        break;
      case "BT-22 -> BT-14":
        road.route.title = "Παπαφλέσσα";
        break;
      case "BT-22 -> BT-15":
        road.route.title = "Κορίνθου";
        break;
      case "BT-23 -> BT-20":
        road.route.title = "Κορίνθου - Δημ. Γούναρη";
        break;
      case "BT-23 -> BT-24":
        road.route.title = "Κορίνθου - Δημ. Γούναρη";
        break;
      case "BT-24 -> BT-22":
        road.route.title = "Μικρή Περιμετρική Πατρών - ΝΕΟ Πατρών Αθηνών";
        break;
      case "BT-24 -> BT-14":
        road.route.title = "Κανακάρη";
        break;
      case "BT-24 -> BT-11":
        road.route.title = "Κανακάρη - Μανιακίου - Κορίνθου";
        break;
      case "BT-25 -> BT-11":
        road.route.title = "Κιλκίς";
        break;
      case "BT-25 -> BT-7":
        road.route.title = "Κορίνθου";
        break;
      case "BT-25 -> BT-9":
        road.route.title =
          "Σαρανταπόρου - Κωνσταντινουπόλεως - Άστιγγος - Αγίου Διονυσίου";
        break;
      case "BT-25 -> BT-24":
        road.route.title = "Πατρέως - Κορίνθου";
        break;
      case "BT-25 -> BT-13":
        road.route.title = "Πατρέως - Μαιζώνος";
        // road.route.title = "Αγίου Ανδρέου-> Κολοκοτρώνη-> Λεωφ. Όθωνος-Αμαλίας";
        break;
      case "BT-25 -> BT-28":
        road.route.title = "Κανακάρη - Δημ. Γούναρη";
        break;
      case "BT-25 -> BT-3":
        road.route.title = "Κανακάρη";
        break;
      case "BT-25 -> BT-22":
        road.route.title = "Φιλοποίμενος - Καραϊσκάκη - Πατρέως";
        break;
      case "BT-26 -> BT-27":
        road.route.title = "Πατρέως - Αγίου Ανδρέου";
        break;
      case "BT-26 -> BT-28":
        road.route.title = "Πατρέως - Λεωφ. Όθωνος-Αμαλίας";
        // road.route.title = "Νέου Λιμένα-> Ακτή Δυμαίων-> Τριών Ναυάρχων-> Αγίου Ανδρέου";
        break;
      case "BT-26 -> BT-4":
        road.route.title = "Παπαφλέσσα";
        break;
      case "BT-27 -> BT-26":
        road.route.title = "Παπαφλέσσα";
        // road.route.title = "Ακτή Δυμαίων-> Ελ. Βενιζέλου-> Σολωμού-> Παπαφλέσσα-> Νέου Λιμένα";
        break;
      case "BT-27 -> BT-17":
        road.route.title = "Αλ. Υψηλάντου";
        break;
      case "BT-27 -> BT-16":
        road.route.title = "Αλ. Υψηλάντου - Δημ. Βότση - Καραϊσκάκη - Πατρέως";
        // road.route.title = "Ακτή Δυμαίων-> Ελ. Βενιζέλου-> Λεωφ. Γεωρ. Παπανδρέου-> Καλαβρύτων-> 3ου Ορειβατικού-> Μεσάτιδος-> Παλαιών Πατρών Γερμανού";
        break;
      case "BT-28 -> BT-12":
        road.route.title =
          "Αλ. Υψηλάντου - Δημ. Βότση - Καραϊσκάκη - Αγίου Νικολάου";
        break;
      case "BT-28 -> BT-19":
        road.route.title = "Αλ. Υψηλάντου - Δημ. Βότση - Κορίνθου";
        break;
      case "BT-28 -> BT-13":
        road.route.title = "Αλ. Υψηλάντου - Δημ. Βότση - Αγίου Ανδρέου";
        // road.route.title = "Αγίου Ανδρέου-> Δημ. Βότση-> Λεωφ. Όθωνος-Αμαλίας";
        break;

      default:
        road.route.title = road.route.name;
        break;
    }
    tempRoads.push(road);
  }
  console.log("TEST: mobility data: formatted: ", tempRoads);
  return tempRoads;
  // return tempRoads.filter((road) => road.level);
};
