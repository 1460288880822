import AccessibilityEl from "../components/Accessibility/AccessibilityEl";
import AccessibilityEN from "../components/Accessibility/AccessibilityEn";
import { useContext } from "react";
import LanguageContext from "./../hooks/language-context";

const Accessibility = () => {
 const languageCtx = useContext(LanguageContext);
  return (
    <div>
      {languageCtx.languageCode === "el" ? (
        <AccessibilityEl />  
      ) : (
         <AccessibilityEN />
      )}
    </div>
  );
};

export default Accessibility;
