import classes from "./RightPanelContent.module.css";
import BoxTitle from "../../box/title/BoxTitle";
import BoxEntity from "../../box/entity/BoxEntity";
import { useEffect, useState, useRef, useContext } from "react";
import { useStore } from "../../../hooks/store";
import parse from "html-react-parser";
import CustomSlider from "../../customSlider/CustomSlider";
import { SRLWrapper } from "simple-react-lightbox";
import SimpleReactLightbox from "simple-react-lightbox";
import { Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SmallCharts from "../../charts/SmallCharts/SmallCharts";

import ViewModal from "../../utils/ViewModal";
import "../../utils/ViewModal.css";
import { SensorIdContext } from "../../../hooks/SensorIdProvider";
import { feedPublicParking } from "../../../services/metrics";
import { publicParkingUrl } from "../../../../SMARTCITY/hardCodeData/DATA";

const options = {
  settings: {
    overlayColor: "rgb(104, 104, 104)",
    autoplaySpeed: 1500,
    transitionSpeed: 900,
  },
  buttons: {
    backgroundColor: "black",
    iconColor: "white",
  },
  caption: {
    captionColor: "white",
    captionFontFamily: "Raleway, sans-serif",
    captionFontWeight: "300",
    captionTextTransform: "uppercase",
  },
  progressBar: {
    backgroundColor: "#F2F2F2",
    fillColor: "#000000",
    height: "3px",
    showProgressBar: true,
  },
};

const RightPanelContent = () => {
  const [data, setData] = useState({ metrics: [], title: "" });
  const [loadingData, setLoadingData] = useState(false);
  const state = useStore()[0];
  const typeStyleVertical = useRef(null);
  const colorIconTitle = useRef(null);
  const iconTitleRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const sensorId = useContext(SensorIdContext);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { search } = useLocation();
  const typeId = new URLSearchParams(search).get("typeId");

  const [publicParkingData, setPublicParkingData] = useState([]);

  const handlePublicParkingData = async () => {
    const controller = new AbortController();
    const { signal } = controller;
    await feedPublicParking(`${publicParkingUrl}`, signal)
      .then((data) => setPublicParkingData(data))
      .catch((error) => []);
  };

  useEffect(() => {
    handlePublicParkingData();
    // setPublicParkingData(tempPublicParkingData);

    if (sensorId.get) {
      const currTypeURL = new URLSearchParams(search).get("typeId");
      const currSensorURL = sensorId.get.toString();

      const tempTypeIdActive = state.types.filter(
        (item) => item.id === currTypeURL
      );

      if (tempTypeIdActive.length > 0 && state.sensors.length > 0) {
        iconTitleRef.current = tempTypeIdActive[0].icon;
        colorIconTitle.current = {
          backgroundColor: tempTypeIdActive[0].color,
          minWidth: "50px",
          maxWidth: "50px",
          minHeight: "50px",
          maxHeight: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "12px",
        };

        setLoadingData(true);

        if (currSensorURL) {
          const sensor = state.sensors.filter(
            (item) => item.id?.toString() === currSensorURL
          );

          if (sensor.length > 0) {
            if (sensor[0].typeId.toString() === "7") {
              setData({
                historic: {
                  category: sensor[0].categoryName,
                  image: sensor[0].thumbnail,
                  content: sensor[0].content,
                  images: sensor[0].images,
                  // images: [
                  //   "https://alexandroupoli.repository.gr/files/asset/b2d3f6e7dc5dd2435bdb3f21127ecf60221e71b8.jpg",
                  //   state.sensors[i].thumbnail,
                  // ],
                },
                title: sensor[0].title,
              });
              typeStyleVertical.current = "POIS";
            } else if (typeId === "36") {
              setData(
                publicParkingData?.filter(
                  (parkingGroup) => parkingGroup.mysensor_id === sensorId.get
                )
              );
            } else {
              console.log(
                sensor[0].metrics,
                sensor[0].metrics.includes("Συντεταγμένες κάδου")
              );
              let place = sensor[0].metrics.filter(
                (item) => item.content === "Συντεταγμένες κάδου"
              )[0];

              if (place) {
                const cords = place.value.split(" ");
                console.log(cords);
                fetch(
                  `https://nominatim.openstreetmap.org/reverse?format=json&lat=${cords[0]}&lon=${cords[1]}&accept-language=el`
                )
                  .then((response) => response.json())
                  .then((data) => {
                    console.log(data);

                    const dataMetrics = sensor[0].metrics.filter(
                      (item) =>
                        item.content !== "Συντεταγμένες κάδου" &&
                        item.content !== "Τάση μπαταρίας"
                    );
                    const tempLocation = {
                      content: "Περιοχή κάδου",
                      value: data.address.village
                        ? data.address.village
                        : data.address.town,
                    };
                    dataMetrics.push(tempLocation);
                    dataMetrics.push({
                      content: "Τύπος κάδου",
                      value: "Μπλε ανακύκλωσης 1100 lt",
                    });

                    setData({
                      metrics: dataMetrics,
                      title: sensor[0].title,
                    });
                    typeStyleVertical.current = "Metrics";
                  })
                  .catch((error) => console.warn(error));
              } else {
                setData({
                  metrics: sensor[0].metrics,
                  title: sensor[0].title,
                });
                typeStyleVertical.current = "Metrics";
              }
            }
          }
        } else {
          setData({ metrics: [], title: "" });
        }
      }
    }
  }, [search, state.types, state.sensors, sensorId.get]);

  useEffect(() => {
    if (data.title !== "") {
      setLoadingData(false);
    }
  }, [data]);

  const Metrics =
    data.metrics &&
    data.metrics
      .filter((item) => item.value)
      .map((item, index) => (
        <BoxEntity
          key={index}
          icon={item.icon_large}
          content={item.content}
          value={item.value}
          className={classes.gradient_border}
          styleValue={{ fontWeight: "400" }}
        />
      ));

  const POIS = data.historic && (
    <Fragment>
      <SimpleReactLightbox>
        <SRLWrapper options={options}>
          {data.historic.image && (
            <img
              src={data.historic.image}
              alt="thumbnail"
              width="100%"
              style={{ cursor: "pointer" }}
            />
          )}
        </SRLWrapper>
      </SimpleReactLightbox>

      {data.historic.category && (
        <p style={{ fontSize: "14px" }}>
          Κατηγορία: <i>{data.historic.category}</i>
        </p>
      )}
      {data.historic.content && (
        <div style={{ marginTop: "20px", textAlign: "justify" }}>
          {parse(data.historic.content)}
        </div>
      )}

      {data.historic.images.length > 0 && (
        <div className={classes.slider_placeholder}>
          <p className={classes.more_photos_descr}>
            <i>Επιπλέον φωτογραφικό υλικό:</i>
          </p>
          <SimpleReactLightbox>
            <SRLWrapper options={options}>
              <CustomSlider
                settings={{
                  infinite: false,
                }}
              >
                {data.historic.images.map((image, index) => (
                  <div key={index} className={classes.image_placeholder}>
                    <img
                      src={image}
                      alt={data.title}
                      width="100%"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                ))}
              </CustomSlider>
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>
      )}
    </Fragment>
  );

  return (
    <div className={classes.info_container}>
      <div className={classes.sticky_title}>
        <BoxTitle
          icon={iconTitleRef.current}
          title={typeId === "36" ? data[0]?.mysensor_name : data?.title}
          styleIcon={colorIconTitle.current}
          className={[classes.info_title, classes.height_unset].join(" ")}
        />
        <hr className={classes.gradient_line_style} />

        {typeId === "36" && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <p>Ελεύθερες Θέσεις:</p>
              <p>{data[0]?.available_slots}</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <p>Σύνολο Θέσεων:</p>
              <p>{data[0]?.total_slots}</p>
            </div>
          </>
        )}

        {typeId === "30" && (
          <img
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              height: "300px",
              width: "100%",
              objectFit: "cover",
              marginTop: "30px",
              borderRadius: "6px",
              boxShadow: "0px 6px 8px rgba(10,10,10,0.2)",
              cursor: "pointer",
              border: "1px solid #4d4949",
            }}
            // src=""
            src="https://asana-user-private-us-east-1.s3.amazonaws.com/assets/46949496928046/1208031398368169/31db76a2102bd0e2836f9de8bcf055ac?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEMT%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDRyaP23A9b8H%2BHxzAHWhpq4xg6VKJnCenEYyV0zmeHyQIhAIuxl7qtdedobuahrJBY%2ByTLXhVKU8qG9cnFpkR0FHMwKpYFCP3%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMNDAzNDgzNDQ2ODQwIgyEtrnIii2ebRk09eUq6gT6GHwW4YaR60E7Ng3PyTjC%2F6o%2F83KBjzilKo26SVbmK8DXywGBzcDcU6l3llWW5SvJ%2BF6gqsTMRJ2%2FQ3MpQ%2Bu93V0J%2Fms3cc1ec05AjRnHYfl9julwnp%2FxQE5U4UT454mi7JSAopDTwJcd%2B%2B7XYmzBzka5JyqH4iETU%2BQrmslWGGX2kfwoJ4DofxHWPYYq0dPM2Q2T6o7QaCb3RyxDcnLvcr4mu2b9zzuoEei3CciVAheBmEMPzlCbd8mZTS4FhzH7zmb%2FxTjJgJebI4e9E7dWEHFAZNIUk6DgolILmNBiLY78zlzZ5NOABIwpeXYI2Foz30r4pfO6owWeIOtgB0sFtZHGCModEWULaKCMEEszwN%2FHfSnylt05bHyQeYfRyiRy0LyYafgnKLqN7CAmej8VFoz3S8lRnaXAuaZnYbUziG6gwEoCrNQYeF84yJF%2F1KbNMPgaXKaVtDFDybD4TSZB4SaPQdjWdNZ%2FiR015hE%2BWjsnRVs52lReIGOIKdVTJyV8dlnyEuhXvhST1nB7OMkXtt2Dvvl8%2F51Dzx1qM3hND%2BIbFHEsCgtmKE716p2MbrkAyz3ASVWT0r92r5CZWzl1fdghhxH4cZ5LXVcgZbgwy%2F7AIobm3kcMMdUnFB14RMh%2B87Pkyf01BdhDk22xAB3n3DrTcq36RhwX7B67OQ7IIzTAmW6LvBdczj6nzn4ibbduQI7T4G314ThwuORv%2BufFQpuCb93izIxa0zPuLlAUfAsEJ3y4N9igcd6UW%2FTBMZZ%2FyZI%2BUHRhMCbqvdxkkdXabwZvY2gprB8anTFdSnkHdHJnS1ATW%2F3%2FgiYwhLjTtwY6mQG5Ov%2BcWlBbI4cdl7OZGLc8CrgdgSHQwZbMMpdwr31Fag6YtuIKwkNRs577%2FRnalEedOiIe%2Fw7zBbktFgGM%2FACI2VMPxzVgt9j%2BcyOAceshtsjs676%2FpMN0ulqWOlU8yYkOUnEO6JbM0uZEgwxTh%2FVQt1nkApJQgl4iG1UzmuutaupcOutoyvhJgB2TGBVKNlspddffaCx%2Fqdc%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240926T065747Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1800&X-Amz-Credential=ASIAV34L4ZY4FOIOMQTX%2F20240926%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=e78e2d6a812ad84fd7497f73863c4ad70dc20f145f408e71d13804a39b7a71fc#_=_"
            alt="έξυπνο παγκάκι"
            width="500"
            height="600"
            onClick={() => showModal()}
          />
        )}

        <ViewModal
          modalStatus={isModalOpen}
          title={data.title}
          closeModal={handleCancel}
        >
          <img
            style={{ width: "100%", height: "auto" }}
            src="https://asana-user-private-us-east-1.s3.amazonaws.com/assets/46949496928046/1208031398368169/31db76a2102bd0e2836f9de8bcf055ac?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEMT%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQDRyaP23A9b8H%2BHxzAHWhpq4xg6VKJnCenEYyV0zmeHyQIhAIuxl7qtdedobuahrJBY%2ByTLXhVKU8qG9cnFpkR0FHMwKpYFCP3%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMNDAzNDgzNDQ2ODQwIgyEtrnIii2ebRk09eUq6gT6GHwW4YaR60E7Ng3PyTjC%2F6o%2F83KBjzilKo26SVbmK8DXywGBzcDcU6l3llWW5SvJ%2BF6gqsTMRJ2%2FQ3MpQ%2Bu93V0J%2Fms3cc1ec05AjRnHYfl9julwnp%2FxQE5U4UT454mi7JSAopDTwJcd%2B%2B7XYmzBzka5JyqH4iETU%2BQrmslWGGX2kfwoJ4DofxHWPYYq0dPM2Q2T6o7QaCb3RyxDcnLvcr4mu2b9zzuoEei3CciVAheBmEMPzlCbd8mZTS4FhzH7zmb%2FxTjJgJebI4e9E7dWEHFAZNIUk6DgolILmNBiLY78zlzZ5NOABIwpeXYI2Foz30r4pfO6owWeIOtgB0sFtZHGCModEWULaKCMEEszwN%2FHfSnylt05bHyQeYfRyiRy0LyYafgnKLqN7CAmej8VFoz3S8lRnaXAuaZnYbUziG6gwEoCrNQYeF84yJF%2F1KbNMPgaXKaVtDFDybD4TSZB4SaPQdjWdNZ%2FiR015hE%2BWjsnRVs52lReIGOIKdVTJyV8dlnyEuhXvhST1nB7OMkXtt2Dvvl8%2F51Dzx1qM3hND%2BIbFHEsCgtmKE716p2MbrkAyz3ASVWT0r92r5CZWzl1fdghhxH4cZ5LXVcgZbgwy%2F7AIobm3kcMMdUnFB14RMh%2B87Pkyf01BdhDk22xAB3n3DrTcq36RhwX7B67OQ7IIzTAmW6LvBdczj6nzn4ibbduQI7T4G314ThwuORv%2BufFQpuCb93izIxa0zPuLlAUfAsEJ3y4N9igcd6UW%2FTBMZZ%2FyZI%2BUHRhMCbqvdxkkdXabwZvY2gprB8anTFdSnkHdHJnS1ATW%2F3%2FgiYwhLjTtwY6mQG5Ov%2BcWlBbI4cdl7OZGLc8CrgdgSHQwZbMMpdwr31Fag6YtuIKwkNRs577%2FRnalEedOiIe%2Fw7zBbktFgGM%2FACI2VMPxzVgt9j%2BcyOAceshtsjs676%2FpMN0ulqWOlU8yYkOUnEO6JbM0uZEgwxTh%2FVQt1nkApJQgl4iG1UzmuutaupcOutoyvhJgB2TGBVKNlspddffaCx%2Fqdc%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240926T065747Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1800&X-Amz-Credential=ASIAV34L4ZY4FOIOMQTX%2F20240926%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=e78e2d6a812ad84fd7497f73863c4ad70dc20f145f408e71d13804a39b7a71fc#_=_"
            alt="έξυπνο παγκάκι"
          />
        </ViewModal>
      </div>

      {!loadingData && typeStyleVertical.current === "Metrics" && Metrics}
      {!loadingData && typeStyleVertical.current === "POIS" && POIS}

      {/*{!loadingData && new URLSearchParams(search).get("typeId") === "9" && (*/}
      {/*  <SmallCharts />*/}
      {/*)}*/}
      {/*{!loadingData && new URLSearchParams(search).get("typeId") === "1" && (*/}
      {/*  <SmallCharts loop={["1", "9", "11"]} />*/}
      {/*)}*/}
    </div>
  );
};

export default RightPanelContent;
