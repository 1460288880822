import classes from "./SubsystemList.module.css";
import { useContext, useEffect, useState } from "react";
import MapLefttPanelContentEntity from "../../Map/leftPanelContent/Entity/MapLefttPanelContentEntity";
import { useStore } from "../../../hooks/store";
import { useHistory, useLocation } from "react-router-dom";
import { showEnvironmentAddress } from "../../../../SMARTCITY/hardCodeData/DATA";
import { SensorIdContext } from "../../../hooks/SensorIdProvider";
import styled from "styled-components";
import { ParkingTypeContext } from "../../../hooks/ParkingTypeProvider";
import { MobilityContext } from "../../../hooks/mobilityProvider";

const SubSystemList = ({
  showAnimation,
  filterTypeName,
  filtersVars,
  mobilityMap,
  setFiltersVars,
  selectedRoad,
}) => {
  const [selectSubSystem, setSelectSubSystem] = useState("");
  const [activeType, setActiveType] = useState(null);
  const [sensors, setSensors] = useState([]);
  const [icons, setIcons] = useState([]);
  const state = useStore()[0];

  const [filteredSensors, setFilteredSensors] = useState([]);

  const history = useHistory();
  const { search } = useLocation();
  const { pathname } = useLocation();

  const sensorId = useContext(SensorIdContext);
  const mobilityData = useContext(MobilityContext);

  const currTypeURL = new URLSearchParams(search).get("typeId");
  useEffect(() => {
    // console.log("TEST: currTypeURL!!s", currTypeURL);

    const tempTypeIdActive = state.types.filter(
      (item) => item.id === currTypeURL
    );

    setActiveType(tempTypeIdActive[0]);

    // console.log(
    //   "TEST: data",
    //   state.sensors?.filter((sensor) => sensor.typeId === currTypeURL)
    // );

    const data = mobilityMap
      ? mobilityData
      : state.sensors?.filter((sensor) => sensor.typeId === currTypeURL);

    setSensors(data);

    filterData(data);
  }, [
    filterTypeName,
    filtersVars,
    search,
    state.sensors,
    state.types,
    currTypeURL,
    mobilityMap,
  ]);

  const filterData = (data) => {
    let tempSensors = data;

    if (filterTypeName) {
      tempSensors = sensors.filter((sensor) => {
        return sensor.title.toLowerCase() === filterTypeName.toLowerCase();
      });
    }
    if (filtersVars.length > 0) {
      switch (currTypeURL) {
        case "9": {
          for (let filterVar of filtersVars) {
            switch (filterVar) {
              case "Άδειοι":
                tempSensors = sensors.filter(
                  (sensor) => sensor.metrics[4] <= "25%"
                );
                break;
              case "Κάτω από τη μέση":
                tempSensors = sensors.filter(
                  (sensor) =>
                    sensor.metrics[4] > "25%" && sensor.metrics[4] <= "40%"
                );
                break;
              case "Περίπου στη μέση":
                tempSensors = sensors.filter(
                  (sensor) =>
                    sensor.metrics[4] > "40%" && sensor.metrics[4] <= "60%"
                );
                break;
              case "Σχεδόν πλήρεις":
                tempSensors = sensors.filter(
                  (sensor) =>
                    sensor.metrics[4] > "60%" && sensor.metrics[4] <= "95%"
                );
                break;
              case "Πλήρεις":
                tempSensors = sensors.filter(
                  (sensor) => sensor.metrics[4] > "95%"
                );
                break;
              default:
                tempSensors = [];
                break;
            }
          }
          break;
        }
        case "24": {
          tempSensors = sensors.filter((sensor) => {
            if (filtersVars.length > 1) {
              if (filtersVars.includes("Ελεύθερη")) {
                return (
                  (filtersVars.includes(sensor.type) && !sensor.isOccupied) ??
                  sensor.isOccupied === false
                );
              }
              if (filtersVars.includes("Κατειλημμένη")) {
                return (
                  (filtersVars.includes(sensor.type) && sensor.isOccupied) ??
                  sensor.isOccupied === true
                );
              }
              return filtersVars.includes(sensor.type);
            } else {
              if (filtersVars.includes("Ελεύθερη")) {
                return !sensor.isOccupied ?? null;
              }
              if (filtersVars.includes("Κατειλημμένη")) {
                return sensor.isOccupied ?? null;
              }
              return filtersVars.includes(sensor.type);
            }
          });
          break;
        }
        default: {
          for (let filterVar of filtersVars) {
            tempSensors.filter((sensor) => sensor.metrics.includes(filterVar));
          }
          break;
        }
      }
    }
    setFilteredSensors(
      tempSensors.filter((sensor) => sensor.title !== "Πάτρα Στάθμευση")
    );
  };

  const getDateString = (interval) => {
    const weekDays = ["Κυρ", "Δευ", "Τρι", "Τετ", "Πεπ", "Παρ", "Σαβ"];
    const fromDate = new Date(interval.from);
    const toDate = new Date(interval.to);
    return `${weekDays[fromDate.getDay()]} ${fromDate.getDate()}/${
      fromDate.getMonth() + 1
    }/${fromDate.getFullYear()} | ${fromDate.getHours()}:${fromDate.getMinutes()} - ${
      toDate.getHours() < 10 ? `0${toDate.getHours()}` : toDate.getHours()
    }:${
      toDate.getMinutes() < 10 ? `0${toDate.getMinutes()}` : toDate.getMinutes()
    }`;
  };

  const renderSensorsData = () => {
    let tempData = mobilityMap
      ? mobilityData
      : filteredSensors.length > 1
      ? filteredSensors
      : sensors;

    if (tempData) {
      return tempData.map((sensor, id) => {
        return (
          <SensorItemContainer
            style={{
              alignItems:
                currTypeURL === "1" ||
                currTypeURL === "2" ||
                currTypeURL === "5"
                  ? "flex-start"
                  : "center",
            }}
            key={`sensor_${id}`}
            onClick={() => clickMarkHandler(sensor)}
          >
            <i
              className={activeType?.icon}
              style={{
                fontSize: "32px",
                color: activeType?.id === "9" ? "blue" : "inherit",
              }}
            />
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                {mobilityMap ? sensor.route.title : sensor.title}
              </p>
              {mobilityMap && (
                <p style={{ fontSize: "12px", fontWeight: "600" }}>
                  {sensor.route.name}
                </p>
              )}
              <p style={{ fontSize: "14px" }}>
                {activeType?.id === "1"
                  ? showEnvironmentAddress
                    ? sensor.address
                    : null
                  : activeType?.id === "24"
                  ? mobilityMap
                    ? `Μήκος δρόμου: ${sensor.route.length} m`
                    : sensor.type ?? sensor.address
                  : sensor.address}
                <span style={{ paddingTop: "10px" }}>
                  {(currTypeURL === "1" ||
                    currTypeURL === "2" ||
                    currTypeURL === "5") &&
                    sensor.metrics.map((item, id) => {
                      return <img src={item.icon_small} />;
                    })}
                </span>
              </p>
              {mobilityMap && (
                <>
                  <p style={{ fontSize: "14px" }}>
                    {getDateString(sensor.interval)}
                  </p>
                  <p style={{ fontSize: "14px" }}>
                    Επίπεδο Κυκλοφορίας:{" "}
                    {sensor.level === 0
                      ? "Πολύ Χαμηλό"
                      : sensor.level === 1
                      ? "Χαμηλο"
                      : sensor.level === 2
                      ? "Μέτριο"
                      : sensor.level === 3
                      ? "Υψηλό"
                      : sensor.level === 4
                      ? "Πολύ Υψηλό"
                      : "Μη Διαθέσιμο"}
                  </p>
                </>
              )}
            </div>
          </SensorItemContainer>
        );
      });
    }
    return null;
  };

  const clickMarkHandler = (item) => {
    console.log("TEST: clicked item:", item);
    if (item !== selectSubSystem) {
      if (!mobilityMap) {
        sensorId.set(item.id);
        // history.push(`${pathname}?typeId=${activeType.id}&sensorId=${item.id}`);
        showAnimation(true);
      } else {
        selectedRoad.set(item);
      }
    } else {
      showAnimation(false);
    }
  };

  return (
    <div className={[classes.subsystem_layout].join(" ")}>
      <div
        className={[classes.flexContainer, classes.subsystem_title_style].join(
          " "
        )}
        style={{ backgroundColor: activeType?.color }}
      >
        <p className={classes.subsystem_title}>{activeType?.name}</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {renderSensorsData()}
      </div>
    </div>
  );

  // useEffect(() => {
  //   if (search !== "") {
  //     const currTypeURL = new URLSearchParams(search).get("typeId");
  //     const currSensorURL = new URLSearchParams(search).get("sensorId");

  //     const tempTypeIdActive = state.types.filter(
  //       (item) => item.id === currTypeURL
  //     );

  //     if (tempTypeIdActive.length > 0 && state.sensors.length > 0) {
  //       console.log(tempTypeIdActive, state.sensors);

  //       const data = state.sensors.filter(
  //         (item) => item.typeId === tempTypeIdActive[0].id
  //       );

  //       let activeSensor = "";
  //       let iconsEachSensor = {};
  //       for (let i = 0; i < data.length; i++) {
  //         if (sensorId && data[i].id?.toString() === sensorId) {
  //           activeSensor = data[i];
  //         }
  //         iconsEachSensor = {
  //           ...iconsEachSensor,
  //           [data[i].id]: data[i].metrics.map((metric) => metric.icon_small),
  //         };
  //       }
  //       console.log(state.sensors);
  //       console.log(data);
  //       setActiveType(tempTypeIdActive[0]);
  //       setSensors(data);
  //       console.log(iconsEachSensor);
  //       setIcons(iconsEachSensor);
  //       setSelectSubSystem(activeSensor);
  //     }
  //   }
  // }, [search, state.sensors, state.types]);

  // console.log(selectSubSystem);
};

export default SubSystemList;

const SensorItemContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 20px;

  padding: 20px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
