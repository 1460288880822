import classes from "./Home.module.css";
import WelcomeMessage from "./Welcome/WelcomeMessage";
import LeftPanel from "../../layout/leftPanel/LeftPanel";
import BoxesContainer from "../../../SMARTCITY/components/boxesContainer/BoxesContainer";
import Charts from "../charts/Charts";
import Tableau from "../../../SMARTCITY/components/tableau/Tableau";

const Home = () => {
  return (
    <div className={classes.home_container}>
      <LeftPanel
        open={false}
        buttonExpandName={"Γραφήματα"}
        className={classes.leftPanel_Charts}
      >
        <Charts />
      </LeftPanel>
      {/*<LeftPanel*/}
      {/*  open={false}*/}
      {/*  buttonExpandName={"Χρονοσειρές"}*/}
      {/*  className={classes.horizontal_side_panel_tableau}*/}
      {/*>*/}
      {/*  <Tableau />*/}
      {/*</LeftPanel>*/}
      <WelcomeMessage />
      <BoxesContainer />
    </div>
  );
};

export default Home;
