import { Fragment, useContext, useEffect, useState } from "react";
import SubSystemList from "../../subSystem/subsystemList/SubsystemList";
import SubsystemSearch from "../../subSystem/subsystemSearch/SubsystemSearch";
import { MobilityContext } from "../../../hooks/mobilityProvider";

const MapContentForLeftPanel = ({
  close,
  showAnimation,
  searchInput,
  setSearchInput,
  filtersVar,
  setFiltersVars,
  clearVarsHandler,
  mobilityMap,
  selectedRoad,
}) => {
  const varsHandler = (variable) => {
    if (!filtersVar?.includes(variable))
      setFiltersVars((prev) => prev.concat(variable));
    else setFiltersVars((prev) => prev.filter((item) => item !== variable));
  };

  return (
    <Fragment>
      <SubsystemSearch
        close={close}
        findTypeName={searchInput}
        setVars={varsHandler}
        filtersVars={filtersVar}
        clearVars={clearVarsHandler}
      />
      <SubSystemList
        showAnimation={showAnimation}
        filterTypeName={searchInput}
        filtersVars={filtersVar}
        setFiltersVars={setFiltersVars}
        mobilityMap={mobilityMap}
        selectedRoad={{ ...selectedRoad }}
      />
    </Fragment>
  );
};

export default MapContentForLeftPanel;
