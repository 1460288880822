import classes from "./BoxEntity.module.css";

const BoxEntity = ({ className, icon, content, styleValue, value }) => {
  return (
    <div
      className={[classes.boxEntity, classes.gridContainer, className].join(
        " "
      )}
    >
      <div className={classes.boxEntity_icon}>
        {icon && <img src={icon} alt="icon" width="32px" />}
      </div>
      <div className={classes.boxEntity_content}>{content}</div>
      <div className={classes.boxEntity_value} style={styleValue}>
        {value}
      </div>
    </div>
  );
};
export default BoxEntity;
