import React, { useEffect, useState, Fragment, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import classes from "./SubsystemPopupMenuVars.module.css";
import { useTranslation } from "react-i18next";
import { ParkingTypeContext } from "../../../../hooks/ParkingTypeProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingTop: "8px",
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

const SubsystemPopupMenuVars = ({
  filtersVars,
  varsList,
  setVars,
  clearVars,
  close,
}) => {
  const classesStyle = useStyles();
  const [list, setList] = useState([]);
  const [clearVisible, setClearVisible] = useState(false);

  const { normalSpot, loadingSpot, ameaSpot, freeSpot } =
    useContext(ParkingTypeContext);

  const { t } = useTranslation();

  useEffect(() => {
    if (filtersVars.length > 0) {
      const tempList = varsList.map((item) => {
        return {
          var: item,
          isActive: filtersVars.includes(item) ? true : false,
        };
      });
      setList(tempList);
      setClearVisible(true);
    } else {
      const tempList = varsList.map((item) => ({
        var: item,
        isActive: false,
      }));
      setList(tempList);
      setClearVisible(false);
    }
  }, [filtersVars]);

  const handleChange = (event, type) => {
    if (type) {
      setVars(type);
    } else {
      setVars(list[event.target.value].var);
    }
  };

  const clearFilters = () => {
    clearVars();
    normalSpot.set(false);
    loadingSpot.set(false);
    ameaSpot.set(false);
    freeSpot.set(false);
  };

  return (
    <Fragment>
      <div className={classesStyle.root}>
        <FormControl component="fieldset" className={classesStyle.formControl}>
          <FormLabel component="legend">{t("Επιλέξτε μεταβλήτες")}</FormLabel>
          <FormGroup
            style={{
              maxHeight: "300px",
              display: "flex",
              flexWrap: "wrap",
              paddingInline: "10px",
            }}
          >
            {list.map((item, index) => {
              if (item.var === "Τύπος" || item.var === "Type") {
                return (
                  <>
                    <FormControlLabel
                      key={`free_${index}`}
                      label={t("Ελεύθερη")}
                      control={
                        <Checkbox
                          checked={freeSpot.get}
                          onChange={(event) => {
                            handleChange(event, "Ελεύθερη");
                            freeSpot.set(!freeSpot.get);
                          }}
                          name="Ελεύθερη"
                          value={2}
                        />
                      }
                    />
                    <FormControlLabel
                      key={`normal_${index}`}
                      label={t("Κανονική")}
                      control={
                        <Checkbox
                          checked={normalSpot.get}
                          onChange={(event) => {
                            handleChange(event, "Κανονική");
                            normalSpot.set(!normalSpot.get);
                          }}
                          name="Κανονική"
                          value={2}
                        />
                      }
                    />
                    <FormControlLabel
                      key={`loading_${index}`}
                      label={t("Φορτοεκφόρτωσης")}
                      control={
                        <Checkbox
                          checked={loadingSpot.get}
                          onChange={(event) => {
                            handleChange(event, "Φορτοεκφόρτωσης");
                            loadingSpot.set(!loadingSpot.get);
                          }}
                          name="Φορτοεκφόρτωσης"
                          value={2}
                        />
                      }
                    />
                    <FormControlLabel
                      key={`amea_${index}`}
                      label={t("ΑΜΕΑ")}
                      control={
                        <Checkbox
                          checked={ameaSpot.get}
                          onChange={(event) => {
                            handleChange(event, "ΑΜΕΑ");
                            ameaSpot.set(!ameaSpot.get);
                          }}
                          name="ΑΜΕΑ"
                          value={2}
                        />
                      }
                    />
                  </>
                );
              }
              return (
                <FormControlLabel
                  key={`${item.var}_${index}`}
                  control={
                    <Checkbox
                      checked={item.isActive}
                      onChange={(event) => handleChange(event, null)}
                      name={item.var}
                      value={index}
                    />
                  }
                  label={item.var}
                />
              );
            })}
          </FormGroup>
        </FormControl>
        {/* <FormControl component="fieldset" className={classesStyle.formControl}>
          <FormLabel component="legend" style={{ visibility: "hidden" }}>
            {t("Επιλέξτε μεταβλήτες")}
          </FormLabel>
          <FormGroup>
            {list
              .slice(Math.ceil(list.length / 2), list.length)
              .map((item, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={item.isActive}
                      onChange={handleChange}
                      name={item.var}
                      value={index + parseInt(Math.ceil(list.length / 2))}
                    />
                  }
                  label={item.var}
                />
              ))}
            <FormHelperText>{t("Από το υπάρχων υποσύστημα")}</FormHelperText>
          </FormGroup>
        </FormControl> */}
      </div>
      <div className={classes.btns_action}>
        {clearVisible && (
          <Button
            variant="contained"
            color="secondary"
            onClick={clearFilters}
            style={{ margin: "5px", width: "111.25px" }}
          >
            {t("Απαλοιφή")}
          </Button>
        )}
        <Button
          variant="contained"
          color="secondary"
          onClick={close}
          style={{ margin: "5px", width: "111.25px" }}
        >
          {t("Εφαρμογή")}
        </Button>
      </div>
    </Fragment>
  );
};
export default SubsystemPopupMenuVars;
